export enum TABS {
  ADMINISTRATORS,
  PROJECTS
  // GROUPS
}

export enum DIALOG_TYPES {
  // CHANGE_QRG_QUOTAS,
  ADD_ADMIN,
  REMOVE_ADMIN,
  CHANGE_OWNERSHIP,
  DELETE_PROJECT,
  SET_PROJECT_QUOTAS,
  EDIT_PROJECT,
  CREATE_PROJECT,
  EDIT_ORGANIZATION,
  DELETE_ORGANIZATION,
  CREATE_GROUP,
  EDIT_GROUP,
  ADD_USER_TO_GROUP,
  REMOVE_USER_FROM_GROUP,
  DELETE_GROUP
}
