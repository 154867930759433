import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Organization, User, UserInfo, Users } from "./types";

export type Reducer = {
  users: Users | null;
  user: User | null;
  userInfo: UserInfo | null;
  userOrganizations: Organization[] | null;
  isUserCreating: boolean;
  isUsersPasswdSetting: boolean;
  areUsersLoading: boolean;
  areUserOrganizationsLoading: boolean;
  isUserInfoLoading: boolean;
  isUserInfoUpdating: boolean;
  isUserModifyingEnabling: boolean;
  isUserTypeModifying: boolean;
  isUserTrialPeriodModifying: boolean;
};

const initialState: Reducer = {
  users: null,
  user: null,
  userInfo: null,
  userOrganizations: null,
  isUserCreating: false,
  isUserInfoUpdating: false,
  isUsersPasswdSetting: false,
  areUsersLoading: false,
  isUserInfoLoading: false,
  areUserOrganizationsLoading: false,
  isUserModifyingEnabling: false,
  isUserTypeModifying: false,
  isUserTrialPeriodModifying: false
};

export const reducer = reducerWithInitialState(initialState)
  //  Create User
  .case(
    actions.createUser.started,
    (state): Reducer => ({
      ...state,
      isUserCreating: true
    })
  )
  .case(
    actions.createUser.done,
    (state, payload): Reducer => ({
      ...state,
      user: payload.result,
      isUserCreating: false
    })
  )
  .case(
    actions.createUser.failed,
    (state): Reducer => ({
      ...state,
      isUserCreating: false
    })
  )
  //  Set Users Password
  .case(
    actions.setUsersPassword.started,
    (state): Reducer => ({
      ...state,
      isUsersPasswdSetting: true
    })
  )
  .case(
    actions.setUsersPassword.done,
    (state): Reducer => ({
      ...state,
      isUsersPasswdSetting: false
    })
  )
  .case(
    actions.setUsersPassword.failed,
    (state): Reducer => ({
      ...state,
      isUsersPasswdSetting: false
    })
  )
  //  Get Users
  .case(
    actions.getUsers.started,
    (state): Reducer => ({
      ...state,
      areUsersLoading: true
    })
  )
  .case(
    actions.getUsers.done,
    (state, payload): Reducer => ({
      ...state,
      users: payload.result,
      areUsersLoading: false
    })
  )
  .case(
    actions.getUsers.failed,
    (state): Reducer => ({
      ...state,
      areUsersLoading: false
    })
  )

  //  Get User Info
  .case(
    actions.getUserInfo.started,
    (state): Reducer => ({
      ...state,
      isUserInfoLoading: true
    })
  )
  .case(
    actions.getUserInfo.done,
    (state, payload): Reducer => ({
      ...state,
      userInfo: payload.result,
      isUserInfoLoading: false
    })
  )
  .case(
    actions.getUserInfo.failed,
    (state): Reducer => ({
      ...state,
      isUserInfoLoading: false
    })
  )

  //  Update User Info
  .case(
    actions.updateUserInfo.started,
    (state): Reducer => ({
      ...state,
      isUserInfoUpdating: true
    })
  )
  .case(
    actions.updateUserInfo.done,
    (state, payload): Reducer => ({
      ...state,
      userInfo: payload.result,
      isUserInfoUpdating: false
    })
  )
  .case(
    actions.updateUserInfo.failed,
    (state): Reducer => ({
      ...state,
      isUserInfoUpdating: false
    })
  )

  //  Update Admin Roles
  .case(
    actions.updateAdminRoles.started,
    (state): Reducer => ({
      ...state,
      isUserInfoUpdating: true
    })
  )
  .case(
    actions.updateUserInfo.done,
    (state, payload): Reducer => ({
      ...state,
      userInfo: payload.result,
      isUserInfoUpdating: false
    })
  )
  .case(
    actions.updateUserInfo.failed,
    (state): Reducer => ({
      ...state,
      isUserInfoUpdating: false
    })
  )

  //  Get User's Organizations
  .case(
    actions.getUserOrganizations.started,
    (state): Reducer => ({
      ...state,
      areUserOrganizationsLoading: true
    })
  )
  .case(
    actions.getUserOrganizations.done,
    (state, payload): Reducer => ({
      ...state,
      userOrganizations: payload.result,
      areUserOrganizationsLoading: false
    })
  )
  .case(
    actions.getUserOrganizations.failed,
    (state): Reducer => ({
      ...state,
      areUserOrganizationsLoading: false
    })
  )

  //  Modify User Enabling
  .case(
    actions.modifyUserEnabling.started,
    (state): Reducer => ({
      ...state,
      isUserModifyingEnabling: true
    })
  )
  .case(
    actions.modifyUserEnabling.done,
    (state): Reducer => ({
      ...state,
      isUserModifyingEnabling: false
    })
  )
  .case(
    actions.modifyUserEnabling.failed,
    (state): Reducer => ({
      ...state,
      isUserModifyingEnabling: false
    })
  )

  // Clear User's Organizations
  .case(
    actions.clearUserOrganizations,
    (state): Reducer => ({
      ...state,
      userOrganizations: initialState.userOrganizations
    })
  )

  // Clear User Info
  .case(
    actions.clearUserInfo,
    (state): Reducer => ({
      ...state,
      userInfo: initialState.userInfo
    })
  )

  // Clear Users List
  .case(
    actions.clearUsersList,
    (state): Reducer => ({
      ...state,
      users: initialState.users
    })
  )

  .case(actions.clear, (): Reducer => initialState);
