import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import {
  groupSelector,
  isGroupDeletingSelector,
  isGroupUpdatingSelector,
  isUserRemovingFromGroupSelector,
  organizationSelector,
  organizationProjectsSelector,
  // isGroupFromProjectRemovingSelector,
  // isGroupToProjectAssigningSelector,
  groupUsersSelector
} from "modules/enterprises/selectors";
import {
  OrganizationUser,
  OrganizationProject
} from "modules/enterprises/types";
import * as pollingActions from "modules/polling/actions";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getParentPath } from "utils/getParentPath";
import { getProjectRegion } from "utils/getProjectRegion";
import { validateName } from "utils/validateName";
import { string } from "yup";
import {
  ADMIN_ROLES,
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  REGEX,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES } from "./types";
import { adminRoleSelector } from "modules/auth/selectors";

const POLL_ID_PREFIX = "GROUP";

const POLL_IDS = {
  group: "GROUP",
  groupUsers: "GROUP_USERS",
  organizationProjects: "ORGANIZATION_PROJECTS"
};

const userTableColumns: TableColumn<OrganizationUser>[] = [
  { key: "full_name", label: "Full name" },
  { key: "id", label: "ID" },
  { key: "email", label: "E-mail" }
];

const projectTableColumns: TableColumn<OrganizationProject>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "region", label: "Region" }
];

export const Group: FC = () => {
  const dispatch = useDispatch();
  const adminRole = useSelector(adminRoleSelector);
  const isUserManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.USER_MANAGEMENT)
  );

  const matchParams = useParams<{
    organizationId: string;
    groupId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const group = useSelector(groupSelector);
  const users = useSelector(groupUsersSelector);

  const isUserRemoving = useSelector(isUserRemovingFromGroupSelector);
  const previousIsUserRemoving = usePrevious(isUserRemoving);
  const organizationProjects = useSelector(organizationProjectsSelector);
  const isGroupUpdating = useSelector(isGroupUpdatingSelector);
  const isGroupDeleting = useSelector(isGroupDeletingSelector);
  // const isGroupAssigning = useSelector(isGroupToProjectAssigningSelector);
  const isGroupOperationInProgress =
    // isGroupAssigning ||
    isGroupUpdating || isGroupDeleting;

  const previousIsGroupOperationInProgress = usePrevious(
    isGroupOperationInProgress
  );

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  // const currentProjectName = group?.projects.find(
  //   (groupProject) => groupProject.id === currentItemId
  // )?.name;
  const currentUserName = users?.find(
    (user) => user.id === currentItemId
  )?.email;

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.ADD_USER, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      setDialog({
        type: dialogType,
        isOpened: true
      });
    },
    []
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: group?.name || "",
      url: generatePath(ROUTES.GROUP, {
        organizationId: matchParams.organizationId,
        groupId: matchParams.groupId
      })
    }
  ];

  const generateProjectTableItemURL = useCallback(
    (id: string) => {
      if (group) {
        return generatePath(ROUTES.PROJECT, {
          organizationId: matchParams.organizationId,
          regionId: getProjectRegion(group?.projects, id),
          projectId: id
        });
      }
    },
    [matchParams.organizationId, group]
  );

  const headerMenuItems = [
    {
      title: "Edit group",
      icon: <EditIcon />,
      onClick: () => handleDialogOpen(DIALOG_TYPES.EDIT_GROUP)
    },
    {
      title: "Delete group",
      icon: <DeleteIcon />,
      onClick: () => handleDialogOpen(DIALOG_TYPES.DELETE_GROUP)
    }
  ];

  const userTableActions: TableRowActionsMenuItem<OrganizationUser>[] = [
    {
      label: "Remove",
      // isDisabled: (user) => Boolean(user.invitationId),
      handler: () => handleDialogOpen(DIALOG_TYPES.REMOVE_USER)
    }
  ];

  const projectTableActions: TableRowActionsMenuItem<OrganizationProject>[] = [
    // {
    //   label: "Remove",
    //   handler: () => handleDialogOpen(DIALOG_TYPES.REMOVE_PROJECT_FROM_GROUP)
    // }
  ];

  const handleConfirmAddUser = useCallback(
    (data: { email: string }) => {
      dispatch(
        enterprisesActions.addUserToGroup.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!,
          userEmail: data.email
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.organizationId,
      matchParams.groupId,
      handleCloseDialog
    ]
  );

  const handleConfirmRemoveUser = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.removeUserFromGroup.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!,
          userId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    dispatch,
    matchParams.organizationId,
    matchParams.groupId,
    handleCloseDialog
  ]);

  // const handleConfirmAssignProjectToGroup = useCallback(
  //   (data: { project: SelectOption }) => {
  //     if (organizationProjects) {
  //       const regionId = getProjectRegion(
  //         organizationProjects,
  //         data.project.value
  //       );
  //       if (regionId) {
  //         dispatch(
  //           projectsActions.assignGroupToProject.started({
  //             projectId: data.project.value,
  //             regionId,
  //             organizationId: matchParams.organizationId,
  //             groupId: matchParams.groupId
  //           })
  //         );
  //       }
  //     }
  //     handleCloseDialog();
  //   },
  //   [
  //     dispatch,
  //     organizationProjects,
  //     matchParams.organizationId,
  //     matchParams.groupId,
  //     handleCloseDialog
  //   ]
  // );

  // const handleConfirmRemoveProjectFromGroup = useCallback(() => {
  //   if (group && selectedItemId) {
  //     const regionId = getProjectRegion(group.projects, selectedItemId);
  //     if (regionId) {
  //       dispatch(
  //         projectsActions.removeGroupFromProject.started({
  //           projectId: selectedItemId,
  //           regionId,
  //           organizationId: matchParams.organizationId,
  //           groupId: matchParams.groupId
  //         })
  //       );
  //     }
  //   }
  //   handleCloseDialog();
  // }, [
  //   dispatch,
  //   group,
  //   selectedItemId,
  //   matchParams.organizationId,
  //   matchParams.groupId,
  //   handleCloseDialog
  // ]);

  const handleConfirmEditGroup = useCallback(
    (data: { name: string }) => {
      dispatch(
        enterprisesActions.updateGroup.started({
          organizationId: matchParams.organizationId!,
          id: matchParams.groupId!,
          data
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      matchParams.groupId
    ]
  );

  const handleConfirmDeleteGroup = useCallback(() => {
    dispatch(
      enterprisesActions.deleteGroup.started({
        organizationId: matchParams.organizationId!,
        id: matchParams.groupId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    handleCloseDialog,
    matchParams.organizationId,
    matchParams.groupId
  ]);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.group}`,
        action: enterprisesActions.getGroup.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.groupUsers}`,
        action: enterprisesActions.getGroupUsers.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!
        })
      })
    );

    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.organizationProjects}`,
        action: enterprisesActions.getOrganizationProjects.started({
          organizationId: matchParams.organizationId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
  });

  useEffect(() => {
    if (previousIsUserRemoving && !isUserRemoving) {
      dispatch(
        enterprisesActions.getGroupUsers.started({
          organizationId: matchParams.organizationId!,
          groupId: matchParams.groupId!
        })
      );
    }
  }, [
    previousIsUserRemoving,
    isUserRemoving,
    dispatch,
    matchParams.organizationId,
    matchParams.groupId
  ]);

  useEffect(() => {
    if (previousIsGroupOperationInProgress && !isGroupOperationInProgress) {
      if (!group) {
        history(getParentPath(location.pathname));
      } else {
        dispatch(
          enterprisesActions.getGroup.started({
            organizationId: matchParams.organizationId!,
            groupId: matchParams.groupId!
          })
        );
      }
    }
  }, [
    group,
    history,
    previousIsGroupOperationInProgress,
    isGroupOperationInProgress,
    dispatch,
    matchParams.organizationId,
    matchParams.groupId
  ]);

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.ADD_USER]: {
      onConfirm: handleConfirmAddUser,
      title: "Add user",
      confirmButtonLabel: "Add",
      fields: [
        {
          name: "email",
          type: FIELD_TYPES.TEXT,
          label: "E-mail address",
          rules: string()
            .required()
            .matches(REGEX.EMAIL_ADDRESS, ERROR_MESSAGES.EMAIL_ADDRESS)
        }
      ]
    },
    [DIALOG_TYPES.REMOVE_USER]: {
      onConfirm: handleConfirmRemoveUser,
      title: `Are you sure you want to remove "${
        currentUserName ?? "selected user"
      }" from the group?`,
      confirmButtonLabel: "Remove"
    },
    // [DIALOG_TYPES.ASSIGN_PROJECT_TO_GROUP]: {
    //   onConfirm: handleConfirmAssignProjectToGroup,
    //   title: "Assign to the project",
    //   confirmButtonLabel: "Assign",
    //   fields: [
    //     {
    //       name: "project",
    //       type: FIELD_TYPES.SELECT,
    //       label: "Project",
    //       options: organizationProjects
    //         ?.filter(
    //           (organizationProject) =>
    //             !group?.projects
    //               .map((groupProject) => groupProject.id)
    //               .includes(organizationProject.id)
    //         )
    //         .map((project) => getSelectOption(project, "name", "id")),
    //       rules: selectOptionSchema
    //     }
    //   ]
    // },
    // [DIALOG_TYPES.REMOVE_PROJECT_FROM_GROUP]: {
    //   onConfirm: handleConfirmRemoveProjectFromGroup,
    //   title: `Are you sure you want to remove "${
    //     currentProjectName ?? "selected"
    //   }" project from the group?`,
    //   confirmButtonLabel: "Remove"
    // },
    [DIALOG_TYPES.EDIT_GROUP]: {
      onConfirm: handleConfirmEditGroup,
      title: "Edit group",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: group?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.GROUP_NAME, ERROR_MESSAGES.GROUP_NAME)
        }
      ]
    },
    [DIALOG_TYPES.DELETE_GROUP]: {
      onConfirm: handleConfirmDeleteGroup,
      title: `Are you sure you want to delete "${
        group?.name ?? "selected"
      }" group?`,
      confirmButtonLabel: "Delete"
    }
  };

  const title = group?.name;

  return (
    <>
      <Head title={title} />
      {group ? (
        <>
          {organization && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.Title variant={"h4"} component={"h2"}>
              {title}
            </s.Title>
            {isUserManagementAllowed ? (
              <s.ActionsContainer>
                {headerMenuItems.map((menuItem, index) => (
                  <Tooltip title={menuItem.title} arrow key={index}>
                    <IconButton
                      onClick={() => menuItem.onClick && menuItem.onClick()}
                      color={"inherit"}
                    >
                      {menuItem.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              </s.ActionsContainer>
            ) : (
              ""
            )}
          </s.SummaryContainer>
          <s.SummaryRow>
            <s.DetailsTitleMain>ID: </s.DetailsTitleMain>
            <s.DetailsInfoMainColored>{group.id}</s.DetailsInfoMainColored>
          </s.SummaryRow>

          <Table<OrganizationUser>
            isSearchEnabled={true}
            isSortingEnabled={true}
            rows={users || []}
            columns={userTableColumns}
            actions={userTableActions}
            toolbarItems={
              <Button
                onClick={() => handleDialogOpen(DIALOG_TYPES.ADD_USER)}
                variant={"contained"}
              >
                Add user
              </Button>
            }
            isLoading={!users}
          />

          <s.Description variant={"h6"}>
            The users above have access to the following projects
          </s.Description>

          <Table<OrganizationProject>
            isSearchEnabled={true}
            isSortingEnabled={true}
            rows={group?.projects || []}
            columns={projectTableColumns}
            actions={projectTableActions}
            itemLink={{
              column: "name",
              getURL: generateProjectTableItemURL
            }}
            toolbarItems={
              <Button
                // onClick={() =>
                //   handleDialogOpen(DIALOG_TYPES.ASSIGN_PROJECT_TO_GROUP)
                // }
                variant={"contained"}
                disabled={!organizationProjects}
              >
                Assign to the project
              </Button>
            }
            isLoading={!group}
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
