export enum DIALOG_TYPES {
  EDIT,
  SET_QUOTAS,
  // SET_QUOTA,
  SET_VM_QUOTA,
  SET_VM_vCPUs_QUOTA,
  SET_VM_MEMORY_QUOTA,
  SET_VOL_QUOTA,
  SET_VOL_SNAP_QUOTA,
  SET_VOL_STORAGE_QUOTA,
  SET_NET_QUOTA,
  SET_FW_QUOTA,
  SET_FW_R_QUOTA,
  DELETE
}
