import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Project, ProjectQuotas } from "./types";

export type Reducer = {
  projects: Project[] | null;
  areProjectsLoading: boolean;
  project: Project | null;
  isProjectLoading: boolean;
  isProjectCreating: boolean;
  isProjectUpdating: boolean;
  isProjectDeleting: boolean;
  projectQuotas: ProjectQuotas | null;
  areProjectQuotasSetting: boolean;
  areProjectQuotasLoading: boolean;
  //   isGroupToProjectAssigning: boolean;
  //   isGroupFromProjectRemoving: boolean;
};

const initialState: Reducer = {
  projects: null,
  areProjectsLoading: false,
  project: null,
  isProjectLoading: false,
  isProjectCreating: false,
  isProjectUpdating: false,
  isProjectDeleting: false,
  projectQuotas: null,
  areProjectQuotasSetting: false,
  areProjectQuotasLoading: false
  //   isGroupToProjectAssigning: false,
  //   isGroupFromProjectRemoving: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getProject.started,
    (state): Reducer => ({
      ...state,
      isProjectLoading: true
    })
  )
  .case(
    actions.getProject.done,
    (state, payload): Reducer => ({
      ...state,
      project: payload.result,
      isProjectLoading: false
    })
  )
  .case(
    actions.getProject.failed,
    (state): Reducer => ({
      ...state,
      isProjectLoading: false
    })
  )
  .case(
    actions.getProjects.started,
    (state): Reducer => ({
      ...state,
      areProjectsLoading: true
    })
  )
  .case(
    actions.getProjects.done,
    (state, payload): Reducer => ({
      ...state,
      projects: payload.result,
      areProjectsLoading: false
    })
  )
  .case(
    actions.getProjects.failed,
    (state): Reducer => ({
      ...state,
      areProjectsLoading: false
    })
  )

  .case(
    actions.createProject.started,
    (state): Reducer => ({
      ...state,
      isProjectCreating: true
    })
  )
  .case(
    actions.createProject.done,
    (state): Reducer => ({
      ...state,
      isProjectCreating: false
    })
  )
  .case(
    actions.createProject.failed,
    (state): Reducer => ({
      ...state,
      isProjectCreating: false
    })
  )
  .case(
    actions.updateProject.started,
    (state): Reducer => ({
      ...state,
      isProjectUpdating: true
    })
  )
  .case(
    actions.updateProject.done,
    (state): Reducer => ({
      ...state,
      isProjectUpdating: false
    })
  )
  .case(
    actions.updateProject.failed,
    (state): Reducer => ({
      ...state,
      isProjectUpdating: false
    })
  )
  .case(
    actions.deleteProject.started,
    (state): Reducer => ({
      ...state,
      isProjectDeleting: true
    })
  )
  .case(
    actions.deleteProject.done,
    (state): Reducer => ({
      ...state,
      isProjectDeleting: false,
      project: null
    })
  )
  .case(
    actions.deleteProject.failed,
    (state): Reducer => ({
      ...state,
      isProjectDeleting: false
    })
  )
  .case(
    actions.setProjectQuotas.started,
    (state): Reducer => ({
      ...state,
      areProjectQuotasSetting: true
    })
  )
  .case(
    actions.setProjectQuotas.done,
    (state): Reducer => ({
      ...state,
      areProjectQuotasSetting: false
    })
  )
  .case(
    actions.setProjectQuotas.failed,
    (state): Reducer => ({
      ...state,
      areProjectQuotasSetting: false
    })
  )
  .case(
    actions.getProjectQuotas.started,
    (state): Reducer => ({
      ...state,
      areProjectQuotasLoading: true
    })
  )
  .case(
    actions.getProjectQuotas.done,
    (state, payload): Reducer => ({
      ...state,
      projectQuotas: payload.result,
      areProjectQuotasLoading: false
    })
  )
  .case(
    actions.getProjectQuotas.failed,
    (state): Reducer => ({
      ...state,
      areProjectQuotasLoading: false
    })
  )

  //   .case(
  //     actions.assignGroupToProject.started,
  //     (state): Reducer => ({
  //       ...state,
  //       isGroupToProjectAssigning: true
  //     })
  //   )
  //   .case(
  //     actions.assignGroupToProject.done,
  //     (state): Reducer => ({
  //       ...state,
  //       isGroupToProjectAssigning: false
  //     })
  //   )
  //   .case(
  //     actions.assignGroupToProject.failed,
  //     (state): Reducer => ({
  //       ...state,
  //       isGroupToProjectAssigning: false
  //     })
  //   )
  //   .case(
  //     actions.removeGroupFromProject.started,
  //     (state): Reducer => ({
  //       ...state,
  //       isGroupFromProjectRemoving: true
  //     })
  //   )
  //   .case(
  //     actions.removeGroupFromProject.done,
  //     (state): Reducer => ({
  //       ...state,
  //       isGroupFromProjectRemoving: false
  //     })
  //   )
  //   .case(
  //     actions.removeGroupFromProject.failed,
  //     (state): Reducer => ({
  //       ...state,
  //       isGroupFromProjectRemoving: false
  //     })
  //   )

  // Clear Projects List
  .case(
    actions.clearProjectsList,
    (state): Reducer => ({
      ...state,
      projects: initialState.projects
    })
  )
  .case(actions.clear, (): Reducer => initialState);
