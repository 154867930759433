import { RootReducer } from "../../reducers";
import { Volume, TableVolume } from "./types";
import { createSelector } from "reselect";
import { formatDate } from "utils/formatDate";
import { DATE_FORMATS } from "../../constants";

// errored volumes
export const areErroredVolumesLoadingSelector = (state: RootReducer): boolean =>
  state.volumes.areErroredVolumesLoading;

export const erroredVolumesSelector = (state: RootReducer): Volume[] | null =>
  state.volumes.erroredVolumes;

export const tableErroredVolumesSelector = createSelector(
  [erroredVolumesSelector],
  (volumes): TableVolume[] | null => {
    return volumes
      ? volumes.map((volume) => ({
          id: volume.id,
          name: volume.name,
          status: volume.status.toUpperCase(),
          size: volume.size,
          sizeString: `${volume.size} GiB`,
          bootable: volume.bootable ? "Yes" : "No",
          user_id: volume.user_id,
          project_id: volume.project_id,
          project_name: volume.project_name,
          organization_id: volume.organization_id,
          email: volume.email,
          user_name: volume.user_name,
          serverId:
            volume.attachments && volume.attachments.length > 0
              ? volume.attachments[0].server_id
              : "",
          region: volume.region,
          created: formatDate(
            new Date(volume.created_at),
            DATE_FORMATS.DATETIME
          )
        }))
      : null;
  }
);
