import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import {
  OrganizationUser,
  Organization,
  OrganizationProject,
  Group
} from "./types";

export type Reducer = {
  organization: Organization | null;
  group: Group | null;
  groupUsers: OrganizationUser[] | null;
  organizations: Organization[] | null;
  organizationUsers: OrganizationUser[] | null;
  organizationProjects: OrganizationProject[] | null;
  organizationGroups: Group[] | null;
  isOrganizationLoading: boolean;
  areOrganizationsLoading: boolean;
  isOrganizationCreating: boolean;
  isOrganizationUpdating: boolean;
  isOrganizationDeleting: boolean;
  isOrganizationOwnerChanging: boolean;
  isAdminAddingToOrganization: boolean;
  isAdminRemovingFromOrganization: boolean;
  areOrganizationUsersLoading: boolean;
  areOrganizationProjectsLoading: boolean;
  areOrganizationGroupsLoading: boolean;
  isGroupCreating: boolean;
  isGroupUpdating: boolean;
  isGroupDeleting: boolean;
  isGroupLoading: boolean;
  areGroupUsersLoading: boolean;
  isUserAddingToGroup: boolean;
  isUserRemovingFromGroup: boolean;

  // isUserRemovingFromOrganization: boolean;
  // userIdToRemoveFromOrganization: string | null;
  // userRemovalFromOrganizationError: string | null;
};

const initialState: Reducer = {
  organization: null,
  group: null,
  groupUsers: null,
  organizations: null,
  organizationUsers: null,
  organizationProjects: null,
  organizationGroups: null,
  isOrganizationLoading: false,
  areOrganizationsLoading: false,
  isOrganizationCreating: false,
  isOrganizationUpdating: false,
  isOrganizationDeleting: false,
  isOrganizationOwnerChanging: false,
  isAdminAddingToOrganization: false,
  isAdminRemovingFromOrganization: false,
  areOrganizationUsersLoading: false,
  areOrganizationProjectsLoading: false,
  areOrganizationGroupsLoading: false,
  isGroupCreating: false,
  isGroupUpdating: false,
  isGroupDeleting: false,
  isGroupLoading: false,
  areGroupUsersLoading: false,
  isUserAddingToGroup: false,
  isUserRemovingFromGroup: false
  // isUserRemovingFromOrganization: false,
  // userIdToRemoveFromOrganization: null,
  // userRemovalFromOrganizationError: null
};

export const reducer = reducerWithInitialState(initialState)
  // Get Organization
  .case(
    actions.getOrganization.started,
    (state): Reducer => ({
      ...state,
      isOrganizationLoading: true
    })
  )
  .case(
    actions.getOrganization.done,
    (state, payload): Reducer => ({
      ...state,
      organization: payload.result,
      isOrganizationLoading: false
    })
  )
  .case(
    actions.getOrganization.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationLoading: false
    })
  )

  // Get all organizations
  .case(
    actions.getOrganizations.started,
    (state): Reducer => ({
      ...state,
      areOrganizationsLoading: true
    })
  )
  .case(
    actions.getOrganizations.done,
    (state, payload): Reducer => ({
      ...state,
      organizations: payload.result,
      areOrganizationsLoading: false
    })
  )
  .case(
    actions.getOrganizations.failed,
    (state): Reducer => ({
      ...state,
      areOrganizationsLoading: false
    })
  )

  // Create Organization
  .case(
    actions.createOrganization.started,
    (state): Reducer => ({
      ...state,
      isOrganizationCreating: true
    })
  )
  .case(
    actions.createOrganization.done,
    (state): Reducer => ({
      ...state,
      isOrganizationCreating: false
    })
  )
  .case(
    actions.createOrganization.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationCreating: false
    })
  )

  // Update Organization
  .case(
    actions.updateOrganization.started,
    (state): Reducer => ({
      ...state,
      isOrganizationUpdating: true
    })
  )
  .case(
    actions.updateOrganization.done,
    (state): Reducer => ({
      ...state,
      isOrganizationUpdating: false
    })
  )
  .case(
    actions.updateOrganization.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationUpdating: false
    })
  )

  // Delete Organization
  .case(
    actions.deleteOrganization.started,
    (state): Reducer => ({
      ...state,
      isOrganizationDeleting: true
    })
  )
  .case(
    actions.deleteOrganization.done,
    (state): Reducer => ({
      ...state,
      isOrganizationDeleting: false,
      organization: null
    })
  )
  .case(
    actions.deleteOrganization.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationDeleting: false
    })
  )

  // Change Organization ownership (transfer Org)
  .case(
    actions.changeOrganizationOwner.started,
    (state): Reducer => ({
      ...state,
      isOrganizationOwnerChanging: true
    })
  )
  .case(
    actions.changeOrganizationOwner.done,
    (state): Reducer => ({
      ...state,
      isOrganizationOwnerChanging: false
    })
  )
  .case(
    actions.changeOrganizationOwner.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationOwnerChanging: false
    })
  )

  // Add Admin to Organization
  .case(
    actions.addAdminToOrganization.started,
    (state): Reducer => ({
      ...state,
      isAdminAddingToOrganization: true
    })
  )
  .case(
    actions.addAdminToOrganization.done,
    (state): Reducer => ({
      ...state,
      isAdminAddingToOrganization: false
    })
  )
  .case(
    actions.addAdminToOrganization.failed,
    (state): Reducer => ({
      ...state,
      isAdminAddingToOrganization: false
    })
  )

  // Remove Admin from Organization
  .case(
    actions.removeAdminFromOrganization.started,
    (state): Reducer => ({
      ...state,
      isAdminRemovingFromOrganization: true
    })
  )
  .case(
    actions.removeAdminFromOrganization.done,
    (state): Reducer => ({
      ...state,
      isAdminRemovingFromOrganization: false
    })
  )
  .case(
    actions.removeAdminFromOrganization.failed,
    (state): Reducer => ({
      ...state,
      isAdminRemovingFromOrganization: false
    })
  )

  // Get Organization Users
  .case(
    actions.getOrganizationUsers.started,
    (state): Reducer => ({
      ...state,
      areOrganizationUsersLoading: true
    })
  )
  .case(
    actions.getOrganizationUsers.done,
    (state, payload): Reducer => ({
      ...state,
      organizationUsers: payload.result,
      areOrganizationUsersLoading: false
    })
  )
  .case(
    actions.getOrganizationUsers.failed,
    (state): Reducer => ({
      ...state,
      areOrganizationUsersLoading: false
    })
  )

  // Get Organization Projects
  .case(
    actions.getOrganizationProjects.started,
    (state): Reducer => ({
      ...state,
      areOrganizationProjectsLoading: true
    })
  )
  .case(
    actions.getOrganizationProjects.done,
    (state, payload): Reducer => ({
      ...state,
      organizationProjects: payload.result,
      areOrganizationProjectsLoading: false
    })
  )
  .case(
    actions.getOrganizationProjects.failed,
    (state): Reducer => ({
      ...state,
      areOrganizationProjectsLoading: false
    })
  )

  // Create Group
  .case(
    actions.createGroup.started,
    (state): Reducer => ({
      ...state,
      isGroupCreating: true
    })
  )
  .case(
    actions.createGroup.done,
    (state): Reducer => ({
      ...state,
      isGroupCreating: false
    })
  )
  .case(
    actions.createGroup.failed,
    (state): Reducer => ({
      ...state,
      isGroupCreating: false
    })
  )
  // Get Group
  .case(
    actions.getGroup.started,
    (state): Reducer => ({
      ...state,
      isGroupLoading: true
    })
  )
  .case(
    actions.getGroup.done,
    (state, payload): Reducer => ({
      ...state,
      group: payload.result,
      isGroupLoading: false
    })
  )
  .case(
    actions.getGroup.failed,
    (state): Reducer => ({
      ...state,
      isGroupLoading: false
    })
  )
  // Get Group Users
  .case(
    actions.getGroupUsers.started,
    (state): Reducer => ({
      ...state,
      areGroupUsersLoading: true
    })
  )
  .case(
    actions.getGroupUsers.done,
    (state, payload): Reducer => ({
      ...state,
      groupUsers: payload.result,
      areGroupUsersLoading: false
    })
  )
  .case(
    actions.getGroupUsers.failed,
    (state): Reducer => ({
      ...state,
      areGroupUsersLoading: false
    })
  )
  // get organization Groups
  .case(
    actions.getOrganizationGroups.started,
    (state): Reducer => ({
      ...state,
      areOrganizationGroupsLoading: true
    })
  )
  .case(
    actions.getOrganizationGroups.done,
    (state, payload): Reducer => ({
      ...state,
      organizationGroups: payload.result,
      areOrganizationGroupsLoading: true
    })
  )
  .case(
    actions.getOrganizationGroups.failed,
    (state): Reducer => ({
      ...state,
      areOrganizationGroupsLoading: false
    })
  )
  // updateGroup
  .case(
    actions.updateGroup.started,
    (state): Reducer => ({
      ...state,
      isGroupUpdating: true
    })
  )
  .case(
    actions.updateGroup.done,
    (state): Reducer => ({
      ...state,
      isGroupUpdating: false
    })
  )
  .case(
    actions.updateGroup.failed,
    (state): Reducer => ({
      ...state,
      isGroupUpdating: false
    })
  )
  // deleteGroup
  .case(
    actions.deleteGroup.started,
    (state): Reducer => ({
      ...state,
      isGroupDeleting: true
    })
  )
  .case(
    actions.deleteGroup.done,
    (state): Reducer => ({
      ...state,
      isGroupDeleting: false,
      group: null
    })
  )
  .case(
    actions.deleteGroup.failed,
    (state): Reducer => ({
      ...state,
      isGroupDeleting: false
    })
  )
  // addUserToGroup
  .case(
    actions.addUserToGroup.started,
    (state): Reducer => ({
      ...state,
      isUserAddingToGroup: true
    })
  )
  .case(
    actions.addUserToGroup.done,
    (state): Reducer => ({
      ...state,
      isUserAddingToGroup: false
    })
  )
  .case(
    actions.addUserToGroup.failed,
    (state): Reducer => ({
      ...state,
      isUserAddingToGroup: false
    })
  )
  //removeUserFromGroup
  .case(
    actions.removeUserFromGroup.started,
    (state): Reducer => ({
      ...state,
      isUserRemovingFromGroup: true
    })
  )
  .case(
    actions.removeUserFromGroup.done,
    (state): Reducer => ({
      ...state,
      isUserRemovingFromGroup: false
    })
  )
  .case(
    actions.removeUserFromGroup.failed,
    (state): Reducer => ({
      ...state,
      isUserRemovingFromGroup: false
    })
  )

  .case(actions.clear, (): Reducer => initialState);
