import actionCreatorFactory from "typescript-fsa";
import {
  GetHypervisorsParams,
  GetHypervisorsResponse,
  GetRegionResourceUtilizationParams,
  GetRegionResourceUtilizationResponse,
  GetResourceUtilizationParams,
  GetResourceUtilizationResponse
} from "./types";

const actionCreator = actionCreatorFactory("MONITORING");

export const getResourceUtilizationData = actionCreator.async<
  GetResourceUtilizationParams,
  GetResourceUtilizationResponse,
  unknown
>("GET_RESOURCE_UTILIZATION_DATA");

export const getRegionResourceUtilizationData = actionCreator.async<
  GetRegionResourceUtilizationParams,
  GetRegionResourceUtilizationResponse,
  unknown
>("GET_REGION_RESOURCE_UTILIZATION_DATA");

export const getHypervisorsData = actionCreator.async<
  GetHypervisorsParams,
  GetHypervisorsResponse,
  unknown
>("GET_HYPERVISORS_DATA");

export const clear = actionCreator<void>("CLEAR");
