import { Tab, Tabs } from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { CustomSelect } from "components/common/CustomSelect";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as instancesActions from "modules/instances/actions";
import * as volumesActions from "modules/volumes/actions";
import * as pollingActions from "modules/polling/actions";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { appConfig } from "../../appConfig";
import {
  DEFAULT_DEBOUNCE_DELAY,
  DEFAULT_REGION,
  ROUTES,
  VMS_STATUSES_FILTER_OPTIONS
} from "../../constants";
import * as s from "./styles";
import { TableVirtualMachine } from "modules/instances/types";
import {
  areInstancesLoadingSelector,
  instancesSelector,
  tableVirtualMachinesSelector
} from "modules/instances/selectors";
import { cancelVmsTokens } from "modules/instances/sagas";
import { TABS } from "./types";
import { TableVolume } from "modules/volumes/types";
import {
  areErroredVolumesLoadingSelector,
  tableErroredVolumesSelector,
  erroredVolumesSelector
} from "modules/volumes/selectors";
import { cancelVolumesTokens } from "modules/volumes/sagas";

const POLL_ID_PREFIX = "FAILED_RESOURCES";

const POLL_IDS = {
  VMS: "VMs",
  VOLUMES: "VOLUMES"
};

// export const FAILED_RESOURCES_TAB_TITLES: { [key in TABS]: string } = {
//   [TABS.INSTANCES]: "Instances",
//   [TABS.CLUSTERS]: "Clusters",
//   [TABS.VOLUMES]: "Volumes"
// };

const title = "Issues";

const breadcrumbs: Breadcrumb[] = [
  { text: "Issues", url: ROUTES.FAILED_RESOURCES }
];

const tableVMsColumns: TableColumn<TableVirtualMachine>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "flavor", label: "Flavor" },
  { key: "status", label: "Status" },
  { key: "ips", label: "IP" },
  { key: "project_name", label: "Project" },
  { key: "organization_id", label: "OrgID" },
  { key: "region", label: "Region" },
  { key: "email", label: "Email" },
  {
    key: "created",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

const tableVolumesColumns: TableColumn<TableVolume>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "status", label: "Status" },
  { key: "sizeString", label: "Size" },
  // { key: "bootable", label: "Bootable" },
  // { key: "serverId", label: "Server ID" },
  { key: "project_name", label: "Project" },
  { key: "organization_id", label: "OrgID" },
  { key: "region", label: "Region" },
  { key: "email", label: "Email" },
  {
    key: "created",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

export const FailedREsources: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const adminRole = useSelector(adminRoleSelector);

  const instances = useSelector(instancesSelector);
  const instancesTable = useSelector(tableVirtualMachinesSelector);
  const areInstancesLoading = useSelector(areInstancesLoadingSelector);

  const erroredVolumes = useSelector(erroredVolumesSelector);
  const erroredVolumesTable = useSelector(tableErroredVolumesSelector);
  const areErroredVolumesLoading = useSelector(
    areErroredVolumesLoadingSelector
  );

  const queryParams = new URLSearchParams(location.search);

  const searchParams = queryParams.get("search");
  const [search, setSearch] = useState<string>(
    searchParams ? String(searchParams) : ""
  );
  const debouncedSearch = useDebounce(search, DEFAULT_DEBOUNCE_DELAY);
  const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    // setPage(0);
  }, []);

  const tableRegionsOptions = (
    appConfig.availableRegions
      ? [
          DEFAULT_REGION,
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const showOnlyRegionParam = queryParams.get("regions");
  const [showOnlyRegion, setShowOnlyRegion] = useState<string>(
    showOnlyRegionParam || tableRegionsOptions[0].value
  );
  const handleChangeRegionFilter = useCallback((value: string) => {
    setShowOnlyRegion(value);
    // setPage(0);
  }, []);

  const showOnlyStatusParam = queryParams.get("status");
  const [showOnlyStatus, setShowOnlyStatus] = useState<string>(
    showOnlyStatusParam?.toLocaleUpperCase() ||
      VMS_STATUSES_FILTER_OPTIONS[0].value
  );
  const handleChangeStatusFilter = useCallback((value: string) => {
    setShowOnlyStatus(value);
    // setPage(0);
  }, []);

  // const prevPage = usePrevious(page);
  // const prevRowsPerPage = usePrevious(rowsPerPage);
  // const prevSortColumn = usePrevious(sortColumn);
  // const prevSortOrder = usePrevious(sortOrder);
  // const prevDebouncedSearch = usePrevious(debouncedSearch);
  const prevShowOnlyRegion = usePrevious(showOnlyRegion);
  const prevShowOnlyStatus = usePrevious(showOnlyStatus);
  const prevDebounceSearch = usePrevious(debouncedSearch);

  const TAB_TITLES: { [key in TABS]: { title: string; disabled: boolean } } =
    useMemo(
      () => ({
        [TABS.INSTANCES]: {
          title: "Instances",
          disabled: false
        },
        [TABS.VOLUMES]: {
          title: "Volumes",
          disabled: false
        },
        [TABS.CLUSTERS]: {
          title: "Clusters",
          disabled: true
        },
        [TABS.ORPHANED_RESOURCES]: {
          title: "Orphaned Resources",
          disabled: true
        }
      }),
      []
    );

  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.INSTANCES) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.VMS}`,
        action: instancesActions.getInstances.started({
          regions: showOnlyRegion,
          status: "ERROR",
          filter: "all",
          ...(debouncedSearch ? { search: debouncedSearch } : {})
        })
      })
    );
    history({
      search: generateSearchString({
        tab: TAB_TITLES[TABS.INSTANCES].title,
        regions: String(showOnlyRegion),
        ...(debouncedSearch ? { search: debouncedSearch } : {})
      })
    });
  });

  useEffect(() => {
    if (previousActiveTabIndex !== activeTabIndex) {
      setShowOnlyRegion(tableRegionsOptions[0].value);
      // setShowOnlyStatus(VMS_STATUSES_FILTER_OPTIONS[0].value);

      // Cancel the previous requests due to new parameters
      cancelVmsTokens["listVms"]?.cancel("Cancel the previous request");
      cancelVmsTokens["listVms"] = null;
      cancelVolumesTokens["listErroredVolumes"]?.cancel(
        "Cancel the previous request"
      );
      cancelVolumesTokens["listErroredVolumes"] = null;

      // stop polling
      Object.values(POLL_IDS).forEach((id) => {
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${id}`
          })
        );
      });

      // clear state - to add loaders if change some of the options
      dispatch(instancesActions.clearInstancesList());
      dispatch(volumesActions.clearErroredVolumesList());

      switch (activeTabIndex) {
        case TABS.INSTANCES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.INSTANCES].title,
              regions: showOnlyRegion
            })
          });
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VMS}`,
              action: instancesActions.getInstances.started({
                regions: showOnlyRegion,
                status: "ERROR",
                filter: "all",
                ...(debouncedSearch ? { search: debouncedSearch } : {})
              })
            })
          );

          break;
        case TABS.VOLUMES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.VOLUMES].title,
              regions: String(showOnlyRegion)
            })
          });
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VOLUMES}`,
              action: volumesActions.getErroredVolumes.started({
                regions: showOnlyRegion
              })
            })
          );

          break;
        case TABS.CLUSTERS:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.CLUSTERS].title,
              regions: String(showOnlyRegion)
            })
          });

          // dispatch(
          //   pollingActions.startPolling({
          //     id: `${POLL_ID_PREFIX}/${POLL_IDS.gpuInstances}`,
          //     action: instancesActions.getGpuInstances.started({
          //       regions: showOnlyRegion,
          //       status: showOnlyStatus
          //     })
          //   })
          // );
          break;
      }
    }
  }, [
    previousActiveTabIndex,
    activeTabIndex,
    tableRegionsOptions,
    history,
    TAB_TITLES,
    showOnlyRegion,
    prevShowOnlyRegion,
    dispatch,
    debouncedSearch
  ]);

  useEffect(() => {
    if (
      (prevShowOnlyRegion !== undefined &&
        prevShowOnlyRegion !== showOnlyRegion) ||
      (prevDebounceSearch !== undefined &&
        prevDebounceSearch !== debouncedSearch)
    ) {
      switch (activeTabIndex) {
        case TABS.INSTANCES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.INSTANCES].title,
              regions: showOnlyRegion,
              ...(debouncedSearch ? { search: debouncedSearch } : {})
            })
          });
          // Cancel the previous requests due to new parameters
          cancelVmsTokens["listVms"]?.cancel("Cancel the previous request");
          cancelVmsTokens["listVms"] = null;

          // stop polling
          dispatch(
            pollingActions.stopPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VMS}`
            })
          );

          // clear state - to add loaders if change some of the options
          dispatch(instancesActions.clearInstancesList());

          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VMS}`,
              action: instancesActions.getInstances.started({
                regions: showOnlyRegion,
                status: "ERROR",
                ...(debouncedSearch ? { search: debouncedSearch } : {})
              })
            })
          );

          break;
        case TABS.VOLUMES:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.VOLUMES].title, // tab: "gpu",
              regions: String(showOnlyRegion),
              ...(debouncedSearch ? { search: debouncedSearch } : {})
            })
          });
          // Cancel the previous requests due to new parameters
          cancelVolumesTokens["listErroredVolumes"]?.cancel(
            "Cancel the previous request"
          );
          cancelVolumesTokens["listErroredVolumes"] = null;

          // stop polling
          dispatch(
            pollingActions.stopPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VOLUMES}`
            })
          );

          // clear state - to add loaders if change some of the options
          dispatch(volumesActions.clearErroredVolumesList());

          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.VOLUMES}`,
              action: volumesActions.getErroredVolumes.started({
                regions: showOnlyRegion
              })
            })
          );
          break;
      }
    }
  }, [
    previousActiveTabIndex,
    activeTabIndex,
    tableRegionsOptions,
    history,
    TAB_TITLES,
    showOnlyRegion,
    prevShowOnlyRegion,
    dispatch,
    prevDebounceSearch,
    debouncedSearch
  ]);

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(instancesActions.clear());
    dispatch(volumesActions.clear());
  });

  const generateVMsTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: instancesTable?.find((vm) => vm.id == id)?.user_id
      }),
    [instancesTable]
  );

  const generateVolumesTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.USER, {
        userId: erroredVolumesTable?.find((v) => v.id == id)?.user_id
      }),
    [erroredVolumesTable]
  );

  const tabContent = [
    <>
      <Table<TableVirtualMachine>
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={instancesTable || []}
        columns={tableVMsColumns}
        isLoading={!instancesTable}
        itemLink={{
          column: "email",
          getURL: generateVMsTableItemURL
        }}
        itemWithIcons={[
          {
            withText: false,
            column: "status",
            getIcon: (row) => {
              return (
                <s.TypographyColoredDanger>
                  {row["status"]}
                </s.TypographyColoredDanger>
              );
              // return (
              //   <s.CustomTableCellText>{row["status"]}</s.CustomTableCellText>
              // ); // or default icon
            },
            tooltipText: (row) => {
              return `Error: ${String(row.faultMessage)}`;
            }
          }
        ]}
        // customItem={
        //   <s.CustomDiv>
        //     <CustomSelect
        //       label={"Region"}
        //       options={tableRegionsOptions}
        //       defaultValue={tableRegionsOptions[0].value}
        //       onChange={handleChangeRegionFilter}
        //     />
        //   </s.CustomDiv>
        // }
        toolbarItems={
          <>
            <CustomSelect
              label={"Region"}
              options={tableRegionsOptions}
              defaultValue={tableRegionsOptions[0].value}
              value={showOnlyRegion}
              onChange={handleChangeRegionFilter}
              margin={"0 15px 0 0"}
            />
          </>
        }
      />
    </>,
    <>
      <Table<TableVolume>
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={erroredVolumesTable || []}
        columns={tableVolumesColumns}
        isLoading={!erroredVolumesTable}
        itemLink={{
          column: "email",
          getURL: generateVolumesTableItemURL
        }}
        itemWithIcons={[
          {
            withText: false,
            column: "status",
            getIcon: (row) => {
              return (
                <s.TypographyColoredDanger>
                  {row["status"]}
                </s.TypographyColoredDanger>
              );
            },
            tooltipText: (row) => {
              return ``;
            }
          }
        ]}
        toolbarItems={
          <>
            <CustomSelect
              label={"Region"}
              options={tableRegionsOptions}
              defaultValue={tableRegionsOptions[0].value}
              value={showOnlyRegion}
              onChange={handleChangeRegionFilter}
              margin={"0 15px 0 0"}
            />
          </>
        }
      />
    </>,
    <></>
  ];

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>
      <s.TabsContainer>
        <Tabs value={activeTabIndex} onChange={handleChangeTab}>
          {Object.values(TAB_TITLES).map((tab) => (
            <Tab key={tab.title} label={tab.title} disabled={tab.disabled} />
          ))}
        </Tabs>
      </s.TabsContainer>
      {tabContent[activeTabIndex]}
    </>
  );
};
