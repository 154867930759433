import actionCreatorFactory from "typescript-fsa";
import {
  GetGpuInstancesParams,
  GetGpuInstancesResponse,
  GetInstancesCachedParams,
  GetInstancesCachedResponse,
  GetInstancesParams,
  GetInstancesResponse,
  UpdateCacheInstancesResponse
} from "./types";

const actionCreator = actionCreatorFactory("INSTANCES");

export const getGpuInstances = actionCreator.async<
  GetGpuInstancesParams,
  GetGpuInstancesResponse,
  unknown
>("GET_GPU_INSTANCES");

export const getInstances = actionCreator.async<
  GetInstancesParams,
  GetInstancesResponse,
  unknown
>("GET_INSTANCES");

export const getInstancesCached = actionCreator.async<
  GetInstancesCachedParams,
  GetInstancesCachedResponse,
  unknown
>("GET_CACHED_INSTANCES");

export const updateCacheInstances = actionCreator.async<
  void,
  UpdateCacheInstancesResponse,
  unknown
>("UPDATE_CACHE_INSTANCES");

export const clearInstancesList = actionCreator<void>("CLEAR_INSTANCES_LIST");
export const clearCachedInstancesList = actionCreator<void>(
  "CLEAR_CACHED_INSTANCES_LIST"
);
export const clearGpuInstancesList = actionCreator<void>(
  "CLEAR_GPU_INSTANCES_LIST"
);

export const clear = actionCreator<void>("CLEAR");
