import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { HypervisorsData, ResourceUtilizationData } from "./types";

export type Reducer = {
  resourceUtilizationData: ResourceUtilizationData[] | null;
  isResourceUtilizationDataLoading: boolean;
  regionResourceUtilizationData: ResourceUtilizationData[] | null;
  isRegionResourceUtilizationDataLoading: boolean;
  hypervisorsData: HypervisorsData | null;
  isHypervisorsDataLoading: boolean;
};

const initialState: Reducer = {
  resourceUtilizationData: null,
  isResourceUtilizationDataLoading: false,
  regionResourceUtilizationData: null,
  isRegionResourceUtilizationDataLoading: false,
  hypervisorsData: null,
  isHypervisorsDataLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getResourceUtilizationData.started,
    (state): Reducer => ({
      ...state,
      isResourceUtilizationDataLoading: true
    })
  )
  .case(
    actions.getResourceUtilizationData.done,
    (state, payload): Reducer => ({
      ...state,
      resourceUtilizationData: payload.result,
      isResourceUtilizationDataLoading: false
    })
  )
  .case(
    actions.getResourceUtilizationData.failed,
    (state): Reducer => ({
      ...state,
      isResourceUtilizationDataLoading: false
    })
  )

  .case(
    actions.getRegionResourceUtilizationData.started,
    (state): Reducer => ({
      ...state,
      isRegionResourceUtilizationDataLoading: true
    })
  )
  .case(
    actions.getRegionResourceUtilizationData.done,
    (state, payload): Reducer => ({
      ...state,
      regionResourceUtilizationData: payload.result,
      isRegionResourceUtilizationDataLoading: false
    })
  )
  .case(
    actions.getRegionResourceUtilizationData.failed,
    (state): Reducer => ({
      ...state,
      isRegionResourceUtilizationDataLoading: false
    })
  )

  .case(
    actions.getHypervisorsData.started,
    (state): Reducer => ({
      ...state,
      isHypervisorsDataLoading: true
    })
  )
  .case(
    actions.getHypervisorsData.done,
    (state, payload): Reducer => ({
      ...state,
      hypervisorsData: payload.result,
      isHypervisorsDataLoading: false
    })
  )
  .case(
    actions.getHypervisorsData.failed,
    (state): Reducer => ({
      ...state,
      isHypervisorsDataLoading: false
    })
  )

  .case(actions.clear, (): Reducer => initialState);
