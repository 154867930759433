import axios, { AxiosResponse, CancelTokenSource } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  // AssignGroupToProjectParams,
  // RemoveGroupFromProjectParams,
  CreateProjectParams,
  CreateProjectResponse,
  DeleteProjectParams,
  GetProjectParams,
  GetProjectQuotasParams,
  GetProjectQuotasResponse,
  GetProjectResponse,
  GetProjectsParams,
  GetProjectsResponse,
  SetProjectQuotasParams,
  SetProjectQuotasResponse,
  UpdateProjectParams,
  UpdateProjectResponse
} from "./types";

export const cancelProjectsTokens: {
  [key: string]: CancelTokenSource | undefined | null;
} = {};
cancelProjectsTokens["listProjects"] = axios.CancelToken.source();

export function* getProjectsSaga(
  action: Action<GetProjectsParams>
): SagaIterator<void> {
  try {
    // Create a new token if it doesn't exist
    if (!cancelProjectsTokens["listProjects"]) {
      cancelProjectsTokens["listProjects"] = axios.CancelToken.source();
    }

    const response: AxiosResponse<GetProjectsResponse> = yield call(
      axiosInstance.get,
      `gotham-enterprises/method/admin/projects?region=${action.payload.region}`,
      { cancelToken: cancelProjectsTokens["listProjects"]?.token }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProjects.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getProjects.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get projects data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* getProjectSaga(
  action: Action<GetProjectParams>
): SagaIterator<void> {
  try {
    const { id } = action.payload;
    const response: AxiosResponse<GetProjectResponse> = yield call(
      axiosInstance.get,
      `gotham-enterprises/method/admin/projects/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProject.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getProject.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get project data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createProjectSaga(
  action: Action<CreateProjectParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the project...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, organizationId, name } = action.payload;
    const response: AxiosResponse<CreateProjectResponse> = yield call(
      axiosInstance.post,
      `gotham-governor/method/admin/orgs/${organizationId}/projects/${name}/regions/${region}`
    );

    // Start new session to gain access to the created project (commented due to problem with relogin after cancelProjectsTokens functionality)
    // console.log("Before relogin");
    // yield* apiReloginSaga();
    // console.log("After relogin");

    yield put(
      actions.createProject.done({
        params: action.payload,
        result: response.data
      })
    );

    yield put(
      notificationsActions.showNotification({
        title: "Project has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createProject.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create project",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateProjectSaga(
  action: Action<UpdateProjectParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the project...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, organizationId, id, data } = action.payload;
    const response: AxiosResponse<UpdateProjectResponse> = yield call(
      axiosInstance.put,
      `gotham-${region}-identity/method/${organizationId}/projects/${id}`,
      data
    );
    yield put(
      actions.updateProject.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Project has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateProject.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update project",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteProjectSaga(
  action: Action<DeleteProjectParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the project...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, organizationId, id } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-identity/method/${organizationId}/projects/${id}`
    );
    yield put(
      actions.deleteProject.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Project has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteProject.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete project",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* setProjectQuotasSaga(
  action: Action<SetProjectQuotasParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Setting quotas for the project...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, id, data } = action.payload;
    const response: AxiosResponse<SetProjectQuotasResponse> = yield call(
      axiosInstance.put,
      `gotham-governor/method/admin/projects/${id}/regions/${region}/quotas`,
      data
    );
    yield put(
      actions.setProjectQuotas.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Project quotas have been successfully set.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.setProjectQuotas.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to set project quotas",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getProjectQuotasSaga(
  action: Action<GetProjectQuotasParams>
): SagaIterator<void> {
  try {
    const { region, id } = action.payload;
    const response: AxiosResponse<GetProjectQuotasResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/projects/${id}/regions/${region}/quotas`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProjectQuotas.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getProjectQuotas.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get project quotas",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

// export function* assignGroupToProjectSaga(
//   action: Action<AssignGroupToProjectParams>
// ): SagaIterator<void> {
//   try {
//     yield put(
//       notificationsActions.showNotification({
//         title: "Assigning group to the project...",
//         type: NOTIFICATION_TYPES.PROGRESS
//       })
//     );
//     const { regionId, organizationId, groupId, projectId } = action.payload;
//     yield call(
//       axiosInstance.post,
//       `gotham-${regionId}-identity/method/${organizationId}/projects/${projectId}/groups/${groupId}`
//     );
//     yield put(
//       actions.assignGroupToProject.done({
//         params: action.payload
//       })
//     );
//     yield put(
//       notificationsActions.showNotification({
//         title: "Group has been successfully assigned to the project.",
//         type: NOTIFICATION_TYPES.SUCCESS
//       })
//     );
//   } catch (e) {
//     yield put(
//       actions.assignGroupToProject.failed({ params: action.payload, error: e })
//     );
//     yield put(
//       notificationsActions.showNotification({
//         title: "Failed to assign group to the project",
//         text: getAxiosErrorMessage(e),
//         type: NOTIFICATION_TYPES.ERROR
//       })
//     );
//   }
// }

// export function* removeGroupFromProjectSaga(
//   action: Action<RemoveGroupFromProjectParams>
// ): SagaIterator<void> {
//   try {
//     yield put(
//       notificationsActions.showNotification({
//         title: "Removing group from the project...",
//         type: NOTIFICATION_TYPES.PROGRESS
//       })
//     );
//     const { regionId, organizationId, groupId, projectId } = action.payload;
//     yield call(
//       axiosInstance.delete,
//       `gotham-${regionId}-identity/method/${organizationId}/projects/${projectId}/groups/${groupId}`
//     );
//     yield put(
//       actions.removeGroupFromProject.done({
//         params: action.payload
//       })
//     );
//     yield put(
//       notificationsActions.showNotification({
//         title: "Group has been successfully removed from the project.",
//         type: NOTIFICATION_TYPES.SUCCESS
//       })
//     );
//   } catch (e) {
//     yield put(
//       actions.removeGroupFromProject.failed({
//         params: action.payload,
//         error: e
//       })
//     );
//     yield put(
//       notificationsActions.showNotification({
//         title: "Failed to remove group from the project",
//         text: getAxiosErrorMessage(e),
//         type: NOTIFICATION_TYPES.ERROR
//       })
//     );
//   }
// }

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getProject.started, getProjectSaga),
    takeEvery(actions.getProjects.started, getProjectsSaga),
    takeEvery(actions.createProject.started, createProjectSaga),
    takeEvery(actions.updateProject.started, updateProjectSaga),
    takeEvery(actions.deleteProject.started, deleteProjectSaga),
    takeEvery(actions.setProjectQuotas.started, setProjectQuotasSaga),
    takeLeading(actions.getProjectQuotas.started, getProjectQuotasSaga)
    // takeEvery(actions.assignGroupToProject.started, assignGroupToProjectSaga),
    // takeEvery(
    //   actions.removeGroupFromProject.started,
    //   removeGroupFromProjectSaga
    // )
  ]);
}
