import { useTheme } from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { PieChartMetric } from "components/common/PieChartMetric";
import { useMount } from "hooks/useMount";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as monitoringActions from "modules/monitoring/actions";
import {
  isResourceUtilizationDataLoadingSelector,
  resourceUtilizationDataSelector
} from "modules/monitoring/selectors";
import * as pollingActions from "modules/polling/actions";
import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatMemorySize } from "utils/formatSize";
import { ADMIN_ROLES, ROUTES } from "../../constants";
import * as s from "./styles";

const POLL_ID_PREFIX = "MONITORING";
const POLL_IDS = {
  RAM: "RAM"
};

export const MonitoringRam: FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const adminRole = useSelector(adminRoleSelector);

  const isObserveRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.OBSERVE)
  );
  const monitoringData = useSelector(resourceUtilizationDataSelector);
  const isMonitoringDataLoading = useSelector(
    isResourceUtilizationDataLoadingSelector
  );

  const title = `RAM Monitoring`;

  const breadcrumbs: Breadcrumb[] = [{ text: "RAM", url: ROUTES.RAM }];

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.RAM}`,
        action: monitoringActions.getResourceUtilizationData.started({
          region: "all"
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(monitoringActions.clear());
  });

  // const renderMetric = (
  //   title: string,
  //   usedValue: number,
  //   freeValue: number,
  //   maxValue: number,
  //   unit: string = "",
  //   simpleMetrics = [],
  //   formatter
  // ) => {
  //   return (
  //     <PieChartMetric
  //       title={title}
  //       usedValue={usedValue}
  //       freeValue={freeValue}
  //       maxValue={maxValue}
  //       unit={unit}
  //       simpleMetrics={simpleMetrics}
  //       formatter={formatter}
  //     />
  //   );
  // };

  const renderMetric = (
    title: string,
    dataChart: { label: string; value: number; color?: string }[],
    total: number,
    unit: string = "",
    simpleMetrics = [],
    formatter
  ) => {
    return (
      <PieChartMetric
        title={title}
        total={total}
        unit={unit}
        dataChart={dataChart}
        simpleMetrics={simpleMetrics}
        formatter={formatter}
        showTotalString
      />
    );
  };

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>

      <s.MetricsContainer variant={"outlined"}>
        {!monitoringData ? (
          <Loader text={"Loading data..."} />
        ) : (
          <>
            <s.MetricRowContainer>
              {monitoringData.map((data, index) => (
                <s.MetricContainer key={index}>
                  {renderMetric(
                    data.region,
                    [
                      {
                        label: "Used",
                        value: data.statistics.memory_mb_used,
                        color: theme.palette.primary.main
                      },
                      {
                        label: "Free",
                        value: data.statistics.free_ram_mb,
                        color: theme.palette.secondary.light
                      }
                    ],
                    data.statistics.memory_mb,
                    "MB",
                    [],
                    formatMemorySize
                  )}
                </s.MetricContainer>
              ))}
            </s.MetricRowContainer>
          </>
        )}
      </s.MetricsContainer>
    </>
  );
};
