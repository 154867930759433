export enum DIALOG_TYPES {
  CREATE,
  ENABLE_2FA,
  DISABLE_2FA,
  GENERATE_BILLING_REPORT,
  SET_ADMIN_ROLES,
  CHANGE_QRG_QUOTAS,
  SET_PASSWORD,
  DISABLE_USER,
  ENABLE_USER,
  MODIFY_USER_TYPE,
  MODIFY_TRIAL_PERIOD
}
