import { watcherSaga as authSaga } from "modules/auth/sagas";
import { watcherSaga as billingSaga } from "modules/billing/sagas";
import { watcherSaga as enterprisesSaga } from "modules/enterprises/sagas";
import { watcherSaga as pollingSaga } from "modules/polling/sagas";
import { watcherSaga as projectsSaga } from "modules/projects/sagas";
import { watcherSaga as supportSaga } from "modules/support/sagas";
import { watcherSaga as usersSaga } from "modules/users/sagas";
import { watcherSaga as networkingSaga } from "modules/networking/sagas";
import { watcherSaga as instancesSaga } from "modules/instances/sagas";
import { watcherSaga as volumesSaga } from "modules/volumes/sagas";
import { watcherSaga as monitoringSaga } from "modules/monitoring/sagas";
import { all, AllEffect } from "redux-saga/effects";

export function* rootSaga(): Generator<AllEffect<unknown>, void, unknown> {
  yield all([
    authSaga(),
    pollingSaga(),
    billingSaga(),
    usersSaga(),
    supportSaga(),
    enterprisesSaga(),
    projectsSaga(),
    networkingSaga(),
    instancesSaga(),
    volumesSaga(),
    monitoringSaga()
  ]);
}
