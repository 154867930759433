import { Tooltip, useTheme, Zoom } from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { Head } from "components/common/Head";
import { useMount } from "hooks/useMount";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as monitoringActions from "modules/monitoring/actions";
import * as pollingActions from "modules/polling/actions";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appConfig } from "../../appConfig";

import { pieArcLabelClasses, PieChart } from "@mui/x-charts";
import { CustomSelect } from "components/common/CustomSelect";
import { Loader } from "components/common/Loader";
import { usePrevious } from "hooks/usePrevious";
import {
  hypervisorsDataSelector,
  isHypervisorsDataLoadingSelector,
  isRegionResourceUtilizationDataLoadingSelector,
  regionResourceUtilizationDataSelector,
  resourceUtilizationDataSelector,
  tableHypervisorsDataSelector
} from "modules/monitoring/selectors";
import { formatMemorySize } from "utils/formatSize";
import { getSelectOption } from "utils/getSelectOption";
import { ADMIN_ROLES, ROUTES } from "../../constants";
import * as s from "./styles";
import { TABS } from "./types";
import { useNavigate } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { TableHypervisorsData } from "modules/monitoring/types";
import { TableColumn } from "components/common/Table/types";
import { Table } from "components/common/Table";
import { cancelMonitoringTokens } from "modules/monitoring/sagas";

const POLL_ID_PREFIX = "MONITORING";
const POLL_IDS = {
  REGION_RESOURCE_UTILIZATION: "region_resource_utilization",
  RESOURCE_UTILIZATION: "resource_utilization",
  HYPERVISORS_DATA: "hypervisors"
};

export const MonitoringOverview: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const theme = useTheme();

  const adminRole = useSelector(adminRoleSelector);
  const isObserveRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.OBSERVE)
  );
  const regionMonitoringData = useSelector(
    regionResourceUtilizationDataSelector
  );
  const isRegionResourceUtilizationDataLoading = useSelector(
    isRegionResourceUtilizationDataLoadingSelector
  );
  const monitoringData = useSelector(resourceUtilizationDataSelector);
  // const isMonitoringDataLoading = useSelector(isMonitoringDataLoadingSelector);

  const hypervisorsData = useSelector(hypervisorsDataSelector);
  const tableHypervisorsData = useSelector(tableHypervisorsDataSelector);
  const isHypervisorsDataLoading = useSelector(
    isHypervisorsDataLoadingSelector
  );

  const tableHypervisorsColumns: TableColumn<TableHypervisorsData>[] = [
    { key: "id", label: "Hostname", width: "15%" },
    { key: "running_vms", label: "VMs" },
    { key: "vcpus_used", label: "vCPUs used" },
    { key: "vcpus_total", label: "vCPUs total" },
    { key: "ram_used", label: "RAM used" },
    { key: "ram_total", label: "RAM total" },
    { key: "time_since_update", label: "Updated" },
    { key: "status", label: "Status", width: "9%", isCentered: false },
    { key: "state", label: "State", width: "80px", isCentered: true }
  ];

  const MONITORING_TAB_TITLES: {
    [key in TABS]: { title: string; disabled: boolean };
  } = useMemo(
    () => ({
      [TABS.GENERAL_OVERVIEW]: {
        title: "Region Overview",
        disabled: false
      },
      [TABS.RAM]: {
        title: "RAM",
        disabled: false
      },
      [TABS.CPU]: {
        title: "CPU",
        disabled: false
      },
      [TABS.DISK_SPACE]: {
        title: "Disk space",
        disabled: true
      }
    }),
    []
  );

  const activeTabIndexFromParam = Object.keys(MONITORING_TAB_TITLES).find(
    (key) =>
      MONITORING_TAB_TITLES[key] &&
      String(MONITORING_TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.GENERAL_OVERVIEW) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const allRegionsAsOptions = (
    appConfig.availableRegions
      ? [
          ...appConfig.availableRegions.map((region) => ({
            name: region,
            id: region
          }))
        ]
      : []
  ).map((region) => getSelectOption(region, "name", "id"));

  const [showOnly, setShowOnly] = useState<string>(
    allRegionsAsOptions[0].value
  );
  const previousShowOnly = usePrevious(showOnly);

  const handleChangeFilter = useCallback((value: string) => {
    setShowOnly(value);
  }, []);

  const title = `Hypervisors Overview`;

  const breadcrumbs: Breadcrumb[] = [
    { text: "Hypervisors", url: ROUTES.UTILIZATION }
    // {
    //   text: project?.name || "",
    //   url: generatePath(ROUTES.PROJECT, {
    //     region: matchParams.region,
    //     projectId: matchParams.projectId
    //   })
    // }
  ];

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.REGION_RESOURCE_UTILIZATION}`,
        action: monitoringActions.getRegionResourceUtilizationData.started({
          region: showOnly
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.HYPERVISORS_DATA}`,
        action: monitoringActions.getHypervisorsData.started({
          region: showOnly
        })
      })
    );
    // dispatch(
    //   pollingActions.startPolling({
    //     id: `${POLL_ID_PREFIX}/${POLL_IDS.RESOURCE_UTILIZATION}`,
    //     action: monitoringActions.getResourceUtilizationData.started({
    //       region: ""
    //     })
    //   })
    // );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(monitoringActions.clear());
  });

  useEffect(() => {
    if (
      isObserveRoleAllowed &&
      previousShowOnly !== undefined &&
      previousShowOnly !== showOnly
    ) {
      // Cancel the previous requests due to new parameters
      cancelMonitoringTokens["regionResourceUtilization"]?.cancel();
      cancelMonitoringTokens["regionResourceUtilization"] = null;
      cancelMonitoringTokens["hypervisors"]?.cancel();
      cancelMonitoringTokens["hypervisors"] = null;

      // stop polling
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.REGION_RESOURCE_UTILIZATION}`
        })
      );
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.HYPERVISORS_DATA}`
        })
      );

      // clear state - to add loaders if change some of the options
      dispatch(monitoringActions.clear());

      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.REGION_RESOURCE_UTILIZATION}`,
          action: monitoringActions.getRegionResourceUtilizationData.started({
            region: showOnly
          })
        })
      );
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.HYPERVISORS_DATA}`,
          action: monitoringActions.getHypervisorsData.started({
            region: showOnly
          })
        })
      );
    }
  }, [dispatch, isObserveRoleAllowed, previousShowOnly, showOnly]);

  interface SimpleMetric {
    label: string;
    value: number | string;
    unit: string;
    width?: string;
  }

  type FormatterFunction = (value: number) => { value: number; unit: string };

  // the same, as if use PieChartMetric custom component
  const renderMetric = (
    title: string,
    dataChart: { label: string; value: number; color?: string }[],
    total: number,
    unit: string = "",
    simpleMetrics: SimpleMetric[] = [],
    formatter: FormatterFunction | null = null,
    showTotalString: boolean = true,
    width?: number
  ) => {
    const data = dataChart.map((item, index) => {
      const formatted = formatter
        ? formatter(item.value)
        : { value: item.value, unit };

      return {
        id: index + 1,
        value: Number(((item.value / total) * 100).toFixed(0)), // %
        label: `${item.label}: ${formatted.value} ${formatted.unit}`,
        ...(item.color ? { color: item.color } : {})
      };
    });

    const formattedTotal = formatter
      ? formatter(total)
      : { value: total, unit };

    return (
      <s.MetricWrapper>
        <s.ChartContainer>
          <s.MetricTitleContainer>
            <s.MetricTitle>{title}</s.MetricTitle>
          </s.MetricTitleContainer>
          <s.MetricTitleContainer>
            {showTotalString && (
              <s.DetailsInfoColored>
                Total: {formattedTotal.value} {formattedTotal.unit}
              </s.DetailsInfoColored>
            )}
          </s.MetricTitleContainer>
          <PieChart
            series={[
              {
                data,
                innerRadius: 30,
                outerRadius: 80,
                paddingAngle: 5,
                cornerRadius: 5,
                startAngle: -90,
                endAngle: 360,
                cx: 90,
                cy: 90,
                arcLabel: (item) => `${item.value} %`,
                valueFormatter: (value) => `${value.value} %`,
                arcLabelMinAngle: 45,
                highlightScope: { faded: "global", highlighted: "item" },
                highlighted: {
                  additionalRadius: 1
                  // innerRadius: 41
                  // color: theme.palette.secondary.dark
                }
              }
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: "white",
                fontWeight: "bold"
              }
            }}
            slotProps={{
              legend: {
                direction: "column",
                position: { vertical: "middle", horizontal: "right" },
                padding: 0,
                markGap: 6,
                itemGap: 8,
                itemMarkHeight: 14,
                labelStyle: {
                  fontSize: 13,
                  fontWeight: 430,
                  width: width ? "145px" : "initial"
                }
              }
            }}
            // tooltip={{
            //   trigger: "none",
            //   itemContent: (props) => <div {...props}>{unit}</div>
            // }}
            width={width ? width : 325}
            height={200}
          />
        </s.ChartContainer>
        {simpleMetrics.length > 0 && (
          <s.SimpleMetricsContainer>
            {simpleMetrics.map((metric) => (
              <s.SimpleMetricItem key={metric.label} width={metric.width}>
                <s.SimpleMetricTitle>{metric.label}</s.SimpleMetricTitle>
                <s.SimpleMetricValue>
                  {metric.value} {metric.unit}
                </s.SimpleMetricValue>
              </s.SimpleMetricItem>
            ))}
          </s.SimpleMetricsContainer>
        )}
      </s.MetricWrapper>
    );
  };

  const renderSimpleMetric = (title, value, unit, helperText = "") => {
    return (
      // <s.MetricWrapper>
      <s.SimpleMetricsContainer>
        <Tooltip
          title={helperText}
          arrow
          placement={"top"}
          TransitionComponent={Zoom}
        >
          <s.SimpleMetricItem2>
            <s.SimpleMetricTitle>{title}</s.SimpleMetricTitle>
            <s.SimpleMetricValue>
              {value} {unit}
            </s.SimpleMetricValue>
          </s.SimpleMetricItem2>
        </Tooltip>
      </s.SimpleMetricsContainer>
      // </s.MetricWrapper>
    );
  };

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.SummaryContainer>
        <s.Title variant={"h4"} component={"h2"}>
          {title}
        </s.Title>
      </s.SummaryContainer>
      <>
        <CustomSelect
          label={"Region"}
          options={allRegionsAsOptions}
          defaultValue={allRegionsAsOptions[0].value}
          onChange={handleChangeFilter}
          margin="20px 0 10px 0"
        />
        <s.MetricsContainer variant={"outlined"}>
          {!regionMonitoringData ? (
            <Loader text={"Loading data..."} />
          ) : (
            <>
              <s.TitleRegion component="h4">
                {regionMonitoringData.region}
              </s.TitleRegion>
              <s.MetricRowContainer>
                <s.MetricContainer>
                  {renderSimpleMetric(
                    "Hypervisors Count",
                    regionMonitoringData.statistics.count,
                    ""
                  )}
                  {renderSimpleMetric(
                    "Running VMs",
                    regionMonitoringData.statistics.running_vms,
                    "VMs"
                    // "The total number of running VMs on all hypervisors"
                  )}
                </s.MetricContainer>
                <s.MetricContainer>
                  {renderMetric(
                    "RAM Usage",
                    [
                      {
                        label: "Used",
                        value: regionMonitoringData.statistics.memory_mb_used,
                        color: theme.palette.primary.main
                      },
                      {
                        label: "Free",
                        value: regionMonitoringData.statistics.free_ram_mb,
                        color: theme.palette.secondary.light
                      }
                    ],
                    regionMonitoringData.statistics.memory_mb,
                    "MB",
                    [],
                    formatMemorySize
                  )}
                </s.MetricContainer>
                <s.MetricContainer>
                  {renderMetric(
                    "vCPU Usage",
                    [
                      {
                        label: "Used",
                        value: regionMonitoringData.statistics.vcpus_used,
                        color: theme.palette.primary.main
                      },
                      {
                        label: "Free",
                        value:
                          regionMonitoringData.statistics.vcpus -
                          regionMonitoringData.statistics.vcpus_used,
                        color: theme.palette.secondary.light
                      }
                    ],

                    regionMonitoringData.statistics.vcpus,
                    "vCPUs",
                    [
                      {
                        label: "Physical Cores",
                        value: regionMonitoringData.statistics.cpus,
                        unit: ""
                      },
                      {
                        label: "vCPU",
                        value: regionMonitoringData.statistics.vcpus,
                        unit: ""
                      },
                      {
                        label: "Overallocation ratio",
                        value:
                          regionMonitoringData.statistics.vcpu_allocation_ratio,
                        unit: ""
                      }
                    ]
                  )}
                </s.MetricContainer>
              </s.MetricRowContainer>
            </>
          )}
        </s.MetricsContainer>
        <Table<TableHypervisorsData>
          // isSearchEnabled={true}
          isSortingEnabled={true}
          rows={tableHypervisorsData || []}
          columns={tableHypervisorsColumns}
          isLoading={!tableHypervisorsData}
          itemWithIcons={[
            {
              withText: false,
              column: "state",
              getIcon: (row) => {
                if (row["state"] === "up")
                  return (
                    <s.TypographyColoredSuccess>
                      {row["state"]}
                    </s.TypographyColoredSuccess>
                  );
                if (row["state"] === "down")
                  return (
                    <s.TypographyColoredDanger>
                      {row["state"]}
                    </s.TypographyColoredDanger>
                  );
                return (
                  <s.CustomTableCellText>{row["state"]}</s.CustomTableCellText>
                ); // or default icon
              },
              tooltipText: (row) => {
                return "";
              }
            },
            {
              withText: false,
              column: "status",
              getIcon: (row) => {
                if (row["status"] === "disabled")
                  return (
                    <s.TypographyColoredWarning>
                      {row["status"]}
                    </s.TypographyColoredWarning>
                  );
                return (
                  <s.CustomTableCellText>{row["status"]}</s.CustomTableCellText>
                ); // or default icon
              },
              tooltipText: (row) => {
                return `${String(row.service_disabled_reason)}`;
              }
            }
          ]}
        />
      </>
      {/* <s.TabsContainer>
        <Tabs value={activeTabIndex} onChange={handleChangeTab}>
          {Object.values(MONITORING_TAB_TITLES).map((tab) => (
            <Tab key={tab.title} label={tab.title} disabled={tab.disabled} />
          ))}
        </Tabs>
      </s.TabsContainer>
      {tabContent[activeTabIndex]} */}
    </>
  );
};
