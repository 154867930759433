import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";
import { Link, Paper } from "@mui/material";

export const Description = styled(Typography)`
  margin: ${({ theme }) => theme.spacing(3, 0, 1, 2)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: smaller;
`;

export const InfoDescription = styled(Description)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const DatePickerTextField = styled(TextField)`
  margin: ${({ theme }) => theme.spacing(0, 2, 1, 0)};
`;

export const CurrencyFormControl = styled(FormControl)`
  width: 120px;
`;

export const DetailsBlock = styled.div`
  flex: 1;
  margin: ${({ theme }) => theme.spacing(1)} auto;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const InfoTitle = styled(Typography)<
  TypographyProps<"h6", { component: "h6" }>
>`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const SummaryRowInfo = styled(SummaryRow)`
  margin-bottom: ${({ theme }) => theme.spacing(1.2)};
`;

export const DetailsTitle = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsInfo = styled.span`
  color: ${({ theme }) => theme.palette.text.secondary};
  white-space: nowrap;
  font-weight: 400;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const StyledLink = styled(Link)`
  white-space: nowrap;
  font-size: smaller;
  font-weight: 450;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const TabsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

export const DetailsTitleMain = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
  font-weight: 500;
  font-size: smaller;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsInfoMain = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
  font-size: smaller;
`;

export const DetailsInfoMainColored = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main};
  font-weight: 450;
  margin-right: ${({ theme }) => theme.spacing(2)};
  font-size: smaller;
`;

export const DetailsInfoMainColoredSuccess = styled(DetailsInfoMain)`
  color: ${({ theme }) => theme.palette.success.main};
  font-weight: 450;
`;

export const DetailsInfoMainColoredWarning = styled(DetailsInfoMain)`
  color: ${({ theme }) => theme.palette.warning.main};
  font-weight: 450;
`;

export const Tag = styled(Chip)`
  margin: ${({ theme }) => theme.spacing(0, 1, 0, 0)};
  font-size: smaller;
  /* color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main};
  font-weight: 450; */
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
`;

export const StyledPaper = styled(Paper)`
  overflow: auto;
  padding: ${({ theme }) => theme.spacing(1)};
`;
