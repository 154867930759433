import actionCreatorFactory from "typescript-fsa";
import {
  GetNetworkUsageParams,
  GetNetworkUsageResponse,
  GetPublicIPsParams,
  GetPublicIPsResponse,
  GetRegionNetworkUsageParams,
  GetRegionNetworkUsageResponse,
  UpdateCachePublicIPsResponse
} from "./types";

const actionCreator = actionCreatorFactory("NETWORKING");

export const getPublicIPs = actionCreator.async<
  GetPublicIPsParams,
  GetPublicIPsResponse,
  unknown
>("GET_PUBLIC_IPS");

export const updateCachePublicIPs = actionCreator.async<
  void,
  UpdateCachePublicIPsResponse,
  unknown
>("UPDATE_CACHE_PUBLIC_IPS");

export const getNetworkUsage = actionCreator.async<
  GetNetworkUsageParams,
  GetNetworkUsageResponse,
  unknown
>("GET_NETWORK_USAGE");

export const getRegionNetworkUsage = actionCreator.async<
  GetRegionNetworkUsageParams,
  GetRegionNetworkUsageResponse,
  unknown
>("GET_REGION_NETWORK_USAGE");

export const clearPublicIPsList = actionCreator<void>("CLEAR_PUBLIC_IPS_LIST");
export const clearNetworkUsage = actionCreator<void>("CLEAR_NETWORK_USAGE");
export const clearRegionNetworkUsage = actionCreator<void>(
  "CLEAR_REGION_NETWORK_USAGE"
);

export const clear = actionCreator<void>("CLEAR");
