import { formatRegion } from "utils/formatRegion";
import { RootReducer } from "../../reducers";
import {
  HypervisorsData,
  ResourceUtilizationData,
  TableHypervisorsData
} from "./types";
import { monitoringDraft, regionMonitoringDraft } from "./drafts";
import { formatMemorySize } from "utils/formatSize";
import { formatTime } from "utils/formatTime";

export const resourceUtilizationDataSelector = (
  state: RootReducer
  // ): ResourceUtilizationData[] | null => monitoringDraft;
): ResourceUtilizationData[] | null =>
  state.monitoring.resourceUtilizationData || null;

export const isResourceUtilizationDataLoadingSelector = (
  state: RootReducer
): boolean => state.monitoring.isResourceUtilizationDataLoading;

export const regionResourceUtilizationDataSelector = (
  state: RootReducer
  // ): ResourceUtilizationData | null => regionMonitoringDraft;
): ResourceUtilizationData | null =>
  state.monitoring.regionResourceUtilizationData?.[0] || null;

export const isRegionResourceUtilizationDataLoadingSelector = (
  state: RootReducer
): boolean => state.monitoring.isRegionResourceUtilizationDataLoading;

export const hypervisorsDataSelector = (
  state: RootReducer
): HypervisorsData | null => state.monitoring.hypervisorsData || null;

export const isHypervisorsDataLoadingSelector = (state: RootReducer): boolean =>
  state.monitoring.isHypervisorsDataLoading;

export const tableHypervisorsDataSelector = (
  state: RootReducer
): TableHypervisorsData[] | null =>
  state.monitoring.hypervisorsData
    ? state.monitoring.hypervisorsData.flatMap((regionData) =>
        regionData.hypervisors.map((hypervisor) => ({
          vcpus_total: hypervisor.vcpus,
          vcpus_used: hypervisor.vcpus_used,
          ram_free: `${formatMemorySize(hypervisor.free_ram_mb).value} ${formatMemorySize(hypervisor.free_ram_mb).unit}`,
          ram_total: `${formatMemorySize(hypervisor.memory_mb).value} ${formatMemorySize(hypervisor.memory_mb).unit}`,
          ram_used: `${formatMemorySize(hypervisor.memory_mb_used).value} ${formatMemorySize(hypervisor.memory_mb_used).unit}`,
          running_vms: hypervisor.running_vms,
          status: hypervisor.status,
          state: hypervisor.state,
          host_ip: hypervisor.host_ip,
          id: hypervisor.service_host,
          hypervisor_hostname: hypervisor.hypervisor_hostname,
          service_disabled_reason: hypervisor.service_disabled_reason,
          hypervisor_type: hypervisor.hypervisor_type,
          hypervisor_version: hypervisor.hypervisor_version,
          time_since_update: formatTime(hypervisor.time_since_update_ms),
          region: regionData.region
        }))
      )
    : null;
