import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { NetworkUsage, PublicIPs } from "./types";

export type Reducer = {
  publicIPs: PublicIPs | null;
  arePublicIPsLoading: boolean;
  isCacheUpdating: boolean;
  networkUsage: NetworkUsage[] | null;
  isNetworkUsageLoading: boolean;
  regionNetworkUsage: NetworkUsage[] | null;
  isRegionNetworkUsageLoading: boolean;
};

const initialState: Reducer = {
  publicIPs: null,
  arePublicIPsLoading: false,
  isCacheUpdating: false,
  networkUsage: null,
  isNetworkUsageLoading: false,
  regionNetworkUsage: null,
  isRegionNetworkUsageLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getPublicIPs.started,
    (state): Reducer => ({
      ...state,
      arePublicIPsLoading: true
    })
  )
  .case(
    actions.getPublicIPs.done,
    (state, payload): Reducer => ({
      ...state,
      publicIPs: payload.result,
      arePublicIPsLoading: false
    })
  )
  .case(
    actions.getPublicIPs.failed,
    (state): Reducer => ({
      ...state,
      arePublicIPsLoading: false
    })
  )

  .case(
    actions.updateCachePublicIPs.started,
    (state): Reducer => ({
      ...state,
      isCacheUpdating: true
    })
  )
  .case(
    actions.updateCachePublicIPs.done,
    (state, payload): Reducer => ({
      ...state,
      // publicIPs: payload.result,
      isCacheUpdating: false
    })
  )
  .case(
    actions.updateCachePublicIPs.failed,
    (state): Reducer => ({
      ...state,
      isCacheUpdating: false
    })
  )

  .case(
    actions.getNetworkUsage.started,
    (state): Reducer => ({
      ...state,
      isNetworkUsageLoading: true
    })
  )
  .case(
    actions.getNetworkUsage.done,
    (state, payload): Reducer => ({
      ...state,
      networkUsage: payload.result,
      isNetworkUsageLoading: false
    })
  )
  .case(
    actions.getNetworkUsage.failed,
    (state): Reducer => ({
      ...state,
      isNetworkUsageLoading: false
    })
  )

  .case(
    actions.getRegionNetworkUsage.started,
    (state): Reducer => ({
      ...state,
      isRegionNetworkUsageLoading: true
    })
  )
  .case(
    actions.getRegionNetworkUsage.done,
    (state, payload): Reducer => ({
      ...state,
      regionNetworkUsage: payload.result,
      isRegionNetworkUsageLoading: false
    })
  )
  .case(
    actions.getRegionNetworkUsage.failed,
    (state): Reducer => ({
      ...state,
      isRegionNetworkUsageLoading: false
    })
  )

  .case(
    actions.clearPublicIPsList,
    (state): Reducer => ({
      ...state,
      publicIPs: initialState.publicIPs
    })
  )

  .case(
    actions.clearNetworkUsage,
    (state): Reducer => ({
      ...state,
      networkUsage: initialState.networkUsage
    })
  )

  .case(
    actions.clearRegionNetworkUsage,
    (state): Reducer => ({
      ...state,
      regionNetworkUsage: initialState.regionNetworkUsage
    })
  )

  .case(actions.clear, (): Reducer => initialState);
