import axios, { AxiosResponse, CancelTokenSource } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import {
  all,
  call,
  put,
  takeEvery,
  takeLatest,
  takeLeading
} from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  GetHypervisorsParams,
  GetHypervisorsResponse,
  GetRegionResourceUtilizationParams,
  GetRegionResourceUtilizationResponse,
  GetResourceUtilizationParams
} from "./types";

export const cancelMonitoringTokens: {
  [key: string]: CancelTokenSource | undefined | null;
} = {};

export function* getResourceUtilizationDataSaga(
  action: Action<GetResourceUtilizationParams>
): SagaIterator<void> {
  try {
    // Create a new token
    if (!cancelMonitoringTokens["resourceUtilization"]) {
      cancelMonitoringTokens["resourceUtilization"] =
        axios.CancelToken.source();
    }

    const { region } = action.payload;
    const response: AxiosResponse<GetRegionResourceUtilizationResponse> =
      yield call(
        axiosInstance.get,
        `gotham-governor/method/admin/monitoring?regions=all`,
        { cancelToken: cancelMonitoringTokens["resourceUtilization"].token }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getResourceUtilizationData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getResourceUtilizationData.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get resource utilization data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* getRegionResourceUtilizationDataSaga(
  action: Action<GetRegionResourceUtilizationParams>
): SagaIterator<void> {
  try {
    // Create a new token
    if (!cancelMonitoringTokens["regionResourceUtilization"]) {
      cancelMonitoringTokens["regionResourceUtilization"] =
        axios.CancelToken.source();
    }

    const { region } = action.payload;
    const response: AxiosResponse<GetRegionResourceUtilizationResponse> =
      yield call(
        axiosInstance.get,
        `gotham-governor/method/admin/monitoring?regions=${region}`,
        {
          cancelToken: cancelMonitoringTokens["regionResourceUtilization"].token
        }
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getRegionResourceUtilizationData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getRegionResourceUtilizationData.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get resource utilization data by region",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* getHypervisorsDataSaga(
  action: Action<GetHypervisorsParams>
): SagaIterator<void> {
  try {
    // Create a new token
    if (!cancelMonitoringTokens["hypervisors"]) {
      cancelMonitoringTokens["hypervisors"] = axios.CancelToken.source();
    }

    const { region } = action.payload;
    const response: AxiosResponse<GetHypervisorsResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/hypervisors?regions=${region}`,
      { cancelToken: cancelMonitoringTokens["hypervisors"].token }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getHypervisorsData.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getHypervisorsData.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get hypervisors by region",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeEvery(
      actions.getResourceUtilizationData.started,
      getResourceUtilizationDataSaga
    ),
    takeEvery(
      actions.getRegionResourceUtilizationData.started,
      getRegionResourceUtilizationDataSaga
    ),
    takeEvery(actions.getHypervisorsData.started, getHypervisorsDataSaga)
  ]);
}
