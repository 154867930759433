import MuiBreadcrumbs from "@mui/material/Breadcrumbs";
import { FC, ReactNode } from "react";
import { createPortal } from "react-dom";
import { Link as RouterLink } from "react-router-dom";
import { ELEMENT_IDS } from "../../../constants";
import * as s from "./styles";
import { BreadcrumbsProps } from "./types";

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  breadcrumbs,
  ...rest
}: BreadcrumbsProps) => {
  const container = document.getElementById(ELEMENT_IDS.BREADCRUMBS_CONTAINER);

  return container
    ? (createPortal(
        <MuiBreadcrumbs {...rest}>
          {breadcrumbs.map((breadcrumb, i) => (
            <s.Link
              // title={breadcrumb.text}
              key={breadcrumb.url}
              color={i === breadcrumbs.length - 1 ? "textPrimary" : "inherit"}
              to={breadcrumb.url}
              component={RouterLink}
            >
              {breadcrumb.text}
            </s.Link>
          ))}
        </MuiBreadcrumbs>,
        container
      ) as ReactNode)
    : null;
};
