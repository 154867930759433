import { formatDate } from "utils/formatDate";
import { DATE_FORMATS, ROLES_IN_ORG } from "../../constants";
import { RootReducer } from "../../reducers";
import { Organization, TableUser, UserInfo } from "./types";

export const tableUsersSelector = (state: RootReducer): TableUser[] | null =>
  state.users.users
    ? state.users.users.users.map((user) => ({
        "user-type": user.user_type ? user.user_type : "",
        name: user.full_name,
        email: user.email,
        id: user.id,
        "trial-days": user.trial_days ? user.trial_days : 0,
        "org-number-limit": user.org_number_limit ? user.org_number_limit : "",
        "otp-required": user.is_otp_required,
        "is-enabled": user.is_enabled,
        "admin-roles": user.admin_roles?.join("; ") || [""],
        "registration-date": formatDate(
          new Date(user.registration_date),
          DATE_FORMATS.DATETIME
        ),
        "expiration-date": user.trial_expiration_date
          ? formatDate(new Date(user.trial_expiration_date), DATE_FORMATS.DATE)
          : "N/A",
        "last-seen": user.last_seen
          ? formatDate(new Date(user.last_seen), DATE_FORMATS.DATETIME)
          : ""
      }))
    : null;

export const usersTotalSelector = (state: RootReducer): number | null =>
  state.users.users ? state.users.users.count : null;

export const areUsersLoadingSelector = (state: RootReducer): boolean =>
  state.users.areUsersLoading;

export const isUserCreatingSelector = (state: RootReducer): boolean =>
  state.users.isUserCreating;

export const areUserOrganizationsLoadingSelector = (
  state: RootReducer
): boolean => state.users.areUserOrganizationsLoading;

export const userOrganizationsSelector = (
  state: RootReducer
): Organization[] | null =>
  state.users.userOrganizations?.map((organization) => ({
    ...organization,
    role: ROLES_IN_ORG[organization.role]
  })) || null;

export const userInfoSelector = (state: RootReducer): UserInfo | null =>
  state.users.userInfo;

export const isUserInfoUpdatingSelector = (state: RootReducer): boolean =>
  state.users.isUserInfoUpdating;

export const isUserModifyingEnablingSelector = (state: RootReducer): boolean =>
  state.users.isUserModifyingEnabling;
