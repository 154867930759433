import { FC, memo } from "react";
import { LogoProps } from "./types";

const LogoComponent: FC<LogoProps> = ({ width, height }: LogoProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={"0 0 140 52"}
      fill={"none"}
      xmlns={"http://www.w3.org/2000/svg"}
      style={{
        display: "block",
        margin: "auto"
      }}
    >
      <title id={"logo-title"}>EWW | ITandTEL Cloud</title>
      <desc id={"logo-desc"}>EWW | ITandTEL Cloud</desc>
      <linearGradient
        id="a"
        x1=".02"
        x2="119.885"
        y1="15.19"
        y2="15.19"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor="#00aeef" />
        <stop offset="100%" stopColor="#0079c1" />
      </linearGradient>
      <path
        d={
          "m116.9 1.7-6.2 13.5c-1.5 3.1-2.3 4.8-4.1 4.9-1.7-.1-2-1.5-2.5-4.9l-1-8.9c-.3-2.2-.7-5.6-3.8-5.6-3 0-4.5 3.2-5.5 5.5l-4.2 9c-1.5 3.1-2.3 4.8-4.1 4.9-1.7-.1-2-1.5-2.5-4.9l-1-8.9c-.3-2.2-.7-5.6-3.8-5.6-3 0-4.5 3.2-5.5 5.5l-4.2 9c-1.5 3.1-2.3 4.8-4.1 4.9-1.7-.1-2-1.5-2.5-4.9l-1-8.9c-.3-2.2-.7-5.6-3.8-5.6-3 0-4.5 3.2-5.6 5.5l-4.2 9c-1.5 3.1-2.3 4.8-4.1 4.9-1.7-.1-2-1.5-2.5-4.9l-1-8.9C39.4 4.1 39 .7 35.9.7c-3.4 0-4.9 4.1-6 6.6-3.3 7.3-6.7 16.5-16 17.3-3.6.2-7.5-1.1-9-4.6-1.6-3.7-.3-7.9 2.3-10.8 2.1-2.3 5.5-4.3 8.8-4 2.6.3 3.7 2.8 3.2 5.1-.6 3.1-3.5 5.6-6.7 4.8-.6-.1-1.3-.6-2-.5-.8.1-1.3 1.1-1.4 1.9-.1 1.5 1 2.5 2.4 2.8 7.3 1.3 15.4-9.4 12-16.1C22.3.8 20-.1 17.5-.1h-.1c-2.8 0-5.9 1.1-7.9 2.4C4.3 5.6 0 11.8 0 18.2v.1c0 1.2.1 2.3.5 3.5 1.9 7.4 10 10 16.6 7.8 7.1-2.4 10.1-9.4 13.1-15.5.7-1.4 1.6-3.7 3.5-3.7 1.7 0 2.1 1.5 2.5 4.9l1 8.9c.3 2.2.7 5.6 3.8 5.6 3 0 4.5-3.2 5.6-5.5l4.2-9c1.5-3.1 2.3-4.8 4.1-4.9 1.7 0 2.1 1.5 2.5 4.9l1 8.9c.3 2.2.7 5.6 3.8 5.6 3 0 4.5-3.2 5.6-5.5l4.2-9c1.5-3.1 2.3-4.8 4.1-4.9 1.8 0 2.1 1.5 2.5 4.9l1 8.9c.3 2.2.7 5.6 3.8 5.6 3 0 4.5-3.2 5.5-5.5l4.2-9c1.5-3.1 2.3-4.8 4.1-4.9 1.7 0 2.1 1.5 2.5 4.9l1 8.9c.3 2.2.7 5.6 3.8 5.6 3 0 4.5-3.2 5.5-5.5l9.7-21.4c.9-2-1.9-3-2.8-1.2z"
        }
        fill="url(#a)"
      />
      <path
        d={
          "M73.8 49.1 76.1 38H78l-2.3 11.1h-1.9zM84.6 39.8l-2 9.3h-1.9l2-9.3h-3.5l.4-1.8h8.7l-.4 1.8h-3.3zM94.5 41.5l-1.2 5.8c-.1.5-.2.9-.2 1.4v.4h-1.7V48.2c-.4.6-1.1 1.1-2.1 1.1-1.9 0-3-1.5-3-3.4 0-2.5 1.7-4.6 3.9-4.6 1.4 0 2 .6 2.3 1.1l.2-.9h1.8zm-6.3 4.3c0 1.1.6 1.9 1.6 1.9 1.2 0 2.2-1.2 2.2-3 0-1-.5-1.8-1.6-1.8-1.5 0-2.2 1.6-2.2 2.9zM100.1 49.1l1-4.5c0-.2.1-.3.1-.5 0-.8-.5-1.2-1.3-1.2-1 0-1.6.9-1.8 1.8l-1 4.4h-1.8l1.3-6.3c.2-.7.2-1.2.2-1.3h1.8c0 .1 0 .5-.1.8.5-.7 1.4-1 2.3-1 1.4 0 2.3 1 2.3 2.4 0 .2 0 .6-.1.8l-1 4.6h-1.9zM111.1 47.3c-.1.6-.2 1.1-.2 1.4v.4h-1.7v-.9c-.4.6-1.1 1.1-2.1 1.1-1.9 0-3-1.5-3-3.4 0-2.5 1.7-4.6 3.9-4.6 1.3 0 2 .6 2.3 1.1l1-4.6h1.8l-2 9.5zm-5.1-1.5c0 1.1.6 1.9 1.6 1.9 1.2 0 2.2-1.2 2.2-3 0-1-.5-1.8-1.6-1.8-1.4 0-2.2 1.6-2.2 2.9zM119.4 39.8l-2 9.3h-1.9l2-9.3H114l.4-1.8h8.7l-.4 1.8h-3.3zM122.3 49.1l2.3-11.1h6.8l-.4 1.8h-4.9l-.6 2.9h4.5l-.4 1.7h-4.4l-.6 2.9h4.9l-.4 1.8h-6.8zM131.3 49.1l2.3-11.1h1.9l-2 9.3h4.9l-.4 1.8h-6.7z"
        }
        fill={"#807f83"}
      />
    </svg>
  );
};

export const Logo = memo<LogoProps>(LogoComponent);
