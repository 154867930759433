import axios, { AxiosResponse, CancelTokenSource } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  GetGpuInstancesParams,
  GetGpuInstancesResponse,
  GetInstancesCachedParams,
  GetInstancesCachedResponse,
  GetInstancesParams,
  GetInstancesResponse,
  UpdateCacheInstancesResponse
} from "./types";

export const cancelVmsTokens: {
  [key: string]: CancelTokenSource | undefined | null;
} = {};

export function* getInstancesSaga(
  action: Action<GetInstancesParams>
): SagaIterator<void> {
  try {
    if (!cancelVmsTokens["listVms"]) {
      cancelVmsTokens["listVms"] = axios.CancelToken.source();
    }

    // const { offset, limit, show, search, sort, order } = action.payload;
    const { search, regions, status, filter } = action.payload;
    const response: AxiosResponse<GetInstancesResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/instances`,
      {
        params: {
          regions: regions ? regions : "",
          filter,
          // filter: "only-gpu" in action.payload ? "gpu" : "all",
          status,
          search
          // offset,
          // limit,
          // sort,
          // order
        },
        cancelToken: cancelVmsTokens["listVms"].token
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInstances.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getInstances.failed({ params: action.payload, error: e })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get VMs data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* getGpuInstancesSaga(
  action: Action<GetGpuInstancesParams>
): SagaIterator<void> {
  try {
    if (!cancelVmsTokens["listGpuVms"]) {
      cancelVmsTokens["listGpuVms"] = axios.CancelToken.source();
    }
    const { regions, status } = action.payload;
    const response: AxiosResponse<GetGpuInstancesResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/instances`,
      {
        params: {
          regions: regions ? regions : "",
          filter: "gpu",
          status
        },
        cancelToken: cancelVmsTokens["listGpuVms"].token
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getGpuInstances.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getGpuInstances.failed({ params: action.payload, error: e })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get gpu virtual machines data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* getInstancesCachedSaga(
  action: Action<GetInstancesCachedParams>
): SagaIterator<void> {
  try {
    if (!cancelVmsTokens["listCachedVms"]) {
      cancelVmsTokens["listCachedVms"] = axios.CancelToken.source();
    }
    const { regions, filter, status, search } = action.payload;
    const response: AxiosResponse<GetInstancesCachedResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/instances/overview`,
      {
        params: {
          regions: regions ? regions : "",
          filter,
          status,
          search
        },
        cancelToken: cancelVmsTokens["listCachedVms"].token
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInstancesCached.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getInstancesCached.failed({ params: action.payload, error: e })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get cached virtual machines data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* updateCacheInstancesSaga(
  action: Action<void>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<UpdateCacheInstancesResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/instances/force-cache-update`
    );

    yield put(
      actions.updateCacheInstances.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title:
          "VMs report for all regions requested. Expect it within a few minutes.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateCacheInstances.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update cached VMs data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeEvery(actions.getInstances.started, getInstancesSaga),
    takeEvery(actions.getGpuInstances.started, getGpuInstancesSaga),
    takeEvery(actions.getInstancesCached.started, getInstancesCachedSaga),
    takeEvery(actions.updateCacheInstances.started, updateCacheInstancesSaga)
  ]);
}
