import { FC } from "react";
import { Helmet } from "react-helmet";
import { appConfig } from "../../../appConfig";
import { HeadProps } from "./types";

export const Head: FC<HeadProps> = ({ title }: HeadProps) => {
  const titleText = `${title ? `${title} - ` : ""}${
    appConfig.theme.title || "EWW | ITandTEL Cloud"
  } Administration Console`;

  return (
    <Helmet>
      <title>{titleText}</title>
      <link
        rel={"shortcut icon"}
        href={appConfig.theme.faviconURL || "/favicon.svg"}
      />
    </Helmet>
  );
};
