import { formatRegion } from "utils/formatRegion";
import { RootReducer } from "../../reducers";
import { Project, ProjectQuotas } from "./types";

export const projectSelector = (state: RootReducer): Project | null =>
  state.projects.project;

export const isProjectLoadingSelector = (state: RootReducer): boolean =>
  state.projects.isProjectLoading;

export const isProjectCreatingSelector = (state: RootReducer): boolean =>
  state.projects.isProjectCreating;

export const isProjectUpdatingSelector = (state: RootReducer): boolean =>
  state.projects.isProjectUpdating;

export const isProjectDeletingSelector = (state: RootReducer): boolean =>
  state.projects.isProjectDeleting;

export const projectsSelector = (state: RootReducer): Project[] | null =>
  state.projects.projects?.map((project) => ({
    ...project,
    region: formatRegion(project.region)
  })) || null;

export const areProjectsLoadingSelector = (state: RootReducer): boolean =>
  state.projects.areProjectsLoading;

export const projectQuotasSelector = (
  state: RootReducer
): ProjectQuotas | null => state.projects.projectQuotas;

export const areProjectQuotasSettingSelector = (state: RootReducer): boolean =>
  state.projects.areProjectQuotasSetting;

export const areProjectQuotasLoadingSelector = (state: RootReducer): boolean =>
  state.projects.areProjectQuotasSetting;
