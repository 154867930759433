import { Paper } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Loader } from "components/common/Loader";
import styled from "styled-components";

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const DetailsTitleMain = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
  /* color: ${({ theme }) => theme.palette.text.secondary}; */
  font-weight: 500;
  font-size: smaller;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsInfoMain = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
  font-size: smaller;
`;

export const DetailsInfoMainColored = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main};
  /* color: ${({ theme }) => theme.palette.text.secondary}; */
  font-weight: 450;
  margin-right: ${({ theme }) => theme.spacing(2)};
  font-size: smaller;
`;

export const MetricsContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
  min-height: 350px;
`;

export const MetricRowContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
`;

export const MetricTitle = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(0.5)};
  /* color: ${({ theme }) => theme.palette.primary.dark}; */
  font-weight: 500;
`;

export const MetricTitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const styledLoader = styled(Loader)`
  font-size: smaller;
`;
