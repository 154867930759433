import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  AddAdminToOrganizationParams,
  AddUserToGroupParams,
  ChangeOrganizationOwnerParams,
  CreateGroupParams,
  CreateGroupResponse,
  CreateOrganizationParams,
  CreateOrganizationResponse,
  DeleteGroupParams,
  DeleteOrganizationParams,
  GetGroupParams,
  GetGroupResponse,
  GetGroupUsersParams,
  GetGroupUsersResponse,
  GetOrganizationGroupsParams,
  GetOrganizationGroupsResponse,
  GetOrganizationParams,
  GetOrganizationProjectsParams,
  GetOrganizationProjectsResponse,
  GetOrganizationResponse,
  GetOrganizationUsersParams,
  GetOrganizationUsersResponse,
  GetOrganizationsParams,
  GetOrganizationsResponse,
  RemoveAdminFromOrganizationParams,
  RemoveUserFromGroupParams,
  UpdateGroupParams,
  UpdateGroupResponse,
  UpdateOrganizationParams,
  UpdateOrganizationResponse
} from "./types";

const serviceApiPath = `gotham-enterprises/method/`;

export function* getOrganizationSaga(
  action: Action<GetOrganizationParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetOrganizationResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}admin/orgs/${action.payload.id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganization.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganization.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getOrganizationsSaga(
  action: Action<GetOrganizationsParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetOrganizationsResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}admin/orgs`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganizations.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganizations.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organizations data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createOrganizationSaga(
  action: Action<CreateOrganizationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the organization...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { data } = action.payload;
    const response: AxiosResponse<CreateOrganizationResponse> = yield call(
      axiosInstance.post,
      `${serviceApiPath}admin/orgs`,
      data
    );
    yield put(
      actions.createOrganization.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Organization has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createOrganization.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create organization",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateOrganizationSaga(
  action: Action<UpdateOrganizationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the organization...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const response: AxiosResponse<UpdateOrganizationResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}admin/orgs/${action.payload.id}`,
      action.payload.data
    );
    yield put(
      actions.updateOrganization.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Organization has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateOrganization.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update organization",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteOrganizationSaga(
  action: Action<DeleteOrganizationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the organization...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    yield call(
      axiosInstance.delete,
      `${serviceApiPath}admin/orgs/${action.payload.id}`
    );
    yield put(
      actions.deleteOrganization.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Organization has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteOrganization.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete organization",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getOrganizationUsersSaga(
  action: Action<GetOrganizationUsersParams>
): SagaIterator<void> {
  try {
    const { organizationId } = action.payload;
    const response: AxiosResponse<GetOrganizationUsersResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}admin/orgs/${organizationId}/users`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganizationUsers.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganizationUsers.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization's users",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* addAdminToOrganizationSaga(
  action: Action<AddAdminToOrganizationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Adding administrator to the organization...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    yield call(
      axiosInstance.post,
      `${serviceApiPath}admin/orgs/${action.payload["org-id"]}/add-user/${action.payload["user-email"]}`
    );
    yield put(
      actions.addAdminToOrganization.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Administrator has been successfully added.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.addAdminToOrganization.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to add administrator",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* removeAdminFromOrganizationSaga(
  action: Action<RemoveAdminFromOrganizationParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Removing administrator from the organization...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    yield call(
      axiosInstance.delete,
      `${serviceApiPath}admin/orgs/${action.payload["org-id"]}/users/${action.payload["user-id"]}`
    );
    yield put(
      actions.removeAdminFromOrganization.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Administrator has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.removeAdminFromOrganization.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to remove administrator",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* changeOrganizationOwnerSaga(
  action: Action<ChangeOrganizationOwnerParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Changing the organization ownership...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    yield call(
      axiosInstance.post,
      `${serviceApiPath}admin/orgs/${action.payload["org-id"]}/transfer-ownership/${action.payload["user-email"]}`
    );
    yield put(
      actions.changeOrganizationOwner.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "You have successfully transferred the organization.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.changeOrganizationOwner.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to transfer organization",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getOrganizationProjectsSaga(
  action: Action<GetOrganizationProjectsParams>
): SagaIterator<void> {
  try {
    const { organizationId } = action.payload;
    const response: AxiosResponse<GetOrganizationProjectsResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}admin/orgs/${organizationId}/projects`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganizationProjects.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganizationProjects.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization's projects",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getOrganizationGroupsSaga(
  action: Action<GetOrganizationGroupsParams>
): SagaIterator<void> {
  try {
    const { organizationId, owner } = action.payload;
    const response: AxiosResponse<GetOrganizationGroupsResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}admin/users/${owner}/orgs/${organizationId}/groups`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganizationGroups.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganizationGroups.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization's groups",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getGroupSaga(
  action: Action<GetGroupParams>
): SagaIterator<void> {
  try {
    const { organizationId, groupId } = action.payload;
    const response: AxiosResponse<GetGroupResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}admin/orgs/${organizationId}/groups/${groupId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getGroup.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getGroup.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getGroupUsersSaga(
  action: Action<GetGroupUsersParams>
): SagaIterator<void> {
  try {
    const { organizationId, groupId } = action.payload;
    const response: AxiosResponse<GetGroupUsersResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}admin/orgs/${organizationId}/groups/${groupId}/users`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getGroupUsers.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getGroupUsers.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get group users",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createGroupSaga(
  action: Action<CreateGroupParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the group...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, data } = action.payload;
    const response: AxiosResponse<CreateGroupResponse> = yield call(
      axiosInstance.post,
      `${serviceApiPath}admin/orgs/${organizationId}/groups/${data.name}`,
      data
    );
    yield put(
      actions.createGroup.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Group has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.createGroup.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}
export function* updateGroupSaga(
  action: Action<UpdateGroupParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the group...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, id, data } = action.payload;
    const response: AxiosResponse<UpdateGroupResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}admin/orgs/${organizationId}/groups/${id}`,
      data
    );
    yield put(
      actions.updateGroup.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Group has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.updateGroup.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteGroupSaga(
  action: Action<DeleteGroupParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the group...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { organizationId, id } = action.payload;
    yield call(
      axiosInstance.delete,
      `${serviceApiPath}admin/orgs/${organizationId}/groups/${id}`
    );
    yield put(
      actions.deleteGroup.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Group has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.deleteGroup.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* addUserToGroupSaga(
  action: Action<AddUserToGroupParams>
): SagaIterator<void> {
  try {
    const { organizationId, userEmail, groupId } = action.payload;
    yield put(
      notificationsActions.showNotification({
        title: "Adding user to the group...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    yield call(
      axiosInstance.post,
      `${serviceApiPath}admin/orgs/${organizationId}/groups/${groupId}/add-user/${userEmail}`
    );
    yield put(
      actions.addUserToGroup.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "User has been successfully added to the group.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.addUserToGroup.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to add user to the group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* removeUserFromGroupSaga(
  action: Action<RemoveUserFromGroupParams>
): SagaIterator<void> {
  try {
    const { organizationId, userId, groupId } = action.payload;
    yield put(
      notificationsActions.showNotification({
        title: "Removing user from the group...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    yield call(
      axiosInstance.delete,
      `${serviceApiPath}admin/orgs/${organizationId}/groups/${groupId}/users/${userId}`
    );
    yield put(
      actions.removeUserFromGroup.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "User has been successfully removed from the group.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.removeUserFromGroup.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to remove user from the group",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getOrganization.started, getOrganizationSaga),
    takeLeading(actions.getOrganizations.started, getOrganizationsSaga),
    takeLeading(actions.getOrganizationUsers.started, getOrganizationUsersSaga),
    takeLeading(
      actions.getOrganizationProjects.started,
      getOrganizationProjectsSaga
    ),
    takeEvery(actions.createOrganization.started, createOrganizationSaga),
    takeEvery(actions.updateOrganization.started, updateOrganizationSaga),
    takeEvery(actions.deleteOrganization.started, deleteOrganizationSaga),
    takeEvery(
      actions.addAdminToOrganization.started,
      addAdminToOrganizationSaga
    ),
    takeEvery(
      actions.removeAdminFromOrganization.started,
      removeAdminFromOrganizationSaga
    ),
    takeEvery(
      actions.changeOrganizationOwner.started,
      changeOrganizationOwnerSaga
    ),
    takeLeading(actions.getGroup.started, getGroupSaga),
    takeLeading(actions.getGroupUsers.started, getGroupUsersSaga),
    takeLeading(
      actions.getOrganizationGroups.started,
      getOrganizationGroupsSaga
    ),
    takeEvery(actions.createGroup.started, createGroupSaga),
    takeEvery(actions.updateGroup.started, updateGroupSaga),
    takeEvery(actions.deleteGroup.started, deleteGroupSaga),
    takeEvery(actions.addUserToGroup.started, addUserToGroupSaga),
    takeEvery(actions.removeUserFromGroup.started, removeUserFromGroupSaga)
  ]);
}
