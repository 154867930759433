export enum DATE_TYPES {
  START,
  END
}

export enum PROJECT_STATUSES {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED"
}

export enum TABS {
  SUMMARY,
  INSTANCES,
  VOLUMES,
  DATABASES,
  OBJECT_STORAGE
}

export type CollapsibleData = Record<string, any>;
