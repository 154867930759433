import { Paper } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Loader } from "components/common/Loader";
import styled from "styled-components";

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const MetricsContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
  min-height: 350px;
`;

export const MetricRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;
  /* margin-bottom: ${({ theme }) => theme.spacing(10)}; */
`;

export const MetricContainer = styled.div`
  /* width: calc(25% - ${({ theme }) => theme.spacing(2)}); */
  min-width: 475px;
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(1)};
`;

export const styledLoader = styled(Loader)`
  font-size: smaller;
`;
