export enum ROLES {
  ADMIN = "admin",
  MEMBER = "member",
  OWNER = "owner",
  NON_MEMBER = "non-member"
}

export enum INVITATION_TYPES {
  GROUP_MEMBER = "group-member",
  ORGANIZATION_OWNER = "organization-owner",
  ORGANIZATION_ADMINISTRATOR = "organization-administrator"
}

export type Organization = {
  id: string;
  name: string;
  role: ROLES;
  owner: string;
  project_number_limit: number;
};

export type TableOrganization = {
  id: string;
  name: string;
  owner: string;
  project_number_limit: number;
};

export type GetOrganizationParams = {
  id: string;
  pollingId?: string;
};

export type GetOrganizationResponse = Organization;

export type GetOrganizationsParams = {
  pollingId?: string;
};

export type GetOrganizationsResponse = Organization[];

export type CreateOrganizationParams = {
  data: {
    org_name: string;
    owner_email: string;
  };
};

export type CreateOrganizationResponse = Organization;

export type UpdateOrganizationParams = {
  id: string;
  data: {
    name?: string;
    project_number_limit?: number;
  };
};

export type UpdateOrganizationResponse = Organization;

export type DeleteOrganizationParams = {
  id: string;
};

export type ChangeOrganizationOwnerParams = {
  "org-id": string;
  "user-email": string;
};

export type AddAdminToOrganizationParams = {
  "org-id": string;
  "user-email": string;
};

export type RemoveAdminFromOrganizationParams = {
  "org-id": string;
  "user-id": string;
};

export type OrganizationUser = {
  id: string;
  // username: string;
  email: string;
  role: ROLES;
  full_name: string;
};

export type GetOrganizationUsersParams = {
  organizationId: string;
  pollingId?: string;
};
export type GetOrganizationUsersResponse = OrganizationUser[];

export type OrganizationProject = {
  id: string;
  name: string;
  region: string;
};

export type GetOrganizationProjectsParams = {
  organizationId: string;
  pollingId?: string;
};

export type GetOrganizationProjectsResponse = OrganizationProject[];

export type Group = {
  id: string;
  name: string;
  projects: OrganizationProject[];
};

export type GetGroupParams = {
  organizationId: string;
  groupId: string;
  pollingId?: string;
};

export type GetGroupResponse = Group;

export type GetOrganizationGroupsParams = {
  organizationId: string;
  owner: string;
  pollingId?: string;
};

export type GetOrganizationGroupsResponse = Group[];

export type CreateGroupParams = {
  organizationId: string;
  data: {
    name: string;
  };
};

export type CreateGroupResponse = Group;

export type UpdateGroupParams = {
  organizationId: string;
  id: string;
  data: {
    name?: string;
  };
};

export type UpdateGroupResponse = Group;

export type DeleteGroupParams = {
  organizationId: string;
  id: string;
};

export type GetGroupUsersParams = {
  organizationId: string;
  groupId: string;
  pollingId?: string;
};

export type AddUserToGroupParams = {
  organizationId: string;
  groupId: string;
  userEmail: string;
};

export type RemoveUserFromGroupParams = {
  organizationId: string;
  groupId: string;
  userId: string;
};

export type GetGroupUsersResponse = OrganizationUser[];
