import React from "react";
import * as s from "./styles";
import { Button } from "@mui/material";

const PopupBanner: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <s.PopUpContainer>
      <s.PopUpContent>
        <strong>{"Reminder"}:</strong>{" "}
        {
          "You are currently in the Admin Portal. Please be aware that your actions can affect other users. Always proceed with caution."
        }
      </s.PopUpContent>
      <Button onClick={onClose}>{"Close"}</Button>
    </s.PopUpContainer>
  );
};

export default PopupBanner;
