import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { USER_TAB_TITLES } from "components/User";
import { TABS as USER_TABS } from "components/User/types";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn
} from "components/common/Table/types";
import { addDays, startOfMonth, sub, subDays } from "date-fns";
import { useMount } from "hooks/useMount";
import { useUnmount } from "hooks/useUnmount";
import * as billingActions from "modules/billing/actions";
import {
  isOrganizationBillingDataLoadingSelector,
  organizationBillingDataSelector,
  tableProjectsBillingDataSelector,
  tableProjectsTotalBillingDataSelector
} from "modules/billing/selectors";
import { TableOrganizationProjectBillingData } from "modules/billing/types";
import * as usersActions from "modules/users/actions";
import { userInfoSelector } from "modules/users/selectors";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import { generateSearchString } from "utils/generateSearchString";
import { getFullName } from "utils/getFullName";
import { getProjectRegion } from "utils/getProjectRegion";
import { DATE_FORMATS, DEFAULT_CURRENCY, ROUTES } from "../../constants";
import * as s from "./styles";
import { DATE_TYPES } from "./types";

const CURRENCY_SELECT_ID = "currency";

const tableColumns: TableColumn<TableOrganizationProjectBillingData>[] = [
  { key: "name", label: "Project Name" },
  { key: "region", label: "Region" },
  { key: "status", label: "Status" }
];

export const BillingOrganizations: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    userId: string;
    organizationId: string;
  }>();
  const history = useNavigate();
  const userInfo = useSelector(userInfoSelector);
  const userName = getFullName(userInfo?.firstName, userInfo?.lastName);
  const billingData = useSelector(organizationBillingDataSelector);
  const tableBillingData = useSelector(tableProjectsBillingDataSelector);
  const tableBillingDataTotal = useSelector(
    tableProjectsTotalBillingDataSelector
  );
  const isOrganizationBillingDataLoading = useSelector(
    isOrganizationBillingDataLoadingSelector
  );
  const currentDate = new Date();
  const queryParams = new URLSearchParams(location.search);
  const [startDate, setStartDate] = useState<string | null>(
    queryParams.get("start-date") ||
      formatDate(startOfMonth(currentDate), DATE_FORMATS.ISO_DATE)
  );
  const [endDate, setEndDate] = useState<string | null>(
    queryParams.get("end-date") ||
      formatDate(currentDate, DATE_FORMATS.ISO_DATE)
  );

  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);

  const currencies = useMemo(
    () => (billingData ? Object.keys(billingData.costs) : []),
    [billingData]
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Users", url: ROUTES.USERS },
    {
      text: userName,
      url: generatePath(ROUTES.USER, {
        userId: matchParams.userId
      })
    },
    {
      text: "Billing",
      url: `${generatePath(ROUTES.USER, {
        userId: matchParams.userId
      })}?${generateSearchString({
        tab: encodeURIComponent(USER_TAB_TITLES[USER_TABS.BILLING]),
        "start-date": encodeURIComponent(startDate || ""),
        "end-date": encodeURIComponent(endDate || "")
      })}`
    },
    {
      text: billingData?.name || "",
      url: generatePath(ROUTES.BILLING_ORGANIZATION, {
        userId: matchParams.userId,
        organizationId: matchParams.organizationId
      })
    }
  ];

  useMount(() => {
    dispatch(
      usersActions.getUserInfo.started({
        id: matchParams.userId!
      })
    );
    if (startDate && endDate) {
      dispatch(
        billingActions.getOrganizationBillingData.started({
          startDate,
          endDate,
          id: matchParams.organizationId!
        })
      );
    }
  });

  useUnmount(() => {
    dispatch(billingActions.clear());
  });

  useEffect(() => {
    if (startDate && endDate) {
      history({
        search: generateSearchString({
          "start-date": startDate,
          "end-date": endDate
        })
      });
      dispatch(
        billingActions.getOrganizationBillingData.started({
          startDate,
          endDate,
          id: matchParams.organizationId!
        })
      );
    }
  }, [
    dispatch,
    startDate,
    endDate,
    matchParams.userId,
    matchParams.organizationId,
    history
  ]);

  const handleDateChange = useCallback(
    (dateType: DATE_TYPES) => (date: Date | null) => {
      const dateValue = date ? formatDate(date, DATE_FORMATS.ISO_DATE) : date;
      switch (dateType) {
        case DATE_TYPES.START:
          setStartDate(dateValue);
          break;
        case DATE_TYPES.END:
          setEndDate(dateValue);
          break;
      }
    },
    []
  );

  const generateTableItemURL = useCallback(
    (id: string) => {
      if (tableBillingData) {
        return `${generatePath(ROUTES.BILLING_PROJECT, {
          userId: matchParams.userId,
          organizationId: matchParams.organizationId,
          regionId: getProjectRegion(tableBillingData, id),
          projectId: id
        })}${
          startDate && endDate
            ? `?${generateSearchString({
                "start-date": startDate,
                "end-date": endDate
              })}`
            : ""
        }`;
      }
    },
    [
      matchParams.userId,
      matchParams.organizationId,
      tableBillingData,
      startDate,
      endDate
    ]
  );

  const handleCurrencyChange = useCallback((event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  }, []);

  const title = billingData?.name;

  const minDate = sub(currentDate, { years: 1 });

  return (
    <>
      <Head title={title} />
      <>
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <s.Title variant={"h4"} component={"h2"}>
          Organization: {title}
        </s.Title>
        <s.SummaryRow>
          <s.DetailsTitle>ID:</s.DetailsTitle>
          <s.DetailsInfo>{billingData?.id}</s.DetailsInfo>
        </s.SummaryRow>
        <s.Description>
          Billing data is available for previous 12 months
        </s.Description>
        <Table
          isSearchEnabled={true}
          isSortingEnabled={true}
          rows={tableBillingData || []}
          columns={[
            ...tableColumns,
            {
              key: `cost-${currency}`,
              label: "Cost",
              sortingType: TABLE_SORTING_TYPES.NUMBER
            }
          ]}
          itemLink={{
            column: "name",
            getURL: generateTableItemURL
          }}
          totalRow={tableBillingDataTotal || undefined}
          isLoading={isOrganizationBillingDataLoading}
          toolbarItems={
            <>
              <MobileDatePicker
                label={"Start date"}
                onChange={handleDateChange(DATE_TYPES.START)}
                value={startDate ? new Date(startDate) : null}
                format={DATE_FORMATS.DATE}
                minDate={minDate}
                // maxDate={endDate ? subDays(new Date(endDate), 1) : undefined}
                maxDate={endDate ? new Date(endDate) : undefined}
                disableFuture={true}
                sx={{ marginRight: "10px" }}
                slotProps={{
                  textField: {
                    InputProps: { size: "small" }
                  }
                }}
              />
              <MobileDatePicker
                label={"End date"}
                onChange={handleDateChange(DATE_TYPES.END)}
                value={endDate ? new Date(endDate) : null}
                format={DATE_FORMATS.DATE}
                // minDate={startDate ? addDays(new Date(startDate), 1) : minDate}
                minDate={startDate ? new Date(startDate) : minDate}
                disableFuture={true}
                sx={{ marginRight: "10px" }}
                slotProps={{
                  textField: {
                    InputProps: { size: "small" }
                  }
                }}
              />
              {currencies.length > 0 && (
                <s.CurrencyFormControl>
                  <InputLabel id={CURRENCY_SELECT_ID}>Currency</InputLabel>
                  <Select
                    label={"Currency"}
                    labelId={CURRENCY_SELECT_ID}
                    value={currency}
                    onChange={handleCurrencyChange}
                    size={"small"}
                  >
                    {currencies.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </s.CurrencyFormControl>
              )}
            </>
          }
        />
      </>
    </>
  );
};
