import { FormControl, Paper } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";

export const ContactSupportLinkText = styled(Typography)`
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.4em;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
`;

export const Description = styled(Typography)`
  margin-left: ${({ theme }) => theme.spacing(6.6)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: smaller;
`;

export const CurrencyFormControl = styled(FormControl)`
  width: 120px;
`;

export const RegionFormControl = styled(FormControl)`
  display: inline-block;
  width: auto;
  min-width: 350px;
  margin-left: auto;
  /* margin-bottom: 10px; */
`;

export const MetricsContainer = styled(Paper)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
  margin-top: ${({ theme }) => theme.spacing(3)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  min-height: 200px;
`;

export const Toolbar = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin: ${({ theme }) => theme.spacing(4, 1, 1, 5)};
`;
