export const generateSearchString = (
  params: Record<string, string>
): string => {
  const formattedParams = Object.keys(params).reduce(
    (obj, key) => ({
      ...obj,
      [key]: params[key].toLowerCase()
    }),
    {}
  );
  return new URLSearchParams(formattedParams).toString();
};
