import { FC, useEffect, useState } from "react";
import * as s from "./styles";

import { LinerProgressWithLabelProps } from "./types";
import { roundTo } from "utils/roundTo";
import { Box, Typography } from "@mui/material";

const ANIMATION_FREQUENCY = 50;
const ANIMATION_PERCENTAGE_STEP = 5;

export const LinearProgressWithLabelBig: FC<LinerProgressWithLabelProps> = ({
  value,
  maxValue,
  unit,
  bottomCaption
}: LinerProgressWithLabelProps) => {
  const [progress, setProgress] = useState(0);
  const percentageValue = (value / maxValue) * 100;

  useEffect(() => {
    const timerId = setInterval(() => {
      setProgress((prevProgress) =>
        Math.min(
          100,
          prevProgress +
            Math.min(ANIMATION_PERCENTAGE_STEP, percentageValue - prevProgress)
        )
      );
    }, ANIMATION_FREQUENCY);

    return () => {
      clearInterval(timerId);
    };
  }, [percentageValue]);

  return (
    <Box sx={{ width: "100%", mt: 0 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <s.StyledProgress variant="determinate" value={progress} />
        </Box>
        <Box sx={{ minWidth: 50, ml: 2 }}>
          <s.Percentage component="h4">{`${Math.round(percentageValue)}%`}</s.Percentage>
        </Box>
      </Box>
      {bottomCaption && (
        <Box mt={0} display="flex" justifyContent="center">
          {" "}
          <Typography
            variant={"caption"}
            component={"div"}
            color={"textSecondary"}
            margin={"0 0 0 0"}
          >
            {`used: ${roundTo(value, 2)} of ${roundTo(maxValue, 2)} (free: ${roundTo(maxValue - value, 2)} ${unit})
            }`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
