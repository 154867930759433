import { createTheme, PaletteMode, Theme } from "@mui/material";

// export const getTheme = (
//   mode: PaletteMode,
//   customConfig?: AppConfig["theme"]
// ): Theme =>
//   createTheme({
//     palette: {
//       mode,
//       primary: {
//         main:
//           customConfig?.primaryColor ||
//           (mode === "dark" ? "#0078c0" : "#00648c")
//       },
//       secondary: {
//         main:
//           customConfig?.secondaryColor ||
//           (mode === "dark" ? "#0092cf" : "#0092cf")
//       },
//       grey: {
//         100: customConfig?.tableHeadersColor || "#eef3f6",
//         900: "#17222d"
//       },
//       ...(customConfig?.backgroundColor
//         ? {
//             background: {
//               default: customConfig?.backgroundColor
//             }
//           }
//         : {})
//     },
//     components: {
//       MuiButton: {
//         defaultProps: {
//           disableElevation: true
//         }
//       },
//       MuiLink: {
//         defaultProps: {
//           underline: "hover",
//           color:
//             customConfig?.secondaryColor ||
//             (mode === "dark" ? "#0092cf" : "#00648c")
//         }
//       },
//       MuiToggleButton: {
//         defaultProps: {
//           sx: {
//             "&.Mui-selected": {
//               // bgcolor: mode === "dark" ? "primary.main" : "primary",
//               color: mode === "dark" ? "primary.contrastText" : "primary"
//             }
//           }
//         }
//       }
//       // MuiTooltip: {
//       //   styleOverrides: {
//       //     tooltip: ({ theme }) => ({
//       //       backgroundColor:
//       //         theme.palette.mode === "dark"
//       //           ? theme.palette.grey[900]
//       //           : theme.palette.grey[100],
//       //       color:
//       //         theme.palette.mode === "dark"
//       //           ? theme.palette.primary.contrastText
//       //           : theme.palette.text.primary
//       //     })
//       //   }
//       // }
//     }
//   });

interface ThemeColors {
  primary: {
    dark: string;
    light: string;
  };
  secondary: {
    dark: string;
    light: string;
  };
  tableHeaders: {
    dark: string;
    light: string;
  };
  links: {
    dark: string;
    light: string;
  };
  // background: string;
  background: {
    dark: string;
    light: string;
  };
}

export const getTheme = (
  mode: PaletteMode,
  customConfig?: AppConfig["theme"]
): Theme => {
  const themes: Record<string, ThemeColors> = {
    Deltacloud: {
      primary: { dark: "#CE094A", light: "#120426" },
      secondary: { dark: "#C40847", light: "#E40951" },
      tableHeaders: { dark: "#2B1218", light: "#ecebee" },
      links: { dark: "#C40847", light: "#E40951" }, // as secondary
      background: { dark: "#121212", light: "#ffffff" }
      // background: { dark: "#1a1a1a", light: "#ffffff" }
    },
    default: {
      primary: { dark: "#0078c0", light: "#00648c" },
      secondary: { dark: "#0092cf", light: "#0092cf" },
      tableHeaders: { dark: "#17222d", light: "#eef3f6" },
      links: { dark: "#0092cf", light: "#00648c" },
      background: { dark: "#121212", light: "#ffffff" }
    }
  };

  // Default to using the provided customConfig title or "default"
  const title = customConfig?.title || "default";

  // Determine if the title exists in the predefined themes or fallback to customConfig or default
  const themeColors = themes[title] || {
    primary: {
      dark: customConfig?.primaryColor || themes.default.primary.dark,
      light: customConfig?.primaryColor || themes.default.primary.light
    },
    secondary: {
      dark: customConfig?.secondaryColor || themes.default.secondary.dark,
      light: customConfig?.secondaryColor || themes.default.secondary.light
    },
    tableHeaders: {
      dark: themes.default.tableHeaders.dark,
      light:
        customConfig?.tableHeadersColor || themes.default.tableHeaders.light
    },
    links: {
      dark: customConfig?.secondaryColor || themes.default.secondary.dark,
      light: customConfig?.secondaryColor || themes.default.secondary.light
    },
    background: {
      dark: customConfig?.backgroundColor || themes.default.background.dark,
      light: customConfig?.backgroundColor || themes.default.background.light
    }
  };

  return createTheme({
    palette: {
      mode,
      primary: {
        main:
          mode === "dark" ? themeColors.primary.dark : themeColors.primary.light
      },
      secondary: {
        main:
          mode === "dark"
            ? themeColors.secondary.dark
            : themeColors.secondary.light
      },
      grey: {
        100: themeColors.tableHeaders.light,
        900: themeColors.tableHeaders.dark
      },
      background: {
        default:
          mode === "dark"
            ? themeColors.background.dark
            : themeColors.background.light
      }
    },
    components: {
      MuiButton: { defaultProps: { disableElevation: true } },
      MuiLink: {
        defaultProps: {
          underline: "hover",
          color:
            mode === "dark" ? themeColors.links.dark : themeColors.links.light
        }
      },
      MuiToggleButton: {
        defaultProps: {
          sx: {
            "&.Mui-selected": {
              color: mode === "dark" ? "primary.contrastText" : "primary"
            }
          }
        }
      }
    }
  });
};
