import { FC } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { AdminWelcomeDialogProps } from "./types";

const AdminWelcomeDialog: FC<AdminWelcomeDialogProps> = ({
  isOpened,
  onClose
}) => {
  return (
    <Dialog open={isOpened} onClose={onClose}>
      <DialogTitle>{"Welcome to the Admin Portal!"}</DialogTitle>
      <DialogContent>
        <div>
          {"You've successfully logged in as an administrator, please note:"}
        </div>
        <ul>
          <li>{"This portal impacts other users."}</li>
          <li>{"Your actions depend on your admin role."}</li>
          <li>{"Always act with caution."}</li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {"Close"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdminWelcomeDialog;
