import { Paper } from "@mui/material";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { Loader } from "components/common/Loader";
import { TableCellText } from "components/common/Table/styles";
import styled from "styled-components";

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const DetailsTitleMain = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
  /* color: ${({ theme }) => theme.palette.text.secondary}; */
  font-weight: 500;
  font-size: smaller;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsInfo = styled.p`
  /* margin-right: ${({ theme }) => theme.spacing(2)}; */
  margin: ${({ theme }) => theme.spacing(0)};
  font-size: smaller;
`;

export const DetailsInfoColored = styled(DetailsInfo)`
  color: ${({ theme }) => theme.palette.primary.main};
  /* color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main}; */
  font-weight: 550;
`;

export const DetailsInfoColoredDark = styled(DetailsInfo)`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-weight: 410;
`;

export const DetailsInfoColoredLight = styled(DetailsInfo)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-weight: 410;
`;

export const DetailsInfoColoredSuccess = styled(DetailsInfo)`
  color: ${({ theme }) => theme.palette.success.main};
  font-weight: 450;
`;

export const DetailsInfoColoredWarning = styled(DetailsInfo)`
  color: ${({ theme }) => theme.palette.warning.main};
  font-weight: 450;
`;

export const DetailsInfoColoredDanger = styled(DetailsInfo)`
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: 450;
`;

export const DetailsInfoGray = styled(DetailsInfo)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.text.secondary};
  font-weight: 450;
`;

export const MetricsContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(2)};
  min-height: 350px;
`;

export const MetricRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Разрешить перенос метрик на новую строку */
  justify-content: space-around;
  align-content: flex-start;
  /* margin-bottom: ${({ theme }) => theme.spacing(10)}; */
`;

export const MetricContainer = styled.div`
  /* width: calc(25% - ${({ theme }) => theme.spacing(2)}); */
  /* min-width: 350px; */
  min-width: 200px;
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(1)};
`;

export const MetricTitle = styled(Typography)`
  /* margin-right: ${({ theme }) => theme.spacing(0.5)}; */
  /* color: ${({ theme }) => theme.palette.primary.dark}; */
  font-weight: 500;
  text-align: left;
`;

export const MetricTitleContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  display: flex;
  /* align-items: center; */
  white-space: nowrap;
`;

export const styledLoader = styled(Loader)`
  font-size: smaller;
`;

export const MetricWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(1, 0, 2, 0)};
`;

export const MetricContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ChartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const MetricInfo = styled.div`
  margin-left: 20px;
`;

export const TabsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

// export const SimpleMetricContainer = styled.div`
//   padding: ${({ theme }) => theme.spacing(7, 5)};
//   margin: ${({ theme }) => theme.spacing(4, 0, 1, 0)};
//   background: ${({ theme }) =>
//     theme.palette.mode === "dark"
//       ? theme.palette.grey[900]
//       : theme.palette.grey[100]};
//   border-radius: 8px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// `;

export const SimpleMetricTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

export const SimpleMetricValue = styled.div`
  font-size: 16px;
  margin-top: ${({ theme }) => theme.spacing(1)};
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.secondary.dark
      : theme.palette.secondary.dark};
  font-weight: 500;
  transition: color 0.3s ease;
`;

export const SimpleMetricsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

interface SimpleMetricItemProps {
  width?: string;
}

export const SimpleMetricItem = styled.div<SimpleMetricItemProps>`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(1)};
  text-align: center;
  width: ${({ width }) => width || "200px"};
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? `0px 1px 3px 0px ${theme.palette.grey["900"]}, 0px 1px 1px 0px ${theme.palette.grey["900"]}, 0px 2px 1px -1px ${theme.palette.secondary.main}`
      : theme.shadows[1]};
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.mode === "dark"
        ? theme.palette.grey[900]
        : theme.palette.action.hover};
    box-shadow: ${({ theme }) =>
      theme.palette.mode === "dark"
        ? `0px 1px 1px -2px ${theme.palette.grey["900"]}, 0px 1px 5px 0px ${theme.palette.grey["900"]}, 0px 3px 1px -1px ${theme.palette.secondary.main}`
        : `${theme.shadows[3]}`};

    ${SimpleMetricValue} {
      color: ${({ theme }) =>
        theme.palette.mode === "dark"
          ? theme.palette.secondary.main
          : theme.palette.secondary.dark};
    }
  }
`;

export const SimpleMetricItem2 = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  padding: ${({ theme }) => theme.spacing(3, 1)};
  text-align: center;
  width: 200px;
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  box-shadow: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? `0px 1px 3px 0px ${theme.palette.grey["900"]}, 0px 1px 1px 0px ${theme.palette.grey["900"]}, 0px 2px 1px -1px ${theme.palette.secondary.main}`
      : theme.shadows[1]};
  transition:
    background-color 0.3s ease,
    color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: ${({ theme }) =>
      theme.palette.mode === "dark"
        ? theme.palette.grey[900]
        : theme.palette.action.hover};
    box-shadow: ${({ theme }) =>
      theme.palette.mode === "dark"
        ? `0px 1px 1px -2px ${theme.palette.grey["900"]}, 0px 1px 5px 0px ${theme.palette.grey["900"]}, 0px 3px 1px -1px ${theme.palette.secondary.main}`
        : `${theme.shadows[3]}`};

    ${SimpleMetricValue} {
      color: ${({ theme }) =>
        theme.palette.mode === "dark"
          ? theme.palette.secondary.main
          : theme.palette.secondary.dark};
    }
  }
`;

export const CustomTableCellText = styled(TableCellText)`
  font-size: 0.85rem;
`;

export const TypographyColoredDanger = styled(TableCellText)`
  color: ${({ theme }) => theme.palette.error.dark};
  font-weight: 430;
  font-size: 0.85rem;
`;

export const TypographyColoredWarning = styled(TableCellText)`
  color: ${({ theme }) => theme.palette.warning.dark};
  text-align: left;
  font-weight: 430;
  font-size: 0.85rem;
`;

export const TypographyColoredSuccess = styled(TableCellText)`
  color: ${({ theme }) => theme.palette.success.dark};
  text-align: left;
  font-weight: 430;
  font-size: 0.85rem;
`;

export const TitleRegion = styled(Typography)<
  TypographyProps<"h4", { component: "h4" }>
>`
  text-align: center;
  margin: 10px 0 10px 0;
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.light
      : theme.palette.primary.main};
  font-size: 1.4rem;
  font-weight: 450;
  font-variant: small-caps;
`;
