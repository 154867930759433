export const formatRegion = (region: string): string =>
  region
    .split("-")
    .map((part) => `${part[0].toUpperCase()}${part.slice(1)}`)
    .join("-");

// parse __GROUP_REGIONS_MAP into Map<string, { group: string; isHidden: boolean }>
export function parseGroupRegionMap(
  input: string
): Map<string, { group: string; isHidden: boolean }> {
  if (!input) {
    input = `[{"upper-austria-dev-1": {"group": "Austria", "isHidden": false}}, {"upper-austria-dev-2": {"group": "Switzerland", "isHidden": false}}]`;
  }
  const jsonGroupRegionArray: string[] = JSON.parse(input);
  const groupRegionMapFormatted = new Map<
    string,
    { group: string; isHidden: boolean }
  >();
  jsonGroupRegionArray.forEach((item: string) => {
    const key = Object.keys(item)[0];
    const value: { group: string; isHidden: boolean } = item[key];
    groupRegionMapFormatted.set(key, value);
  });

  return groupRegionMapFormatted;
}

// format sorted not hidden regions as Select Option
export const getSortedNotHiddenRegionsAsOptions = (appConfig: AppConfig) => {
  const groupRegionMap = parseGroupRegionMap(appConfig.groupRegionMap);

  const sortedNotHiddenRegions = appConfig.availableRegions
    .sort((a, b) => {
      const isAInCurrentGroup =
        groupRegionMap.get(a)?.group === appConfig.regionGroup;
      const isBInCurrentGroup =
        groupRegionMap.get(b)?.group === appConfig.regionGroup;

      if (isAInCurrentGroup && !isBInCurrentGroup) {
        return -1;
      } else if (!isAInCurrentGroup && isBInCurrentGroup) {
        return 1;
      } else {
        return a.localeCompare(b);
      }
    })
    .filter((region) => !appConfig.hiddenRegions.includes(region));

  return sortedNotHiddenRegions.map((region) => ({
    label: formatRegion(region),
    value: region
  }));
};
