import { FormControl, MenuItem } from "@mui/material";
import styled from "styled-components";

interface ShowUsersFormControlProps {
  margin?: string;
}

export const ShowUsersItem = styled(MenuItem)`
  font-size: 0.9em;
`;

// export const ShowUsersFormControl = styled(FormControl)`
//   width: 220px;
//   margin: ${({ theme }) => theme.spacing(0, 2, 0, 1.5)};
// `;

export const ShowUsersFormControl = styled(
  FormControl
)<ShowUsersFormControlProps>`
  width: 220px;
`;

export const StyledDiv = styled.div<{ margin?: string }>`
  margin: ${({ margin, theme }) => margin || theme.spacing(0, 2, 0, 1.5)};
`;
