import actionCreatorFactory from "typescript-fsa";
import {
  ChangeOrganizationOwnerParams,
  AddAdminToOrganizationParams,
  RemoveAdminFromOrganizationParams,
  CreateOrganizationParams,
  CreateOrganizationResponse,
  DeleteOrganizationParams,
  GetOrganizationParams,
  GetOrganizationResponse,
  GetOrganizationsParams,
  GetOrganizationsResponse,
  UpdateOrganizationParams,
  UpdateOrganizationResponse,
  GetOrganizationUsersParams,
  GetOrganizationUsersResponse,
  GetOrganizationProjectsParams,
  GetOrganizationProjectsResponse,
  GetGroupParams,
  GetGroupResponse,
  GetOrganizationGroupsParams,
  GetOrganizationGroupsResponse,
  CreateGroupParams,
  CreateGroupResponse,
  UpdateGroupParams,
  UpdateGroupResponse,
  DeleteGroupParams,
  GetGroupUsersParams,
  GetGroupUsersResponse,
  AddUserToGroupParams,
  RemoveUserFromGroupParams
} from "./types";

const actionCreator = actionCreatorFactory("ENTERPRISES");

export const getOrganization = actionCreator.async<
  GetOrganizationParams,
  GetOrganizationResponse,
  unknown
>("GET_ORGANIZATION");

export const getOrganizations = actionCreator.async<
  GetOrganizationsParams,
  GetOrganizationsResponse,
  unknown
>("GET_ORGANIZATIONS");

export const createOrganization = actionCreator.async<
  CreateOrganizationParams,
  CreateOrganizationResponse,
  unknown
>("CREATE_ORGANIZATION");

export const updateOrganization = actionCreator.async<
  UpdateOrganizationParams,
  UpdateOrganizationResponse,
  unknown
>("UPDATE_ORGANIZATION");

export const deleteOrganization = actionCreator.async<
  DeleteOrganizationParams,
  void,
  unknown
>("DELETE_ORGANIZATION");

export const addAdminToOrganization = actionCreator.async<
  AddAdminToOrganizationParams,
  void,
  unknown
>("ADD_ADMINISTRATOR");

export const removeAdminFromOrganization = actionCreator.async<
  RemoveAdminFromOrganizationParams,
  void,
  unknown
>("REMOVE_ADMINISTRATOR");

export const changeOrganizationOwner = actionCreator.async<
  ChangeOrganizationOwnerParams,
  void,
  unknown
>("CHANGE_ORGANIZATION_OWNER");

export const getOrganizationUsers = actionCreator.async<
  GetOrganizationUsersParams,
  GetOrganizationUsersResponse,
  unknown
>("GET_ORGANIZATION_USERS");

export const getOrganizationProjects = actionCreator.async<
  GetOrganizationProjectsParams,
  GetOrganizationProjectsResponse,
  unknown
>("GET_ORGANIZATION_PROJECTS");

export const getGroup = actionCreator.async<
  GetGroupParams,
  GetGroupResponse,
  unknown
>("GET_GROUP");

export const getOrganizationGroups = actionCreator.async<
  GetOrganizationGroupsParams,
  GetOrganizationGroupsResponse,
  unknown
>("GET_ORGANIZATION_GROUPS");

export const createGroup = actionCreator.async<
  CreateGroupParams,
  CreateGroupResponse,
  unknown
>("CREATE_GROUP");

export const updateGroup = actionCreator.async<
  UpdateGroupParams,
  UpdateGroupResponse,
  unknown
>("UPDATE_GROUP");

export const deleteGroup = actionCreator.async<
  DeleteGroupParams,
  void,
  unknown
>("DELETE_GROUP");

export const getGroupUsers = actionCreator.async<
  GetGroupUsersParams,
  GetGroupUsersResponse,
  unknown
>("GET_GROUP_USERS");

export const addUserToGroup = actionCreator.async<
  AddUserToGroupParams,
  void,
  unknown
>("ADD_USER_TO_GROUP");

export const removeUserFromGroup = actionCreator.async<
  RemoveUserFromGroupParams,
  void,
  unknown
>("REMOVE_USER_FROM_GROUP");

export const clear = actionCreator<void>("CLEAR");
