import { ROLES_IN_ORG } from "../../constants";
import { RootReducer } from "../../reducers";
import {
  Group,
  Organization,
  OrganizationProject,
  OrganizationUser,
  TableOrganization
} from "./types";

export const tableOrganizationsSelector = (
  state: RootReducer
): TableOrganization[] | null =>
  state.enterprises.organizations?.map((organization) => ({
    ...organization,
    role: ROLES_IN_ORG[organization.role]
  })) || null;

export const areOrganizationsLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.areOrganizationsLoading;

export const organizationSelector = (state: RootReducer): Organization | null =>
  state.enterprises.organization;

export const isOrganizationLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.isOrganizationLoading;

export const isOrganizationCreatingSelector = (state: RootReducer): boolean =>
  state.enterprises.isOrganizationCreating;

export const isOrganizationUpdatingSelector = (state: RootReducer): boolean =>
  state.enterprises.isOrganizationUpdating;

export const isOrganizationDeletingSelector = (state: RootReducer): boolean =>
  state.enterprises.isOrganizationDeleting;

export const areProjectsLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.areOrganizationsLoading;

export const areOrganizationUsersLoadingSelector = (
  state: RootReducer
): boolean => state.enterprises.areOrganizationUsersLoading;

export const organizationUsersSelector = (
  state: RootReducer
): OrganizationUser[] | null => state.enterprises.organizationUsers || null;

// export const tableOrganizationUsersSelector = (
//   state: RootReducer
// ): TableUser[] | null =>
//   state.enterprises.organizationUsers &&
//   state.enterprises.organizationInvitations
//     ? [
//         ...state.enterprises.organizationUsers.map((user) => {
//           const ownershipTransferInvitation =
//             state.enterprises.organizationInvitations?.find(
//               (invitation) => invitation.inviteeEmail === user.email
//             );
//           return {
//             id: user.id,
//             email: user.email,
//             formattedEmail: `${user.email}${
//               ownershipTransferInvitation ? " (pending ownership transfer)" : ""
//             }`,
//             invitationId: ownershipTransferInvitation?.id,
//             invitationType: ownershipTransferInvitation?.type,
//             role: ROLE_LABELS[user.role],
//             fullName: user.full_name
//           };
//         }),
//         ...state.enterprises.organizationInvitations
//           .filter(
//             (invitation) =>
//               invitation.type === INVITATION_TYPES.ORGANIZATION_ADMINISTRATOR
//           )
//           .map((invitation) => ({
//             id: invitation.inviteeEmail,
//             email: invitation.inviteeEmail,
//             formattedEmail: `${invitation.inviteeEmail} (pending invitation)`,
//             invitationId: invitation.id,
//             invitationType: invitation.type,
//             role: "",
//             fullName: ""
//           }))
//       ].sort(sortUsersByEmail)
//     : null;

export const isOrganizationOwnerChangingSelector = (
  state: RootReducer
): boolean => state.enterprises.isOrganizationOwnerChanging;

export const isAdminAddingToOrganizationSelector = (
  state: RootReducer
): boolean => state.enterprises.isAdminAddingToOrganization;

export const isAdminRemovingFromOrganizationSelector = (
  state: RootReducer
): boolean => state.enterprises.isAdminRemovingFromOrganization;

export const organizationProjectsSelector = (
  state: RootReducer
): OrganizationProject[] | null => state.enterprises.organizationProjects;

export const areOrganizationProjectsLoadingSelector = (
  state: RootReducer
): boolean => state.enterprises.areOrganizationProjectsLoading;

export const organizationGroupsSelector = (
  state: RootReducer
): Group[] | null => state.enterprises.organizationGroups;

export const groupUsersSelector = (
  state: RootReducer
): OrganizationUser[] | null => state.enterprises.groupUsers;

export const groupSelector = (state: RootReducer): Group | null =>
  state.enterprises.group;

export const isGroupLoadingSelector = (state: RootReducer): boolean =>
  state.enterprises.areOrganizationGroupsLoading;

export const isGroupCreatingSelector = (state: RootReducer): boolean =>
  state.enterprises.isGroupCreating;

export const isGroupUpdatingSelector = (state: RootReducer): boolean =>
  state.enterprises.isGroupUpdating;

export const isGroupDeletingSelector = (state: RootReducer): boolean =>
  state.enterprises.isGroupDeleting;

export const isUserAddingToGroupSelector = (state: RootReducer): boolean =>
  state.enterprises.isUserAddingToGroup;

export const isUserRemovingFromGroupSelector = (state: RootReducer): boolean =>
  state.enterprises.isUserRemovingFromGroup;
