export enum BILLING_RESOURCES_TYPES {
  INSTANCES,
  VOLUMES,
  DATABASES,
  S3
}

export const BILLING_RESOURCES_TYPES_TITLES: {
  [key in BILLING_RESOURCES_TYPES]: string;
} = {
  [BILLING_RESOURCES_TYPES.INSTANCES]: "Instances",
  [BILLING_RESOURCES_TYPES.VOLUMES]: "Volumes",
  [BILLING_RESOURCES_TYPES.DATABASES]: "Databases",
  [BILLING_RESOURCES_TYPES.S3]: "Object Storage"
};

export type OrganizationProjectBillingData = {
  id: string;
  name: string;
  status: string;
  region: string;
  costs: Record<string, number>;
};

export type OrganizationBillingData = {
  id: string;
  name: string;
  status: string;
  projects: OrganizationProjectBillingData[];
  costs: Record<string, number>;
};

export type GetOrganizationBillingDataParams = {
  id: string;
  startDate: string;
  endDate: string;
};

export type GetOrganizationBillingDataResponse = OrganizationBillingData;

export type UserOrganizationBillingData = {
  id: string;
  name: string;
  status: string;
  costs: Record<string, number>;
};

export type UserBillingData = {
  companyName: string;
  companyTaxNumber: string;
  country: string;
  email: string;
  firstName?: string;
  lastName?: string;
  locality: string;
  mobile: string;
  postalCode: string;
  region: string;
  registrationDate: string;
  street: string;
  organizations: UserOrganizationBillingData[];
  costs: Record<string, number>;
};

export type GetUserBillingDataParams = {
  startDate: string;
  endDate: string;
  userId: string;
  pollingId?: string;
};

export type GetUserBillingDataResponse = UserBillingData;

export type TableBillingUser = {
  email: string;
  name: string;
  id: string;
  registrationDate: string;
  lastSeen: string;
};

export type TableOrganizationBillingData = {
  id: string;
  name: string;
  status: string;
} & Record<string, string>;

export type TableTotalOrganizationBillingData = {
  id: "__total";
  name: string;
  status: string;
} & Record<string, string>;

export type TableOrganizationProjectBillingData = {
  id: string;
  name: string;
  region: string;
  status: string;
} & Record<string, string>;

export type TableTotalOrganizationProjectBillingData = {
  id: "__total";
  name: string;
  region: string;
  status: string;
} & Record<string, string>;

export type GenerateBillingDataReportParams = {
  startDate: string;
  endDate: string;
  region: string;
};

export type GenerateGpuBillingDataReportParams = {
  startDate: string;
  endDate: string;
  region: string;
};

// Instances TAB
export type ProjectBillingInstancesData = {
  id: string;
  name: string;
  region: string;
  status: string;
  items: {
    id: string;
    type: string;
    name?: string;
    deleted?: string; // date
    costs: Record<string, number>;
    itemFlavors: {
      name: string;
      description: string;
      costs: Record<string, number>;
    }[];
  }[];
  costs: Record<string, number>;
};
export type TableBillingInstancesRecord = {
  id: string;
  type: string;
  name?: string;
  status?: string;
  deleted?: string;
  itemFlavors?: ({
    name: string;
    description: string;
  } & Record<string, string>)[];
} & Record<string, any>;

export type TableTotalBillingInstancesRecord = {
  id: string;
  type: string;
  name?: string;
  deletedAt?: string;
  status?: string;
} & Record<string, any>;

export type GetProjectBillingInstancesDataParams = {
  startDate: string;
  endDate: string;
  organizationId: string;
  projectId: string;
  regionId: string;
  pollingId?: string;
};
export type GetProjectBillingInstancesDataResponse =
  ProjectBillingInstancesData;

// Volumes TAB
export type ProjectBillingVolumesData = {
  id: string;
  name: string;
  region: string;
  status: string;
  items: {
    id: string;
    type: string;
    name?: string;
    deleted?: string; // "true" or "false"
    costs: Record<string, number>;
    itemFlavors: {
      name: string;
      description: string;
      costs: Record<string, number>;
    }[];
  }[];
  costs: Record<string, number>;
};

export type TableBillingVolumesRecord = {
  id: string;
  type: string;
  name?: string;
  deleted?: string; // "true" or "false"
  status?: string;
  itemFlavors?: ({
    name: string;
    description: string;
  } & Record<string, string>)[];
} & Record<string, any>;

export type TableTotalBillingVolumesRecord = {
  id: string;
  name?: string;
  status?: string;
  deleted?: string;
  type: string;
} & Record<string, any>;

export type GetProjectBillingVolumesDataParams = {
  startDate: string;
  endDate: string;
  organizationId: string;
  projectId: string;
  regionId: string;
  pollingId?: string;
};
export type GetProjectBillingVolumesDataResponse = ProjectBillingVolumesData;

// DB TAB
export type ProjectBillingDatabasesData = {
  id: string;
  name: string;
  region: string;
  status: string;
  items: {
    id: string;
    name: string;
    deleted: string;
    type: string;
    costs: Record<string, number>;
    itemFlavors: {
      name: string;
      costs: Record<string, number>;
    }[];
  }[];
  costs: Record<string, number>;
};

export type TableBillingDatabasesRecord = {
  id: string;
  name: string;
  type: string;
  deleted?: string;
  status?: string;
  itemFlavors?: ({
    name: string;
  } & Record<string, string>)[];
} & Record<string, any>;

export type TableTotalBillingDatabasesRecord = {
  id: string;
  type: string;
  name: string;
  deleted?: string;
  status?: string;
} & Record<string, any>;

export type GetProjectBillingDatabasesDataParams = {
  startDate: string;
  endDate: string;
  organizationId: string;
  projectId: string;
  regionId: string;
  pollingId?: string;
};
export type GetProjectBillingDatabasesDataResponse =
  ProjectBillingDatabasesData;

// S3 TAB
export type ProjectBillingS3Data = {
  id: string;
  name: string;
  region: string;
  status: string;
  items: {
    id: string;
    type: string;
    name?: string;
    deleted?: string; // "true" or "false"
    costs: Record<string, number>;
    itemFlavors: {
      name: string;
      description: string;
      costs: Record<string, number>;
    }[];
  }[];
  costs: Record<string, number>;
};

export type TableBillingS3Record = {
  id: string;
  type: string;
  name?: string;
  deleted?: string; // "true" or "false"
  status?: string;
  itemFlavors?: ({
    name: string;
  } & Record<string, string>)[];
} & Record<string, any>;

export type TableTotalBillingS3Record = {
  id: string;
  name?: string;
  status?: string;
  deleted?: string;
  type: string;
} & Record<string, any>;

export type GetProjectBillingS3DataParams = {
  startDate: string;
  endDate: string;
  organizationId: string;
  projectId: string;
  regionId: string;
  pollingId?: string;
};
export type GetProjectBillingS3DataResponse = ProjectBillingS3Data;

// summary tab
export type TableBillingSummaryRecord = {
  id: string; // BILLING_RESOURCES_TYPES (instances, volumes, databases)
  items?: ({
    id: string;
  } & Record<string, any>)[];
} & Record<string, any>;

export type TableTotalBillingSummaryRecord = {
  id: string;
} & Record<string, any>;

// charts
export type DailyCosts = Record<string, number>;
// type DailyCosts = {
//   chf: number;
//   eur: number;
// };

export type DailyChartBillingData = {
  regions?: string[];
  daily_costs: Record<string, Record<string, number>>;
};

export type DailyChartBillingDataParams = {
  startDate: string;
  endDate: string;
  regions?: string;
};

export type DailyChartBillingDataResponse = DailyChartBillingData;

export type DailyCostsCurrencyFormatted = {
  time_stamp: string;
  value: number;
}[];

export type MonthChartBillingData = {
  month: string;
  regions: {
    [key: string]: number;
  };
};

export type MonthChartBillingDataParams = {
  startDate?: string;
  endDate?: string;
};

export type MonthChartBillingDataResponse = MonthChartBillingData[];
