import axios, { AxiosResponse, CancelTokenSource } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  CreateUserParams,
  CreateUserResponse,
  GetUserInfoParams,
  GetUserInfoResponse,
  GetUserOrganizationsParams,
  GetUserOrganizationsResponse,
  GetUsersParams,
  GetUsersResponse,
  ModifyUserEnablingParams,
  SetUsersPasswdParams,
  UpdateUserInfoParams,
  UpdateUserInfoResponse
} from "./types";

const serviceApiPath = `gotham-enterprises/method/`;

export const cancelUsersTokens: {
  [key: string]: CancelTokenSource | undefined | null;
} = {};

export function* getUsersSaga(
  action: Action<GetUsersParams>
): SagaIterator<void> {
  try {
    if (!cancelUsersTokens["listUsers"]) {
      cancelUsersTokens["listUsers"] = axios.CancelToken.source();
    }

    const { offset, limit, show, search, sort, order } = action.payload;
    const response: AxiosResponse<GetUsersResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}users`,
      {
        params: {
          offset,
          limit,
          show,
          ["hide-disabled"]:
            "hide-disabled" in action.payload
              ? action.payload["hide-disabled"]
              : undefined,
          sort,
          order,
          search
        },
        cancelToken: cancelUsersTokens["listUsers"].token
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getUsers.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(actions.getUsers.failed({ params: action.payload, error: e }));
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get users data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* createUserSaga(
  action: Action<CreateUserParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the user...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { data } = action.payload;
    const response: AxiosResponse<CreateUserResponse> = yield call(
      axiosInstance.post,
      `${serviceApiPath}admin/users`,
      data
    );
    yield put(
      actions.createUser.done({ params: action.payload, result: response.data })
    );
    yield put(
      notificationsActions.showNotification({
        title: "User has been successfully created",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.createUser.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create user",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* setUsersPasswordSaga(
  action: Action<SetUsersPasswdParams>
): SagaIterator<void> {
  try {
    const { id, creds_temporary, creds_password } = action.payload;
    yield put(
      notificationsActions.showNotification({
        title: "Setting users password...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    yield call(axiosInstance.put, `${serviceApiPath}admin/users/${id}/passwd`, {
      creds_temporary,
      creds_password
    });
    yield put(actions.setUsersPassword.done({ params: action.payload }));
    yield put(
      notificationsActions.showNotification({
        title: "Users password has been successfully setted",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.setUsersPassword.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to set users password",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getUserOrganizationsSaga(
  action: Action<GetUserOrganizationsParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetUserOrganizationsResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}admin/users/${action.payload.id}/orgs`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getUserOrganizations.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getUserOrganizations.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get user's organizations data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getUserInfoSaga(
  action: Action<GetUserInfoParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetUserInfoResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}users/${action.payload.id}`
    );

    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getUserInfo.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getUserInfo.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get user's info data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateUserInfoSaga(
  action: Action<UpdateUserInfoParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the user info and settings...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { data, id } = action.payload;
    const response: AxiosResponse<UpdateUserInfoResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}admin/users/${id}`,
      data
    );
    yield put(
      actions.updateUserInfo.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "User info and settings has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateUserInfo.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update user info and settings",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateAdminRolesSaga(
  action: Action<UpdateUserInfoParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating admin roles...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { data, id } = action.payload;
    const response: AxiosResponse<UpdateUserInfoResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}admin/users/${id}/admin-roles`,
      data
    );
    yield put(
      actions.updateAdminRoles.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Admin Roles has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateAdminRoles.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update User info",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* modifyUserEnablingSaga(
  action: Action<ModifyUserEnablingParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Modifying user enabling...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { enabling_status, id } = action.payload;

    yield call(
      axiosInstance.put,
      `${serviceApiPath}admin/users/${id}/enable/${enabling_status}`
    );
    yield put(
      actions.modifyUserEnabling.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "User status has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.modifyUserEnabling.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update user status",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeEvery(actions.getUsers.started, getUsersSaga),
    takeEvery(actions.createUser.started, createUserSaga),
    takeEvery(actions.setUsersPassword.started, setUsersPasswordSaga),
    takeLeading(actions.getUserOrganizations.started, getUserOrganizationsSaga),
    takeLeading(actions.getUserInfo.started, getUserInfoSaga),
    takeEvery(actions.updateUserInfo.started, updateUserInfoSaga),
    takeEvery(actions.updateAdminRoles.started, updateAdminRolesSaga),
    takeEvery(actions.modifyUserEnabling.started, modifyUserEnablingSaga)
  ]);
}
