import styled from "styled-components";
import { TableCellText } from "components/common/Table/styles";
import { Typography } from "@mui/material";

export const CustomTableCellText = styled(TableCellText)`
  font-size: 0.85rem;
`;

export const TypographyColoredSuccess = styled(TableCellText)`
  color: ${({ theme }) => theme.palette.success.main};
  font-size: 0.85rem;
`;

export const TypographyColoredWarning = styled(TableCellText)`
  color: ${({ theme }) => theme.palette.warning.main};
  font-weight: 430;
  font-size: 0.85rem;
`;

export const TypographyColoredDanger = styled(TableCellText)`
  color: ${({ theme }) => theme.palette.error.dark};
  font-weight: 430;
  font-size: 0.85rem;
`;

export const ToggleLabel = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: 450;
  font-size: smaller;
`;
