import { TableOrganizationProjectBillingData } from "modules/billing/types";
import { OrganizationProject } from "modules/enterprises/types";
import { Project } from "modules/projects/types";

export const getProjectRegion = (
  projects:
    | TableOrganizationProjectBillingData[]
    | OrganizationProject[]
    | Project[],
  projectId: string
): string | undefined =>
  projects.find((project) => project.id === projectId)?.region.toLowerCase();
