export const formatTime = (milliseconds: number) => {
  const seconds = Math.floor(milliseconds / 1000);

  if (seconds < 60) {
    // return `${seconds} sec`;
    return `0 min`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} min`;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hr ${minutes} min`;
  } else {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor(((seconds % 86400) % 3600) / 60);
    return `${days} day ${hours} hr ${minutes} min`;
  }
};
