import axios, { AxiosResponse, CancelTokenSource } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  GetNetworkUsageParams,
  GetNetworkUsageResponse,
  GetPublicIPsParams,
  GetPublicIPsResponse,
  GetRegionNetworkUsageParams,
  GetRegionNetworkUsageResponse,
  UpdateCachePublicIPsResponse
} from "./types";
import { GetRegionResourceUtilizationParams } from "modules/monitoring/types";

export const cancelNetworkMonitoringTokens: {
  [key: string]: CancelTokenSource | undefined | null;
} = {};

export function* getPublicIPsSaga(
  action: Action<GetPublicIPsParams>
): SagaIterator<void> {
  try {
    // Create a new token if it doesn't exist
    if (!cancelNetworkMonitoringTokens["listPublicIPs"]) {
      cancelNetworkMonitoringTokens["listPublicIPs"] =
        axios.CancelToken.source();
    }

    const { offset, limit, region, search, sort, order } = action.payload;
    const response: AxiosResponse<GetPublicIPsResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/ips`,
      {
        params: {
          offset,
          limit,
          region,
          sort,
          order,
          search
        },
        cancelToken: cancelNetworkMonitoringTokens["listPublicIPs"]?.token
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getPublicIPs.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getPublicIPs.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get public IPs data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* updateCachePublicIPsSaga(
  action: Action<void>
): SagaIterator<void> {
  try {
    // const queryParams = new URLSearchParams(location.search);
    // const region = queryParams.get("region");
    // const search = queryParams.get("search");
    // const sort = queryParams.get("sort");
    // const order = queryParams.get("order");
    // const offset = Number(queryParams.get("offset") || "0");
    // const limit = Number(queryParams.get("limit") || "0");

    // const { offset, limit, region, search, sort, order } = action.payload;
    const response: AxiosResponse<UpdateCachePublicIPsResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/ips/force-cache-update`
    );

    yield put(
      actions.updateCachePublicIPs.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title:
          "Public IPs report for all regions requested. Expect it within a few minutes.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );

    // yield put(
    //   notificationsActions.showNotification({
    //     title: `Updated regions:`,
    //     text: `${response.data.updated_regions.join("\n")}`,
    //     type: NOTIFICATION_TYPES.INFO
    //   })
    // );

    // yield put(
    //   actions.getPublicIPs.started({
    //     region: region!,
    //     search: search || "",
    //     sort: sort || "",
    //     order: order || "",
    //     offset: offset,
    //     limit: limit
    //   })
    // );
  } catch (e) {
    yield put(
      actions.updateCachePublicIPs.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update cached public IPs data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getNetworkUtilizationDataSaga(
  action: Action<GetNetworkUsageParams>
): SagaIterator<void> {
  try {
    // Create a new token
    if (!cancelNetworkMonitoringTokens["networkUsage"]) {
      cancelNetworkMonitoringTokens["networkUsage"] =
        axios.CancelToken.source();
    }

    const { region } = action.payload;
    const response: AxiosResponse<GetNetworkUsageResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/networks?regions=all`,
      {
        cancelToken: cancelNetworkMonitoringTokens["networkUsage"].token
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getNetworkUsage.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getNetworkUsage.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get network usage data by region",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* getRegionNetworkUtilizationDataSaga(
  action: Action<GetRegionNetworkUsageParams>
): SagaIterator<void> {
  try {
    // Create a new token
    if (!cancelNetworkMonitoringTokens["regionNetworkUsage"]) {
      cancelNetworkMonitoringTokens["regionNetworkUsage"] =
        axios.CancelToken.source();
    }

    const { region } = action.payload;
    const response: AxiosResponse<GetRegionNetworkUsageResponse> = yield call(
      axiosInstance.get,
      `gotham-governor/method/admin/networks?regions=${region}`,
      {
        cancelToken: cancelNetworkMonitoringTokens["regionNetworkUsage"].token
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getRegionNetworkUsage.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    if (!axios.isCancel(e)) {
      yield put(
        actions.getRegionNetworkUsage.failed({
          params: action.payload,
          error: e
        })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get network usage data by region",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeEvery(actions.getPublicIPs.started, getPublicIPsSaga),
    takeEvery(actions.updateCachePublicIPs.started, updateCachePublicIPsSaga),
    takeEvery(actions.getNetworkUsage.started, getNetworkUtilizationDataSaga),
    takeEvery(
      actions.getRegionNetworkUsage.started,
      getRegionNetworkUtilizationDataSaga
    )
  ]);
}
