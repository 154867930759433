import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import {
  DailyChartBillingData,
  MonthChartBillingData,
  OrganizationBillingData,
  ProjectBillingDatabasesData,
  ProjectBillingInstancesData,
  ProjectBillingS3Data,
  ProjectBillingVolumesData,
  UserBillingData
} from "./types";

export type Reducer = {
  organizationBillingData: OrganizationBillingData | null;
  userBillingData: UserBillingData | null;
  projectBillingInstancesData: ProjectBillingInstancesData | null;
  isProjectBillingInstancesDataLoading: boolean;
  projectBillingVolumesData: ProjectBillingVolumesData | null;
  isProjectBillingVolumesDataLoading: boolean;
  projectBillingDatabasesData: ProjectBillingDatabasesData | null;
  isProjectBillingDatabasesDataLoading: boolean;
  projectBillingS3Data: ProjectBillingS3Data | null;
  isProjectBillingS3DataLoading: boolean;
  isOrganizationBillingDataLoading: boolean;
  isUserBillingDataLoading: boolean;
  isGpuBillingDataReportGenerating: boolean;
  isBillingDataReportGenerating: boolean;
  monthChartBillingData: MonthChartBillingData[] | null;
  isMonthChartBillingDataLoading: boolean;
  dailyChartBillingData: DailyChartBillingData | null;
  isDailyChartBillingDataLoading: boolean;
};

const initialState: Reducer = {
  organizationBillingData: null,
  userBillingData: null,
  projectBillingInstancesData: null,
  isProjectBillingInstancesDataLoading: false,
  projectBillingVolumesData: null,
  isProjectBillingVolumesDataLoading: false,
  projectBillingDatabasesData: null,
  isProjectBillingDatabasesDataLoading: false,
  projectBillingS3Data: null,
  isProjectBillingS3DataLoading: false,
  isOrganizationBillingDataLoading: false,
  isUserBillingDataLoading: false,
  isGpuBillingDataReportGenerating: false,
  isBillingDataReportGenerating: false,
  monthChartBillingData: null,
  isMonthChartBillingDataLoading: false,
  dailyChartBillingData: null,
  isDailyChartBillingDataLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  .case(
    actions.getOrganizationBillingData.started,
    (state): Reducer => ({
      ...state,
      isOrganizationBillingDataLoading: true
    })
  )
  .case(
    actions.getOrganizationBillingData.done,
    (state, payload): Reducer => ({
      ...state,
      organizationBillingData: payload.result,
      isOrganizationBillingDataLoading: false
    })
  )
  .case(
    actions.getOrganizationBillingData.failed,
    (state): Reducer => ({
      ...state,
      isOrganizationBillingDataLoading: false
    })
  )

  .case(
    actions.getUserBillingData.started,
    (state): Reducer => ({
      ...state,
      isUserBillingDataLoading: true
    })
  )
  .case(
    actions.getUserBillingData.done,
    (state, payload): Reducer => ({
      ...state,
      userBillingData: payload.result,
      isUserBillingDataLoading: false
    })
  )
  .case(
    actions.getUserBillingData.failed,
    (state): Reducer => ({
      ...state,
      isUserBillingDataLoading: false
    })
  )

  .case(
    actions.generateBillingDataReport.started,
    (state): Reducer => ({
      ...state,
      isBillingDataReportGenerating: true
    })
  )
  .case(
    actions.generateBillingDataReport.done,
    (state): Reducer => ({
      ...state,
      isBillingDataReportGenerating: false
    })
  )
  .case(
    actions.generateBillingDataReport.failed,
    (state): Reducer => ({
      ...state,
      isBillingDataReportGenerating: false
    })
  )

  .case(
    actions.generateGpuBillingDataReport.started,
    (state): Reducer => ({
      ...state,
      isGpuBillingDataReportGenerating: true
    })
  )
  .case(
    actions.generateGpuBillingDataReport.done,
    (state): Reducer => ({
      ...state,
      isGpuBillingDataReportGenerating: false
    })
  )
  .case(
    actions.generateGpuBillingDataReport.failed,
    (state): Reducer => ({
      ...state,
      isGpuBillingDataReportGenerating: false
    })
  )

  .case(
    actions.getProjectBillingInstancesData.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingInstancesDataLoading: true
    })
  )
  .case(
    actions.getProjectBillingInstancesData.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingInstancesData: payload.result,
      isProjectBillingInstancesDataLoading: false
    })
  )
  .case(
    actions.getProjectBillingInstancesData.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingInstancesDataLoading: false
    })
  )

  .case(
    actions.getProjectBillingVolumesData.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingVolumesDataLoading: true
    })
  )
  .case(
    actions.getProjectBillingVolumesData.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingVolumesData: payload.result,
      isProjectBillingVolumesDataLoading: false
    })
  )
  .case(
    actions.getProjectBillingVolumesData.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingVolumesDataLoading: false
    })
  )

  .case(
    actions.getProjectBillingDatabasesData.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingDatabasesDataLoading: true
    })
  )
  .case(
    actions.getProjectBillingDatabasesData.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingDatabasesData: payload.result,
      isProjectBillingDatabasesDataLoading: false
    })
  )
  .case(
    actions.getProjectBillingDatabasesData.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingDatabasesDataLoading: false
    })
  )

  .case(
    actions.getProjectBillingS3Data.started,
    (state): Reducer => ({
      ...state,
      isProjectBillingS3DataLoading: true
    })
  )
  .case(
    actions.getProjectBillingS3Data.done,
    (state, payload): Reducer => ({
      ...state,
      projectBillingS3Data: payload.result,
      isProjectBillingS3DataLoading: false
    })
  )
  .case(
    actions.getProjectBillingS3Data.failed,
    (state): Reducer => ({
      ...state,
      isProjectBillingS3DataLoading: false
    })
  )

  .case(
    actions.getMonthChartBillingData.started,
    (state): Reducer => ({
      ...state,
      isMonthChartBillingDataLoading: true
    })
  )
  .case(
    actions.getMonthChartBillingData.done,
    (state, payload): Reducer => ({
      ...state,
      monthChartBillingData: payload.result,
      isMonthChartBillingDataLoading: false
    })
  )
  .case(
    actions.getMonthChartBillingData.failed,
    (state): Reducer => ({
      ...state,
      isMonthChartBillingDataLoading: false
    })
  )

  .case(
    actions.getDailyChartBillingData.started,
    (state): Reducer => ({
      ...state,
      isDailyChartBillingDataLoading: true
    })
  )
  .case(
    actions.getDailyChartBillingData.done,
    (state, payload): Reducer => ({
      ...state,
      dailyChartBillingData: payload.result,
      isDailyChartBillingDataLoading: false
    })
  )
  .case(
    actions.getDailyChartBillingData.failed,
    (state): Reducer => ({
      ...state,
      isDailyChartBillingDataLoading: false
    })
  )

  .case(actions.clear, (): Reducer => initialState);
