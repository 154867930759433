import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Volume } from "./types";

export type Reducer = {
  erroredVolumes: Volume[] | null;
  areErroredVolumesLoading: boolean;
  // isCacheUpdating: boolean;
  // instancesCached: InstancesCached | null;
  // areInstancesCachedLoading: boolean;
};

const initialState: Reducer = {
  erroredVolumes: null,
  areErroredVolumesLoading: false
  // isCacheUpdating: false,
  // instancesCached: null,
  // areInstancesCachedLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  // errored volumes (in real time loaded data)
  .case(
    actions.getErroredVolumes.started,
    (state): Reducer => ({
      ...state,
      areErroredVolumesLoading: true
    })
  )
  .case(
    actions.getErroredVolumes.done,
    (state, payload): Reducer => ({
      ...state,
      erroredVolumes: payload.result,
      areErroredVolumesLoading: false
    })
  )
  .case(
    actions.getErroredVolumes.failed,
    (state): Reducer => ({
      ...state,
      areErroredVolumesLoading: false
    })
  )

  // Clear Volumes List
  .case(
    actions.clearErroredVolumesList,
    (state): Reducer => ({
      ...state,
      erroredVolumes: initialState.erroredVolumes
    })
  )

  .case(actions.clear, (): Reducer => initialState);
