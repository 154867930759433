import { Badge } from "@mui/material";
import Link, { LinkProps } from "@mui/material/Link";
import MuiTable from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { LinkProps as RouterLinkProps } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
  /* margin: ${({ theme }) => theme.spacing(1, 0)}; */
`;

export const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ theme }) => theme.spacing(2, 0)};
`;

export const SearchTextField = styled(TextField)`
  margin-left: auto;
`;

export const NoDataText = styled(Typography)`
  text-align: center;
`;

export const Table = styled(MuiTable)`
  table-layout: fixed;
  width: 100%;

  & th {
    background-color: ${({ theme }) =>
      theme.palette.mode === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[100]};
  }
`;

export const TableCellText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TableTotalCellText = styled(TableCellText)`
  font-weight: 500;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const BadgeStyled = styled(Badge)`
  margin-left: ${({ theme }) => theme.spacing(15)};
`;

export const TableCellTextDisabled = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.disabled};
`;

export const ActionsTableCell = styled(TableCell)`
  width: ${({ theme }) => theme.spacing(6)};
`;

export const LoaderContainer = styled.div`
  height: ${({ theme }) => theme.spacing(20)};
`;

export const TableLink = styled(Link)<LinkProps & RouterLinkProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
`;

export const TablePagination = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const TabsContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

export const IconContainer = styled.span<{ $centered?: boolean }>`
  display: flex;
  justify-content: ${({ $centered }) => ($centered ? "center" : "flex-start")};
`;

export const TableCellTextWithMargin = styled(TableCellText)`
  margin-left: ${({ theme }) => theme.spacing(0.5)};
`;
