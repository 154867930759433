import QuotasIcon from "@mui/icons-material/AppRegistrationSharp";
import QuotaEditIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import { IconButton, Tooltip } from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { useMount } from "hooks/useMount";
import { useUnmount } from "hooks/useUnmount";
import { adminRoleSelector } from "modules/auth/selectors";
import * as enterprisesActions from "modules/enterprises/actions";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { validateName } from "utils/validateName";
import { number, string } from "yup";
import {
  ADMIN_ROLES,
  ERROR_MESSAGES,
  PROJECT_NAME_LENGTH,
  REGEX,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES } from "./types";

import { LinearProgressWithLabel } from "components/common/LinearProgressWithLabel";
import { usePrevious } from "hooks/usePrevious";
import {
  areProjectQuotasSettingSelector,
  projectQuotasSelector,
  projectSelector
} from "modules/projects/selectors";
import { generatePath, useParams } from "react-router-dom";
import { Loader } from "components/common/Loader";

const POLL_ID_PREFIX = "PROJECT";
const POLL_IDS = {
  project: "PROJECT",
  projectQuotas: "PROJECT_QUOTAS"
};

export const Project: FC = () => {
  const dispatch = useDispatch();
  const adminRole = useSelector(adminRoleSelector);
  const isUserManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.USER_MANAGEMENT)
  );
  const isOperatorRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.OPERATOR)
  );
  const project = useSelector(projectSelector);
  const areProjectQuotasSetting = useSelector(areProjectQuotasSettingSelector);
  const previousAreProjectQuotasSetting = usePrevious(areProjectQuotasSetting);

  const projectQuotas = useSelector(projectQuotasSelector);

  const matchParams = useParams<{
    projectId: string;
    region: string;
  }>();

  const title = `Project: ${project?.name || ""}`;

  const breadcrumbs: Breadcrumb[] = [
    { text: "Projects", url: ROUTES.PROJECTS },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        region: matchParams.region,
        projectId: matchParams.projectId
      })
    }
  ];

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.EDIT, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      setDialog({
        type: dialogType,
        isOpened: true
      });
    },
    []
  );

  const headerMenuItems = [
    // {
    //   title: "Edit project",
    //   icon: <EditIcon />,
    //   isDisabled: true,
    //   onClick: () => handleDialogOpen(DIALOG_TYPES.EDIT)
    // },
    {
      title: "Set project quotas",
      icon: <QuotasIcon />,
      isDisabled: !projectQuotas,
      onClick: () => handleDialogOpen(DIALOG_TYPES.SET_QUOTAS)
    }
    // {
    //   title: "Delete project",
    //   icon: <DeleteIcon />,
    //   isDisabled: true,
    //   onClick: () => handleDialogOpen(DIALOG_TYPES.DELETE)
    // }
  ];

  const handleConfirmEditProject = useCallback(
    (data: { name: string }) => {
      if (selectedItemId) {
        // console.log(data);
      }
      handleCloseDialog();
    },
    [selectedItemId, handleCloseDialog]
  );

  const handleConfirmSetQuotaProject = useCallback(
    (data: {
      vm_limit: number;
      vm_total_vCPUs: number;
      vm_total_memory: number;
      volumes_limit: number;
      volume_snapshots_limit: number;
      volume_storage_limit: number;
      networks_limit: number;
      firewalls_limit: number;
      firewall_rules_limit: number;
    }) => {
      dispatch(
        projectsActions.setProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!,
          data: {
            instances: data.vm_limit,
            cores: data.vm_total_vCPUs,
            ram: data.vm_total_memory * 1024,
            volumes: data.volumes_limit,
            snapshots: data.volume_snapshots_limit,
            gigabytes: data.volume_storage_limit,
            network: data.networks_limit,
            security_group: data.firewalls_limit,
            security_group_rule: data.firewall_rules_limit
          }
        })
      );
      handleCloseDialog();
    },
    [handleCloseDialog, dispatch, matchParams.region, matchParams.projectId]
  );

  const handleConfirmSetVMQuotasProject = useCallback(
    (data: { vm_limit: number }) => {
      dispatch(
        projectsActions.setProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!,
          data: {
            instances: data.vm_limit
          }
        })
      );
      handleCloseDialog();
    },
    [handleCloseDialog, dispatch, matchParams.region, matchParams.projectId]
  );

  const handleConfirmSetCPUQuotasProject = useCallback(
    (data: { vm_total_vCPUs: number }) => {
      dispatch(
        projectsActions.setProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!,
          data: {
            cores: data.vm_total_vCPUs
          }
        })
      );
      handleCloseDialog();
    },
    [handleCloseDialog, dispatch, matchParams.region, matchParams.projectId]
  );

  const handleConfirmSetRAMQuotasProject = useCallback(
    (data: { vm_total_memory: number }) => {
      dispatch(
        projectsActions.setProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!,
          data: {
            ram: data.vm_total_memory * 1024
          }
        })
      );
      handleCloseDialog();
    },
    [handleCloseDialog, dispatch, matchParams.region, matchParams.projectId]
  );

  const handleConfirmSetVolumesQuotasProject = useCallback(
    (data: { volumes_limit: number }) => {
      dispatch(
        projectsActions.setProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!,
          data: {
            volumes: data.volumes_limit
          }
        })
      );
      handleCloseDialog();
    },
    [handleCloseDialog, dispatch, matchParams.region, matchParams.projectId]
  );

  const handleConfirmSetSnapshotsQuotasProject = useCallback(
    (data: { volume_snapshots_limit: number }) => {
      dispatch(
        projectsActions.setProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!,
          data: {
            snapshots: data.volume_snapshots_limit
          }
        })
      );
      handleCloseDialog();
    },
    [handleCloseDialog, dispatch, matchParams.region, matchParams.projectId]
  );

  const handleConfirmSetStorageQuotasProject = useCallback(
    (data: { volume_storage_limit: number }) => {
      dispatch(
        projectsActions.setProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!,
          data: {
            gigabytes: data.volume_storage_limit
          }
        })
      );
      handleCloseDialog();
    },
    [handleCloseDialog, dispatch, matchParams.region, matchParams.projectId]
  );

  const handleConfirmSetNetworkQuotasProject = useCallback(
    (data: { networks_limit: number }) => {
      dispatch(
        projectsActions.setProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!,
          data: {
            network: data.networks_limit
          }
        })
      );
      handleCloseDialog();
    },
    [handleCloseDialog, dispatch, matchParams.region, matchParams.projectId]
  );

  const handleConfirmSetFirewallsQuotasProject = useCallback(
    (data: { firewalls_limit: number }) => {
      dispatch(
        projectsActions.setProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!,
          data: {
            security_group: data.firewalls_limit
          }
        })
      );
      handleCloseDialog();
    },
    [handleCloseDialog, dispatch, matchParams.region, matchParams.projectId]
  );

  const handleConfirmSetFirewallRulesQuotasProject = useCallback(
    (data: { firewall_rules_limit: number }) => {
      dispatch(
        projectsActions.setProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!,
          data: {
            security_group_rule: data.firewall_rules_limit
          }
        })
      );
      handleCloseDialog();
    },
    [handleCloseDialog, dispatch, matchParams.region, matchParams.projectId]
  );

  const handleConfirmDeleteProject = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        enterprisesActions.deleteOrganization.started({
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [dispatch, selectedItemId, handleCloseDialog]);

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditProject,
      title: "Edit project",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: project?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(PROJECT_NAME_LENGTH)
            })
            .matches(REGEX.PROJECT_NAME, ERROR_MESSAGES.PROJECT_NAME)
        }
      ]
    },
    [DIALOG_TYPES.SET_VM_QUOTA]: {
      onConfirm: handleConfirmSetVMQuotasProject,
      title: `Set quota`,
      fields: [
        {
          name: "vm_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Virtual machines limit",
          defaultValue: projectQuotas?.instances.limit || 1,
          min: 1,
          suffix: "vm",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quota"
    },
    [DIALOG_TYPES.SET_VM_vCPUs_QUOTA]: {
      onConfirm: handleConfirmSetCPUQuotasProject,
      title: `Set quota`,
      fields: [
        {
          name: "vm_total_vCPUs",
          type: FIELD_TYPES.NUMBER,
          label: "Virtual machines total vCPUs limit",
          defaultValue: projectQuotas?.cores.limit || 1,
          min: 1,
          suffix: "vCPU",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quota"
    },
    [DIALOG_TYPES.SET_VM_MEMORY_QUOTA]: {
      onConfirm: handleConfirmSetRAMQuotasProject,
      title: `Set quota`,
      fields: [
        {
          name: "vm_total_memory",
          type: FIELD_TYPES.NUMBER,
          label: "Virtual machines total memory limit",
          defaultValue: projectQuotas?.ram.limit
            ? projectQuotas.ram.limit / 1024
            : 1,
          min: 1,
          suffix: "GiB ",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quota"
    },
    [DIALOG_TYPES.SET_VOL_QUOTA]: {
      onConfirm: handleConfirmSetVolumesQuotasProject,
      title: `Set quota`,
      fields: [
        {
          name: "volumes_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Volumes limit",
          defaultValue: projectQuotas?.volumes.limit || 1,
          min: 1,
          suffix: "vol",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quota"
    },
    [DIALOG_TYPES.SET_VOL_SNAP_QUOTA]: {
      onConfirm: handleConfirmSetSnapshotsQuotasProject,
      title: `Set quota`,
      fields: [
        {
          name: "volume_snapshots_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Volume snapshots limit",
          defaultValue: projectQuotas?.snapshots.limit || 1,
          min: 1,
          suffix: "snap",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quota"
    },
    [DIALOG_TYPES.SET_VOL_STORAGE_QUOTA]: {
      onConfirm: handleConfirmSetStorageQuotasProject,
      title: `Set quota`,
      fields: [
        {
          name: "volume_storage_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Volume storage limit",
          defaultValue: projectQuotas?.gigabytes.limit || 1,
          min: 1,
          suffix: "GiB",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quota"
    },
    [DIALOG_TYPES.SET_NET_QUOTA]: {
      onConfirm: handleConfirmSetNetworkQuotasProject,
      title: `Set quota`,
      fields: [
        {
          name: "networks_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Networks limit",
          defaultValue: projectQuotas?.network.limit || 1,
          min: 1,
          suffix: "net",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quota"
    },
    [DIALOG_TYPES.SET_FW_QUOTA]: {
      onConfirm: handleConfirmSetFirewallsQuotasProject,
      title: `Set quota`,
      fields: [
        {
          name: "firewalls_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Firewalls limit",
          defaultValue: projectQuotas?.security_group.limit || 1,
          min: 1,
          suffix: "fw",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quota"
    },
    [DIALOG_TYPES.SET_FW_R_QUOTA]: {
      onConfirm: handleConfirmSetFirewallRulesQuotasProject,
      title: `Set quota`,
      fields: [
        {
          name: "firewall_rules_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Firewall rules limit",
          defaultValue: projectQuotas?.security_group_rule.limit || 1,
          min: 1,
          suffix: "fw_r",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quota"
    },
    [DIALOG_TYPES.SET_QUOTAS]: {
      onConfirm: handleConfirmSetQuotaProject,
      title: `Set quotas for current project?`,
      fields: [
        {
          name: "vm_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Virtual machines limit",
          defaultValue: projectQuotas?.instances.limit || 1,
          min: 1,
          suffix: " vm ",
          rules: number().integer().required()
        },
        {
          name: "vm_total_vCPUs",
          type: FIELD_TYPES.NUMBER,
          label: "Virtual machines total vCPUs",
          defaultValue: projectQuotas?.cores.limit || 1,
          min: 1,
          suffix: "vCPU",
          rules: number().integer().required()
        },
        {
          name: "vm_total_memory",
          type: FIELD_TYPES.NUMBER,
          label: "Virtual machines total memory",
          defaultValue: projectQuotas?.ram.limit
            ? projectQuotas.ram.limit / 1024
            : 1,
          min: 1,
          suffix: "GiB ",
          rules: number().integer().required()
        },
        {
          name: "volumes_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Volumes limit",
          defaultValue: projectQuotas?.volumes.limit || 1,
          min: 1,
          suffix: "vol",
          rules: number().integer().required()
        },
        {
          name: "volume_snapshots_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Volume snapshots limit",
          defaultValue: projectQuotas?.snapshots.limit || 1,
          min: 1,
          suffix: "snap",
          rules: number().integer().required()
        },
        {
          name: "volume_storage_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Volume storage limit",
          defaultValue: projectQuotas?.gigabytes.limit || 1,
          min: 1,
          suffix: "GiB",
          rules: number().integer().required()
        },
        {
          name: "networks_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Networks limit",
          defaultValue: projectQuotas?.network.limit || 1,
          min: 1,
          suffix: "net",
          rules: number().integer().required()
        },
        {
          name: "firewalls_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Firewalls limit",
          defaultValue: projectQuotas?.security_group.limit || 1,
          min: 1,
          suffix: "fw",
          rules: number().integer().required()
        },
        {
          name: "firewall_rules_limit",
          type: FIELD_TYPES.NUMBER,
          label: "Firewall rules limit",
          defaultValue: projectQuotas?.security_group_rule.limit || 1,
          min: 1,
          suffix: "fw_r",
          rules: number().integer().required()
        }
      ],
      confirmButtonLabel: "Set Quotas"
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteProject,
      title: `Are you sure you want to delete current project?`,
      confirmButtonLabel: "Delete"
    }
  };

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.project}`,
        action: projectsActions.getProject.started({
          region: matchParams.region!,
          id: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.projectQuotas}`,
        action: projectsActions.getProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(projectsActions.clear());
  });

  useEffect(() => {
    if (areProjectQuotasSetting && !areProjectQuotasSetting) {
      dispatch(
        projectsActions.getProjectQuotas.started({
          region: matchParams.region!,
          id: matchParams.projectId!
        })
      );
    }
  }, [
    dispatch,
    previousAreProjectQuotasSetting,
    areProjectQuotasSetting,
    matchParams.region,
    matchParams.projectId
  ]);

  const renderMetric = (
    title: string,
    value: number,
    maxValue: number,
    dialogType: DIALOG_TYPES,
    unit?: string
  ) => (
    <s.MetricContainer>
      <s.MetricTitleContainer>
        <s.MetricTitle>{title}</s.MetricTitle>
        {isUserManagementAllowed || isOperatorRoleAllowed ? (
          <Tooltip title={"Edit"} arrow placement="top">
            <span>
              <IconButton
                onClick={() => handleDialogOpen(dialogType)}
                color={"primary"}
                size="small"
              >
                <QuotaEditIcon fontSize="small" />
              </IconButton>
            </span>
          </Tooltip>
        ) : (
          ""
        )}
      </s.MetricTitleContainer>

      <LinearProgressWithLabel value={value} maxValue={maxValue} unit={unit} />
    </s.MetricContainer>
  );

  return (
    <>
      <Head title={title} />
      {!project ? (
        <Loader text={"Loading data..."} />
      ) : (
        <>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <s.SummaryContainer>
            <s.Title variant={"h4"} component={"h2"}>
              Project: {project?.name}
            </s.Title>
            {isOperatorRoleAllowed || isUserManagementAllowed ? (
              <s.ActionsContainer>
                {headerMenuItems.map((menuItem, index) => (
                  <Tooltip title={menuItem.title} arrow key={index}>
                    <span>
                      <IconButton
                        onClick={() => menuItem.onClick && menuItem.onClick()}
                        color={"inherit"}
                        disabled={menuItem.isDisabled}
                      >
                        {menuItem.icon}
                      </IconButton>
                    </span>
                  </Tooltip>
                ))}
              </s.ActionsContainer>
            ) : (
              ""
            )}
          </s.SummaryContainer>
          <s.SummaryRow>
            <s.DetailsTitleMain>ID: </s.DetailsTitleMain>
            <s.DetailsInfoMainColored>{project?.id}</s.DetailsInfoMainColored>
          </s.SummaryRow>
          <s.SummaryRow>
            <s.DetailsTitleMain>Region: </s.DetailsTitleMain>
            <s.DetailsInfoMainColored>
              {project?.region}
            </s.DetailsInfoMainColored>
          </s.SummaryRow>
          <s.SummaryRow>
            <s.DetailsTitleMain>Organization ID: </s.DetailsTitleMain>
            <s.DetailsInfoMainColored>
              {project?.org_id}
            </s.DetailsInfoMainColored>
          </s.SummaryRow>
          <s.SummaryRow>
            <s.DetailsTitleMain>Organization Name: </s.DetailsTitleMain>
            <s.DetailsInfoMainColored>
              {project?.org_name}
            </s.DetailsInfoMainColored>
          </s.SummaryRow>

          <s.MetricsContainer variant={"outlined"}>
            {!projectQuotas ? (
              <Loader text={"Loading quotas data..."} />
            ) : (
              <>
                <s.MetricRowContainer>
                  {renderMetric(
                    "Virtual machines",
                    projectQuotas?.instances.in_use || 0,
                    projectQuotas?.instances.limit || 0,
                    DIALOG_TYPES.SET_VM_QUOTA
                  )}
                  {renderMetric(
                    "VM total vCPUs",
                    projectQuotas?.cores.in_use || 0,
                    projectQuotas?.cores.limit || 0,
                    DIALOG_TYPES.SET_VM_vCPUs_QUOTA
                  )}
                  {renderMetric(
                    "VM total memory",
                    projectQuotas?.ram.in_use
                      ? projectQuotas.ram.in_use / 1024
                      : 0,
                    projectQuotas?.ram.limit
                      ? projectQuotas.ram.limit / 1024
                      : 0,
                    DIALOG_TYPES.SET_VM_MEMORY_QUOTA,
                    "GiB"
                  )}
                </s.MetricRowContainer>
                <s.MetricRowContainer>
                  {renderMetric(
                    "Volumes",
                    projectQuotas?.volumes.in_use || 0,
                    projectQuotas?.volumes.limit || 0,
                    DIALOG_TYPES.SET_VOL_QUOTA
                  )}
                  {renderMetric(
                    "Volume snapshots",
                    projectQuotas?.snapshots.in_use || 0,
                    projectQuotas?.snapshots.limit || 0,
                    DIALOG_TYPES.SET_VOL_SNAP_QUOTA
                  )}
                  {renderMetric(
                    "Volume storage",
                    projectQuotas?.gigabytes.in_use || 0,
                    projectQuotas?.gigabytes.limit || 0,
                    DIALOG_TYPES.SET_VOL_STORAGE_QUOTA,
                    "GiB"
                  )}
                </s.MetricRowContainer>
                <s.MetricRowContainer>
                  {renderMetric(
                    "Networks",
                    projectQuotas?.network.in_use || 0,
                    projectQuotas?.network.limit || 0,
                    DIALOG_TYPES.SET_NET_QUOTA
                  )}
                  {renderMetric(
                    "Firewalls",
                    projectQuotas?.security_group.in_use || 0,
                    projectQuotas?.security_group.limit || 0,
                    DIALOG_TYPES.SET_FW_QUOTA
                  )}
                  {renderMetric(
                    "Firewall rules",
                    projectQuotas?.security_group_rule.in_use || 0,
                    projectQuotas?.security_group_rule.limit || 0,
                    DIALOG_TYPES.SET_FW_R_QUOTA
                  )}
                </s.MetricRowContainer>
              </>
            )}
          </s.MetricsContainer>

          <FormDialog
            isOpened={dialog.isOpened}
            onCancel={handleCloseDialog}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
          />
        </>
      )}
    </>
  );
};
