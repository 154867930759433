import { FC, useEffect, useState } from "react";
import {
  LinearProgress,
  Typography,
  Box,
  linearProgressClasses
} from "@mui/material";
import { roundTo } from "utils/roundTo";
import { LinerProgressWithLabelProps } from "./types";

const ANIMATION_FREQUENCY = 50;
const ANIMATION_PERCENTAGE_STEP = 5;

export const LinearProgressWithLabel: FC<LinerProgressWithLabelProps> = ({
  value,
  maxValue,
  unit
}: LinerProgressWithLabelProps) => {
  const [progress, setProgress] = useState(0);
  const percentageValue = (value / maxValue) * 100;

  useEffect(() => {
    const timerId = setInterval(() => {
      setProgress((prevProgress) =>
        Math.min(
          100,
          prevProgress +
            Math.min(ANIMATION_PERCENTAGE_STEP, percentageValue - prevProgress)
        )
      );
    }, ANIMATION_FREQUENCY);

    return () => {
      clearInterval(timerId);
    };
  }, [percentageValue]);

  return (
    <Box sx={{ width: "100%", mt: 0 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={progress}
            sx={(theme) => ({
              borderRadius: "30px",
              boxShadow:
                theme.palette.mode === "dark"
                  ? `0px 1px 3px 0px ${theme.palette.grey["900"]}, 0px 1px 1px 0px ${theme.palette.grey["900"]}, 0px 2px 1px -1px ${theme.palette.secondary.main}`
                  : theme.shadows[1],
              transition:
                "background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease",
              "&:hover": {
                transform: "scaleY(1.5) scaleX(1.03)",
                boxShadow:
                  theme.palette.mode === "dark"
                    ? `0px 1px 1px -2px ${theme.palette.grey["900"]}, 0px 1px 1px -1px ${theme.palette.secondary.main}}`
                    : theme.shadows[1]
              },
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.grey["900"]
                    : theme.palette.grey["100"]
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: "30px",
                backgroundColor: theme.palette.secondary.main
              }
            })}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            percentageValue
          )}%`}</Typography>
        </Box>
      </Box>
      <Box mt={0} display="flex" justifyContent="center">
        {" "}
        <Typography
          variant={"caption"}
          component={"div"}
          color={"textSecondary"}
          margin={"0 30px 0 0"}
        >
          {`${roundTo(value, 2)} of ${roundTo(maxValue, 2)}${
            unit ? ` ${unit}` : ""
          }`}
        </Typography>
      </Box>
    </Box>
  );
};
