// import HypervisorsIcon from "@mui/icons-material/AssessmentRounded";
import BarChart from "@mui/icons-material/BarChartRounded";
import DashboardIcon from "@mui/icons-material/Dashboard";
import OrganizationIcon from "@mui/icons-material/Domain";
import PieChart from "@mui/icons-material/DonutSmallRounded";
import BillingChartIcon from "@mui/icons-material/AccountBalanceWallet";
import DnsIcon from "@mui/icons-material/Dns";
// import GroupIcon from "@mui/icons-material/GroupsRounded";

import CpuIcon from "@mui/icons-material/Memory";
import PersonIcon from "@mui/icons-material/PeopleRounded";
import ServerIcon from "@mui/icons-material/Storage";
import RamIcon from "@mui/icons-material/DeveloperBoard";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import RouterIcon from "@mui/icons-material/Router";
import { ListItemButton, Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";
import { Content } from "components/Content";
import { Logo } from "components/common/Logo";
import { Snackbar } from "components/common/Snackbar";
import { ClusterIcon } from "components/common/icons/ClusterIcon";
import { adminRoleSelector, isAdminSelector } from "modules/auth/selectors";
import * as notificationsActions from "modules/notifications/actions";
import { notificationSelector } from "modules/notifications/selectors";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, generatePath, useLocation } from "react-router-dom";
import { appConfig } from "../../appConfig";
import { ADMIN_ROLES, ROUTES } from "../../constants";
import * as s from "./styles";
import { NavigationMenuItem } from "./types";
import { useDarkMode } from "usehooks-ts";

export const Container = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { isDarkMode, toggle } = useDarkMode();
  const adminRole = useSelector(adminRoleSelector);
  const isUserManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.USER_MANAGEMENT)
  );

  const isBillingManagementAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.BILLING)
  );

  const isOperatorRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.OPERATOR)
  );

  const isObserveRoleAllowed = adminRole?.some((role) =>
    role.includes(ADMIN_ROLES.OBSERVE)
  );

  const identityMenuItems: NavigationMenuItem[] = [
    {
      route: ROUTES.USERS,
      icon: <PersonIcon />,
      label: "Users"
    },
    {
      route: ROUTES.ORGANIZATIONS,
      icon: <OrganizationIcon />,
      label: "Organizations",
      disabled: !isUserManagementAllowed,
      tooltip_disabled: "No permissions"
    }
  ];

  const resourcesMenuItems: NavigationMenuItem[] = [
    {
      route: ROUTES.PROJECTS,
      icon: <DashboardIcon />,
      label: "Projects",
      disabled: !isOperatorRoleAllowed,
      tooltip_disabled: "No permissions"
    },
    {
      route: ROUTES.VIRTUAL_MACHINES,
      icon: <ServerIcon />,
      label: "Virtual Machines",
      disabled: !isObserveRoleAllowed,
      tooltip_disabled: "No permissions"
    },
    {
      route: ROUTES.CLUSTERS,
      icon: <ClusterIcon />,
      disabled: true,
      tooltip_disabled: "This option is under development",
      label: "Clusters"
    },
    {
      route: ROUTES.FAILED_RESOURCES,
      icon: <ErrorIcon />,
      disabled: !isObserveRoleAllowed,
      tooltip_disabled: "No permissions",
      label: "Issues"
    }
  ];

  const monitoringMenuItems: NavigationMenuItem[] = [
    // {
    //   route: ROUTES.HYPERVISORS_OVERVIEW,
    //   icon: <HypervisorsIcon />,
    //   disabled: true,
    //   tooltip_disabled: "This option is under development",
    //   label: "Hypervisors Overview"
    // },
    {
      route: ROUTES.BILLING_CHARTS,
      icon: <BillingChartIcon />,
      disabled: !isBillingManagementAllowed,
      tooltip_disabled: "No permissions",
      label: "Billing Charts"
    },
    {
      route: ROUTES.NETWORK_USAGE,
      icon: <RouterIcon />,
      disabled: !isObserveRoleAllowed,
      tooltip_disabled: "No permissions",
      label: "Network Usage"
    },
    {
      route: ROUTES.HYPERVISORS_OVERVIEW,
      icon: <PieChart />,
      disabled: !isObserveRoleAllowed,
      tooltip_disabled: "No permissions",
      label: "Hypervisors"
    },
    {
      route: ROUTES.RAM,
      icon: <RamIcon />,
      disabled: !isObserveRoleAllowed,
      tooltip_disabled: "No permissions",
      label: "RAM"
    },
    {
      route: ROUTES.CPU,
      icon: <CpuIcon />,
      disabled: !isObserveRoleAllowed,
      tooltip_disabled: "No permissions",
      label: "CPU"
    },
    {
      route: ROUTES.DISK_SPACE,
      // icon: <DiskSpaceIcon />,
      icon: <DnsIcon />,
      disabled: true,
      tooltip_disabled: "This option is under development",
      label: "Disk Space"
    }
    // {
    //   route: ROUTES.NETWORK_USAGE,
    //   icon: <NetworkCheckIcon />,
    //   disabled: !isObserveRoleAllowed,
    //   tooltip_disabled: "No permissions",
    //   label: "Network Usage"
    // }
  ];

  const dispatch = useDispatch();
  const notification = useSelector(notificationSelector);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState<boolean>(false);
  const isAdmin = useSelector(isAdminSelector);

  const handleCloseSnackbar = useCallback(() => {
    setIsSnackbarVisible(false);
  }, []);

  const handleSnackbarExited = useCallback(() => {
    dispatch(notificationsActions.clear());
  }, [dispatch]);

  useEffect(() => {
    setIsSnackbarVisible(Boolean(notification));
  }, [notification]);

  // const currentRoute = Object.values(ROUTES).find((route) =>
  //   matchPath(location.pathname, { path: route, exact: true })
  // );

  // const match = matchPath<MatchParams>(location.pathname, {
  //   path: currentRoute,
  //   exact: true
  // });

  const renderMenuItems = (menuItems: NavigationMenuItem[]) =>
    menuItems.map((menuItem) => (
      <Tooltip
        key={menuItem.route}
        title={
          menuItem.disabled && menuItem.tooltip_disabled
            ? menuItem.tooltip_disabled
            : ""
        }
        placement="right"
        arrow
      >
        <span>
          <ListItemButton
            key={menuItem.route}
            disabled={menuItem.disabled}
            sx={{ padding: "0" }}
            // component={s.ListItem}
            // selected={currentRoute?.includes(menuItem.route)}
            selected={
              location.pathname === menuItem.route ||
              location.pathname.startsWith(menuItem.route)
            }
            data-testid={"container-drawer-menulist-item"}
          >
            {/* <s.Link to={generatePath(menuItem.route, match?.params)}> */}
            <s.Link to={generatePath(menuItem.route)}>
              <s.IconContainer>{menuItem.icon}</s.IconContainer>
              <Typography>{menuItem.label}</Typography>
            </s.Link>
          </ListItemButton>
        </span>
      </Tooltip>
    ));

  return (
    <s.Container>
      <s.Drawer variant={"permanent"} open={true}>
        <s.DrawerHeader>
          <Link to={ROUTES.ROOT}>
            <s.LogoContainer>
              {appConfig.theme.logoURL ? (
                appConfig.theme.isDarkModeEnabled &&
                isDarkMode &&
                appConfig.theme.darkModeLogoURL ? (
                  <s.LogoImage
                    src={appConfig.theme.darkModeLogoURL}
                    alt={appConfig.theme.logoDescription}
                  />
                ) : (
                  <s.LogoImage
                    src={appConfig.theme.logoURL}
                    alt={appConfig.theme.logoDescription}
                  />
                )
              ) : (
                <Logo height={48} />
              )}
            </s.LogoContainer>
          </Link>
        </s.DrawerHeader>
        {isAdmin && (
          <s.DrawerMenuList>
            <s.AdminSectionHeader>
              <Typography variant="h6">{"Admin Portal"}</Typography>
            </s.AdminSectionHeader>
            <s.SectionName>
              <s.SectionNameText>{"IDENTITY MANAGEMENT"}</s.SectionNameText>
            </s.SectionName>
            {renderMenuItems(identityMenuItems)}
            <s.SectionName>
              <s.SectionNameText>{"RESOURCES MANAGEMENT"}</s.SectionNameText>
            </s.SectionName>
            {renderMenuItems(resourcesMenuItems)}
            <s.SectionName>
              <s.SectionNameText>{"MONITORING"}</s.SectionNameText>
            </s.SectionName>
            {renderMenuItems(monitoringMenuItems)}
            <s.MenuFooter />
          </s.DrawerMenuList>
        )}
      </s.Drawer>
      <Content>{children}</Content>
      {notification && (
        <Snackbar
          type={notification.type}
          isOpened={isSnackbarVisible}
          onClose={handleCloseSnackbar}
          onExited={handleSnackbarExited}
          title={notification.title}
          text={notification.text}
        />
      )}
    </s.Container>
  );
};
