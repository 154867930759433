import FailedIcon from "@mui/icons-material/ClearRounded";
import SuccessIcon from "@mui/icons-material/Done";
import { Tab, Tabs } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { USER_TAB_TITLES } from "components/User";
import { TABS as USER_TABS } from "components/User/types";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { Head } from "components/common/Head";
import {
  TABLE_SORTING_TYPES,
  TableColumn
} from "components/common/Table/types";
import { TableCollapsible } from "components/common/TableCollapsible";
import { addDays, startOfMonth, sub, subDays } from "date-fns";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as billingActions from "modules/billing/actions";
import {
  isProjectBillingDatabasesDataLoadingSelector,
  isProjectBillingInstancesDataLoadingSelector,
  isProjectBillingS3DataLoadingSelector,
  isProjectBillingVolumesDataLoadingSelector,
  projectBillingInstancesDataSelector,
  tableProjectBillingDatabasesRecordsSelector,
  tableProjectBillingDatabasesRecordsTotalSelector,
  tableProjectBillingInstancesRecordsSelector,
  tableProjectBillingInstancesRecordsTotalSelector,
  tableProjectBillingS3RecordsSelector,
  tableProjectBillingS3RecordsTotalSelector,
  tableProjectBillingSummaryRecordsSelector,
  tableProjectBillingSummaryRecordsTotalSelector,
  tableProjectBillingVolumesRecordsSelector,
  tableProjectBillingVolumesRecordsTotalSelector
} from "modules/billing/selectors";
import {
  TableBillingDatabasesRecord,
  TableBillingInstancesRecord,
  TableBillingS3Record,
  TableBillingSummaryRecord,
  TableBillingVolumesRecord
} from "modules/billing/types";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as usersActions from "modules/users/actions";
import { userInfoSelector } from "modules/users/selectors";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import { generateSearchString } from "utils/generateSearchString";
import { getFullName } from "utils/getFullName";
import { DATE_FORMATS, DEFAULT_CURRENCY, ROUTES } from "../../constants";
import * as s from "./styles";
import { CollapsibleData, DATE_TYPES, TABS } from "./types";

const CURRENCY_SELECT_ID = "currency";
// const collapsibleSummaryColumns: TableColumn<TableBillingSummaryRecord>[] = [
//   { key: "id", label: "Resource Id", width: "55%", isCentered: true },
// ]; // add to the summary table if need to add details to the summary page

const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.SUMMARY]: "Summary",
  [TABS.INSTANCES]: "Instances",
  [TABS.VOLUMES]: "Volumes",
  [TABS.DATABASES]: "Databases",
  [TABS.OBJECT_STORAGE]: "Object Storage"
};

export const BillingProject: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    userId: string;
    regionId: string;
    organizationId: string;
    projectId: string;
  }>();

  const history = useNavigate();
  const userInfo = useSelector(userInfoSelector);
  const userName = getFullName(userInfo?.firstName, userInfo?.lastName);
  const organization = useSelector(organizationSelector);

  const summaryTableBillingData = useSelector(
    tableProjectBillingSummaryRecordsSelector
  );

  const summaryTableBillingDataTotal = useSelector(
    tableProjectBillingSummaryRecordsTotalSelector
  );

  const instancesBillingData = useSelector(projectBillingInstancesDataSelector);
  const instancesTableBillingData = useSelector(
    tableProjectBillingInstancesRecordsSelector
  );
  const instancesTableBillingDataTotal = useSelector(
    tableProjectBillingInstancesRecordsTotalSelector
  );

  const volumesTableBillingData = useSelector(
    tableProjectBillingVolumesRecordsSelector
  );
  const volumesTableBillingDataTotal = useSelector(
    tableProjectBillingVolumesRecordsTotalSelector
  );

  const databasesTableBillingData = useSelector(
    tableProjectBillingDatabasesRecordsSelector
  );
  const databasesTableBillingDataTotal = useSelector(
    tableProjectBillingDatabasesRecordsTotalSelector
  );

  const s3TableBillingData = useSelector(tableProjectBillingS3RecordsSelector);
  const s3TableBillingDataTotal = useSelector(
    tableProjectBillingS3RecordsTotalSelector
  );

  const isProjectBillingInstancesDataLoading = useSelector(
    isProjectBillingInstancesDataLoadingSelector
  );
  const isProjectBillingVolumesDataLoading = useSelector(
    isProjectBillingVolumesDataLoadingSelector
  );
  const isProjectBillingDatabasesDataLoading = useSelector(
    isProjectBillingDatabasesDataLoadingSelector
  );
  const isProjectBillingS3DataLoading = useSelector(
    isProjectBillingS3DataLoadingSelector
  );

  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);
  const tableCurrencies = useMemo(
    () => (instancesBillingData ? Object.keys(instancesBillingData.costs) : []),
    [instancesBillingData]
  );
  const handleCurrencyChange = useCallback((event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  }, []);

  const tableSummaryColumns: TableColumn<TableBillingSummaryRecord>[] = [
    { key: "id", label: "Resource Type", width: "50%" },
    {
      key: `cost-${currency}`,
      label: "Cost",
      sortingType: TABLE_SORTING_TYPES.NUMBER
    }
  ];

  const tableVolumesColumns: TableColumn<TableBillingVolumesRecord>[] = [
    { key: "id", label: "Volume ID" },
    { key: "name", label: "Volume Name" },
    { key: "status", label: "Status", width: "15%" },
    {
      key: `cost-${currency}`,
      label: "Cost",
      width: "15%",
      sortingType: TABLE_SORTING_TYPES.NUMBER
    }
  ];
  const collapsibleVolumesColumns: TableColumn<CollapsibleData>[] = [
    { key: "description", label: "Size" },
    {
      key: `cost-${currency}`,
      label: "Cost"
    }
  ];

  const tableInstancesColumns: TableColumn<TableBillingInstancesRecord>[] = [
    { key: "id", label: "Instance ID" },
    { key: "name", label: "Instance Name" },
    { key: "status", label: "Status", width: "15%" },
    {
      key: `cost-${currency}`,
      label: "Cost",
      width: "15%",
      sortingType: TABLE_SORTING_TYPES.NUMBER
    }
  ];
  const collapsibleInstanceColumns: TableColumn<CollapsibleData>[] = [
    { key: "name", label: "Flavor Name" },
    { key: "description", label: "Description" },
    {
      key: `cost-${currency}`,
      label: "Cost"
    }
  ];

  const tableDatabasesColumns: TableColumn<TableBillingVolumesRecord>[] = [
    { key: "id", label: "Database ID" },
    { key: "name", label: "Database Name" },
    { key: "type", label: "Type" },
    { key: "status", label: "Status", width: "15%" },
    {
      key: `cost-${currency}`,
      label: "Cost",
      width: "15%",
      sortingType: TABLE_SORTING_TYPES.NUMBER
    }
  ];
  const collapsibleDatabasesColumns: TableColumn<CollapsibleData>[] = [
    { key: "name", label: "Description" },
    {
      key: `cost-${currency}`,
      label: "Cost"
    }
  ];

  const tableS3Columns: TableColumn<TableBillingS3Record>[] = [
    { key: "id", label: "ID" },
    { key: "name", label: "Name", width: "25%" },
    { key: "status", label: "Status", width: "15%" },
    {
      key: `cost-${currency}`,
      label: "Cost",
      width: "15%",
      sortingType: TABLE_SORTING_TYPES.NUMBER
    }
  ];
  const collapsibleS3Columns: TableColumn<CollapsibleData>[] = [
    { key: "name", label: "S3 Object Name" },
    {
      key: `cost-${currency}`,
      label: "Cost"
    }
  ];

  const currentDate = new Date();
  const minDate = sub(currentDate, { years: 1 });
  const queryParams = new URLSearchParams(location.search);
  const [startDate, setStartDate] = useState<string | null>(
    queryParams.get("start-date") ||
      formatDate(startOfMonth(currentDate), DATE_FORMATS.ISO_DATE)
  );
  const [endDate, setEndDate] = useState<string | null>(
    queryParams.get("end-date") ||
      formatDate(currentDate, DATE_FORMATS.ISO_DATE)
  );

  const previousStartDate = usePrevious(startDate);
  const previousEndDate = usePrevious(endDate);

  const handleDateChange = useCallback(
    (dateType: DATE_TYPES) => (date: Date | null) => {
      const dateValue = date ? formatDate(date, DATE_FORMATS.ISO_DATE) : date;
      switch (dateType) {
        case DATE_TYPES.START:
          setStartDate(dateValue);
          break;
        case DATE_TYPES.END:
          setEndDate(dateValue);
      }
    },
    []
  );

  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.SUMMARY) as TABS
  );
  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Users", url: ROUTES.USERS },
    {
      text: userName,
      url: generatePath(ROUTES.USER, {
        userId: matchParams.userId
      })
    },
    {
      text: "Billing",
      url: `${generatePath(ROUTES.USER, {
        userId: matchParams.userId
      })}?${generateSearchString({
        tab: encodeURIComponent(USER_TAB_TITLES[USER_TABS.BILLING]),
        "start-date": encodeURIComponent(startDate || ""),
        "end-date": encodeURIComponent(endDate || "")
      })}`
    },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.BILLING_ORGANIZATION, {
        userId: matchParams.userId,
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.BILLING_ORGANIZATION, {
        userId: matchParams.userId,
        organizationId: matchParams.organizationId
      })
    },
    {
      text: instancesBillingData?.name || "",
      url: generatePath(ROUTES.BILLING_PROJECT, {
        organizationId: matchParams.organizationId,
        userId: matchParams.userId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  useMount(() => {
    dispatch(
      usersActions.getUserInfo.started({
        id: matchParams.userId!
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    if (startDate && endDate) {
      dispatch(
        billingActions.getProjectBillingInstancesData.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
      dispatch(
        billingActions.getProjectBillingVolumesData.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
      dispatch(
        billingActions.getProjectBillingDatabasesData.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
      dispatch(
        billingActions.getProjectBillingS3Data.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
    }
  });

  useUnmount(() => {
    dispatch(billingActions.clear());
  });

  useEffect(() => {
    history({
      search: generateSearchString({
        tab: TAB_TITLES[TABS.SUMMARY as number],
        "start-date": startDate || "",
        "end-date": endDate || ""
      })
    });
    if (
      startDate &&
      endDate &&
      (startDate !== previousStartDate || endDate !== previousEndDate)
    ) {
      dispatch(
        billingActions.getProjectBillingInstancesData.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
      dispatch(
        billingActions.getProjectBillingVolumesData.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
      dispatch(
        billingActions.getProjectBillingDatabasesData.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
      dispatch(
        billingActions.getProjectBillingS3Data.started({
          startDate,
          endDate,
          regionId: matchParams.regionId!,
          organizationId: matchParams.organizationId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    history,
    activeTabIndex,
    dispatch,
    startDate,
    endDate,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.projectId,
    previousStartDate,
    previousEndDate
  ]);

  const title = instancesBillingData?.name;

  const toolbarItems = (
    <>
      <MobileDatePicker
        label={"Start date"}
        onChange={handleDateChange(DATE_TYPES.START)}
        value={startDate ? new Date(startDate) : null}
        format={DATE_FORMATS.DATE}
        minDate={minDate}
        maxDate={endDate ? new Date(endDate) : undefined}
        disableFuture={true}
        sx={{ marginRight: "10px" }}
        slotProps={{
          textField: {
            InputProps: { size: "small" }
          }
        }}
      />
      <MobileDatePicker
        label={"End date"}
        onChange={handleDateChange(DATE_TYPES.END)}
        value={endDate ? new Date(endDate) : null}
        format={DATE_FORMATS.DATE}
        minDate={startDate ? new Date(startDate) : minDate}
        disableFuture={true}
        sx={{ marginRight: "10px" }}
        slotProps={{
          textField: {
            InputProps: { size: "small" }
          }
        }}
      />
      {tableCurrencies.length > 0 && (
        <s.CurrencyFormControl>
          <InputLabel id={CURRENCY_SELECT_ID}>Currency</InputLabel>
          <Select
            label={"Currency"}
            labelId={CURRENCY_SELECT_ID}
            value={currency}
            onChange={handleCurrencyChange}
            size={"small"}
          >
            {tableCurrencies.map((currency) => (
              <MenuItem key={currency} value={currency}>
                {currency.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </s.CurrencyFormControl>
      )}
    </>
  );

  const tabContent = [
    <TableCollapsible<TableBillingSummaryRecord>
      key={"summaryBilling"}
      isSearchEnabled={true}
      rows={summaryTableBillingData || []}
      // collapsibleDataKey="items"
      // collapsibleDataColumns={[
      //   ...collapsibleSummaryColumns,
      // ]}
      columns={[...tableSummaryColumns]}
      totalRow={summaryTableBillingDataTotal || undefined}
      isLoading={
        isProjectBillingInstancesDataLoading ||
        isProjectBillingVolumesDataLoading ||
        isProjectBillingDatabasesDataLoading
      }
      toolbarItems={toolbarItems}
    />,
    <TableCollapsible<TableBillingInstancesRecord>
      key={"InstancesBilling"}
      rows={instancesTableBillingData || []}
      collapsibleDataKey="itemFlavors"
      isLoading={isProjectBillingInstancesDataLoading}
      isSearchEnabled={true}
      totalRow={instancesTableBillingDataTotal || undefined}
      toolbarItems={toolbarItems}
      columns={[...tableInstancesColumns]}
      collapsibleDataColumns={[...collapsibleInstanceColumns]}
      itemWithIcon={{
        withText: false,
        column: "status",
        getIcon: (row) => {
          if (row["status"] === "available")
            return <SuccessIcon color="success" fontSize="small" />;
          if (row["status"] === "deleted")
            return <FailedIcon color="error" fontSize="small" />;
          return <></>; // or default icon
        },
        tooltipText: (row) => {
          if (row.status === "deleted")
            return `deleted at ${String(row.deleted)}`;
          if (row.status === "available") return "available instance";
          return "";
        }
      }}
    />,
    <TableCollapsible<TableBillingVolumesRecord>
      key={"volumesBilling"}
      rows={volumesTableBillingData || []}
      collapsibleDataKey="itemFlavors"
      isLoading={isProjectBillingVolumesDataLoading}
      isSearchEnabled={true}
      totalRow={volumesTableBillingDataTotal || undefined}
      toolbarItems={toolbarItems}
      columns={[...tableVolumesColumns]}
      collapsibleDataColumns={[...collapsibleVolumesColumns]}
      itemWithIcon={{
        withText: false,
        column: "status",
        getIcon: (row) => {
          if (row["status"] === "available")
            return <SuccessIcon color="success" fontSize="small" />;
          if (row["status"] === "deleted")
            return <FailedIcon color="error" fontSize="small" />;
          return <></>; // or default icon
        },
        tooltipText: (row) => {
          if (row.deleted === "true") return `deleted volume`;
          if (row.deleted === "false") return "available volume";
          return "";
        }
      }}
    />,
    <TableCollapsible<TableBillingDatabasesRecord>
      key={"databasesBilling"}
      rows={databasesTableBillingData || []}
      collapsibleDataKey="itemFlavors"
      isLoading={isProjectBillingDatabasesDataLoading}
      isSearchEnabled={true}
      totalRow={databasesTableBillingDataTotal || undefined}
      toolbarItems={toolbarItems}
      columns={[...tableDatabasesColumns]}
      collapsibleDataColumns={[...collapsibleDatabasesColumns]}
      itemWithIcon={{
        withText: false,
        column: "status",
        getIcon: (row) => {
          if (row["status"] === "available")
            return <SuccessIcon color="success" fontSize="small" />;
          if (row["status"] === "deleted")
            return <FailedIcon color="error" fontSize="small" />;
          return <></>; // or default icon
        },
        tooltipText: (row) => {
          if (row.deleted === "true") return `deleted database`;
          if (row.deleted === "false") return "available database";
          return "";
        }
      }}
    />,
    <TableCollapsible<TableBillingS3Record>
      key={"s3Billing"}
      rows={s3TableBillingData || []}
      // collapsibleDataKey="itemFlavors"
      // collapsibleDataColumns={[...collapsibleS3Columns]}
      isLoading={isProjectBillingS3DataLoading}
      isSearchEnabled={true}
      totalRow={s3TableBillingDataTotal || undefined}
      toolbarItems={toolbarItems}
      columns={[...tableS3Columns]}
      itemWithIcon={{
        withText: false,
        column: "status",
        getIcon: (row) => {
          if (row["status"] === "available")
            return <SuccessIcon color="success" fontSize="small" />;
          if (row["status"] === "deleted")
            return <FailedIcon color="error" fontSize="small" />;
          return <></>; // or default icon
        },
        tooltipText: (row) => {
          if (row.deleted === "true") return `deleted object`;
          if (row.deleted === "false") return "available object";
          return "";
        }
      }}
    />
  ];

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <s.Title variant={"h4"} component={"h2"}>
        Project: {title}
      </s.Title>
      <s.SummaryRow>
        <s.DetailsTitle>ID:</s.DetailsTitle>
        <s.DetailsInfo>{matchParams.projectId}</s.DetailsInfo>
      </s.SummaryRow>
      <s.SummaryRow>
        <s.DetailsTitle>Region:</s.DetailsTitle>
        <s.Tag label={matchParams.regionId} />
      </s.SummaryRow>
      <s.SummaryRow>
        <s.DetailsTitle>Organization ID:</s.DetailsTitle>
        <s.DetailsInfo>{matchParams.organizationId}</s.DetailsInfo>
      </s.SummaryRow>

      <s.TabsContainer>
        <Tabs value={activeTabIndex} onChange={handleChangeTab}>
          {Object.values(TAB_TITLES).map((tab) => (
            <Tab key={tab} label={tab} />
          ))}
        </Tabs>
      </s.TabsContainer>
      <s.Description>
        Billing data is available for previous 12 months
      </s.Description>

      {tabContent[activeTabIndex]}
    </>
  );
};
