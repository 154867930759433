import { RootReducer } from "../../reducers";
import { ApiSession, KeycloakUser } from "./types";

export const userSelector = (state: RootReducer): KeycloakUser | null =>
  state.auth.user;

export const isAdminSelector = (state: RootReducer): boolean =>
  Boolean(state.auth.user?.attributes?.["role:admin"]);

export const adminRoleSelector = (state: RootReducer): string[] | undefined =>
  state.auth.user?.attributes?.["role:admin"];

export const apiSessionSelector = (state: RootReducer): ApiSession | null =>
  state.auth.apiSession;
