import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import { Instance, InstancesCached } from "./types";

export type Reducer = {
  gpuInstances: Instance[] | null;
  areGpuInstancesLoading: boolean;
  instances: Instance[] | null;
  areInstancesLoading: boolean;
  isCacheUpdating: boolean;
  instancesCached: InstancesCached | null;
  areInstancesCachedLoading: boolean;
};

const initialState: Reducer = {
  gpuInstances: null,
  areGpuInstancesLoading: false,
  instances: null,
  areInstancesLoading: false,
  isCacheUpdating: false,
  instancesCached: null,
  areInstancesCachedLoading: false
};

export const reducer = reducerWithInitialState(initialState)
  // gpu instances
  .case(
    actions.getGpuInstances.started,
    (state): Reducer => ({
      ...state,
      areGpuInstancesLoading: true
    })
  )
  .case(
    actions.getGpuInstances.done,
    (state, payload): Reducer => ({
      ...state,
      gpuInstances: payload.result,
      areGpuInstancesLoading: false
    })
  )
  .case(
    actions.getGpuInstances.failed,
    (state): Reducer => ({
      ...state,
      areGpuInstancesLoading: false
    })
  )

  // all instances (in real time loaded data)
  .case(
    actions.getInstances.started,
    (state): Reducer => ({
      ...state,
      areInstancesLoading: true
    })
  )
  .case(
    actions.getInstances.done,
    (state, payload): Reducer => ({
      ...state,
      instances: payload.result,
      areInstancesLoading: false
    })
  )
  .case(
    actions.getInstances.failed,
    (state): Reducer => ({
      ...state,
      areInstancesLoading: false
    })
  )

  // all instances (cached)
  .case(
    actions.getInstancesCached.started,
    (state): Reducer => ({
      ...state,
      areInstancesCachedLoading: true
    })
  )
  .case(
    actions.getInstancesCached.done,
    (state, payload): Reducer => ({
      ...state,
      instancesCached: payload.result,
      areInstancesCachedLoading: false
    })
  )
  .case(
    actions.getInstancesCached.failed,
    (state): Reducer => ({
      ...state,
      areInstancesCachedLoading: false
    })
  )

  // Update cache
  .case(
    actions.updateCacheInstances.started,
    (state): Reducer => ({
      ...state,
      isCacheUpdating: true
    })
  )
  .case(
    actions.updateCacheInstances.done,
    (state, payload): Reducer => ({
      ...state,
      isCacheUpdating: false
    })
  )
  .case(
    actions.updateCacheInstances.failed,
    (state): Reducer => ({
      ...state,
      isCacheUpdating: false
    })
  )

  // Clear VMs List
  .case(
    actions.clearInstancesList,
    (state): Reducer => ({
      ...state,
      instances: initialState.instances
    })
  )
  .case(
    actions.clearCachedInstancesList,
    (state): Reducer => ({
      ...state,
      instancesCached: initialState.instancesCached
    })
  )
  .case(
    actions.clearGpuInstancesList,
    (state): Reducer => ({
      ...state,
      gpuInstances: initialState.instances
    })
  )
  .case(actions.clear, (): Reducer => initialState);
