export const formatSize = (bytes: number): string => {
  if (bytes === 0) {
    return "0 B";
  }

  const k = 1024;
  const sizes: string[] = ["B", "KB", "MB", "GB", "TB"];

  const i: number = Math.floor(Math.log(bytes) / Math.log(k));
  const fileSize: number = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${fileSize} ${sizes[i]}`;
};

export interface MemorySize {
  value: number;
  unit: string;
}

export const formatMemorySize = (memoryInMB: number): MemorySize => {
  if (memoryInMB === 0) {
    return { value: 0, unit: "MB" };
  }

  const k = 1024;
  const sizes: string[] = ["MB", "GB", "TB", "PB"];

  const i: number = Math.min(
    Math.floor(Math.log(memoryInMB) / Math.log(k)),
    sizes.length - 1
  );
  const formattedSize: number = parseFloat(
    (memoryInMB / Math.pow(k, i)).toFixed(2)
  );

  return { value: formattedSize, unit: sizes[i] };
};

// export const formatMemorySize = (memoryInMB: number): string => {
//   if (memoryInMB === 0) {
//     return "0 MB";
//   }

//   const k = 1024;
//   const sizes: string[] = ["MB", "GB", "TB", "PB"];

//   const i: number = Math.floor(Math.log(memoryInMB) / Math.log(k));
//   const formattedSize: number = parseFloat(
//     (memoryInMB / Math.pow(k, i)).toFixed(2)
//   );

//   return `${formattedSize} ${sizes[i]}`;
// };
