import Typography from "@mui/material/Typography";
import { FC } from "react";
import * as s from "./styles";
import { ErrorScreenProps } from "./types";

export const ErrorScreen: FC<ErrorScreenProps> = ({
  message
}: ErrorScreenProps) => (
  <s.Container>
    <Typography variant={"h3"} component={"p"}>
      {message}
    </Typography>
  </s.Container>
);
