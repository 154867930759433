export enum DATE_TYPES {
  START,
  END
}

export enum ORG_STATUSES {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED"
}

export enum TABS {
  USER_INFO,
  BILLING,
  ORGANIZATIONS
}

export enum DIALOG_TYPES {
  CHANGE_QRG_QUOTAS,
  ENABLE_2FA,
  DISABLE_2FA,
  SET_PASSWORD,
  EDIT_USER_INFO,
  CREATE_ORGANIZATION,
  EDIT_ORGANIZATION,
  ADD_ADMIN,
  REMOVE_ADMIN,
  CHANGE_OWNERSHIP,
  DELETE_ORGANIZATION,
  DISABLE_USER,
  ENABLE_USER,
  SET_ADMIN_ROLES,
  MODIFY_USER_TYPE,
  MODIFY_TRIAL_PERIOD
}
